import { LoadingOutlined } from "@ant-design/icons";
import { Breadcrumb, Col, Row, Spin, Tabs, TabsProps } from "antd";
import React from "react";
import { adminContext } from "../../Common/PageRoute";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "antd/es/form/Form";
import PayrollYearlySettingsService, {
  AEORatesType,
  CarAllowanceListType,
  ITaxData,
  IYearlyPayrollSetting,
  PayrollYearlySettingsTabs,
  PayrollYearlySettingsTabsNameList,
  PayrollYearlySettingsUpdateTypes,
} from "../../Services/PayrollYearlySettingsService";
import General from "./General";
import AllowanceLimitsAndLoans from "./AllowanceLimitsAndLoans";
import NIThersholds from "./NIThersholds";
import NIRates from "./NIRates";
import TaxData from "./TaxData";
import CarAllowances from "./CarAllowances";
import AddNIThershold from "./AddNIThershold";
import AddNIRate from "./AddNIRate";
import AEORates from "./AEORates";
import AddCarAllowanceItem from "./AddCarAllowanceItem";
import AddAEORates from "./AddAEORates";

interface ListParams {
  refresh: boolean;
  carAllowancesListType: CarAllowanceListType;
  aeoRates: AEORatesType;
}
const PayrollYearlySettingsDetails = ({ data }: any) => {
  const { openNotification } = React.useContext(adminContext);
  const navigate = useNavigate();
  const [form] = useForm();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState<{ type: string; params: any }>({
    type: "",
    params: {},
  });
  const [selectedTab, setSelectedTab] =
    React.useState<PayrollYearlySettingsTabs>(
      PayrollYearlySettingsTabs.general
    );
  const [payrollYearlySettingsData, setPayrollYearlySettingsData] =
    React.useState<IYearlyPayrollSetting>();
  const [listParams, setListParams] = React.useState<ListParams>({
    refresh: true,
    carAllowancesListType: 1,
    aeoRates: 1,
  });
  const [isEdit, setIsEdit] = React.useState<{
    type: PayrollYearlySettingsUpdateTypes;
  }>({
    type: PayrollYearlySettingsUpdateTypes.none,
  });
  const onChange = (key: any) => {
    let numKey = parseInt(key, 10);
    setSelectedTab(numKey);
  };
  const OnSaveClick = async () => {
    setLoading(true);
    const Id = payrollYearlySettingsData?.id ?? "";
    const values = form.getFieldsValue();
    let res =
      await PayrollYearlySettingsService.postPayrollYearlySettingsDetails(
        Id,
        isEdit.type,
        values
      );
    if (res.status) {
      setLoading(false);
      setIsEdit({ type: PayrollYearlySettingsUpdateTypes.none });
      openNotification("success", "Details updated successfully");
    } else {
      setLoading(false);
      openNotification("error", "Details not updated");
    }
  };
  const OnCancelClick = () => {
    setIsEdit({ type: PayrollYearlySettingsUpdateTypes.none });
    setListParams({...listParams, refresh: !listParams.refresh })
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "General",
      children: (
        <General
          form={form}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          OnSaveClick={OnSaveClick}
          OnCancelClick={OnCancelClick}
        />
      ),
    },
    {
      key: "2",
      label: "Allowance Limits & Students Loans",
      children: (
        <AllowanceLimitsAndLoans
          form={form}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          OnSaveClick={OnSaveClick}
          OnCancelClick={OnCancelClick}
        />
      ),
    },
    {
      key: "3",
      label: "NI Thersholds",
      children: (
        <NIThersholds
          OnCancelClick={OnCancelClick}
          setDialog={setDialog}
          NIThresholdsList={payrollYearlySettingsData?.niThresholds}
        />
      ),
    },
    {
      key: "4",
      label: "NI Rates",
      children: (
        <NIRates
          OnCancelClick={OnCancelClick}
          setDialog={setDialog}
          NIRatesList={payrollYearlySettingsData?.niRates}
        />
      ),
    },
    {
      key: "5",
      label: "Tax Data",
      children: (
        <TaxData
          form={form}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          OnSaveClick={OnSaveClick}
          OnCancelClick={OnCancelClick}
        />
      ),
    },
    {
      key: "6",
      label: "Car Allowance",
      children: (
        <CarAllowances
          OnCancelClick={OnCancelClick}
          listParams={listParams}
          setDialog={setDialog}
          setListParams={setListParams}
          CarAllowancesTypeList={
            listParams?.carAllowancesListType ===
            CarAllowanceListType.CarCo2Emmission
              ? payrollYearlySettingsData?.carCo2Emmission
              : listParams.carAllowancesListType ===
                CarAllowanceListType.CarEngineSize
              ? payrollYearlySettingsData?.carEngineSize
              : listParams.carAllowancesListType ===
                CarAllowanceListType.CarEleRange
              ? payrollYearlySettingsData?.carEleRanage
              : []
          }
        />
      ),
    },
    {
      key: "7",
      label: "AEO Rates",
      children: (
        <AEORates
          OnCancelClick={OnCancelClick}
          listParams={listParams}
          setListParams={setListParams}
          setDialog={setDialog}
          aeoRatesListType={
            listParams?.aeoRates === AEORatesType.DEAStandard
              ? payrollYearlySettingsData?.deaStandard
              : listParams.aeoRates === AEORatesType.DEAHigher
              ? payrollYearlySettingsData?.deaHigher
              : listParams.aeoRates === AEORatesType.DEAEAS
              ? payrollYearlySettingsData?.deaeas
              : listParams.aeoRates === AEORatesType.DEAMC
              ? payrollYearlySettingsData?.deamc
              : listParams.aeoRates === AEORatesType.DEACTW
              ? payrollYearlySettingsData?.deactw
              : listParams.aeoRates === AEORatesType.DEACTE
              ? payrollYearlySettingsData?.deacte
              : []
          }
        />
      ),
    },
  ];

  const setFieldsValue = (item: IYearlyPayrollSetting) => {
    form.setFieldsValue({
      id: item?.id,
      yearId: item?.yearId,
      defaultTaxCode: item?.defaultTaxCode,
      awe: item?.awe,
      eaLimit: item?.eaLimit,
      sspWkPay: item?.sspWkPay,
      sapawePay: item?.sapawePay,
      smpawePay: item?.smpawePay,
      ppBirthAWEPay: item?.ppBirthAWEPay,
      ppAdoptionAWEPay: item?.ppAdoptionAWEPay,
      stdLoanPlan1Weekly: item?.stdLoanPlan1Weekly,
      stdLoanPlan1Monthly: item?.stdLoanPlan1Monthly,
      stdLoanPlan1Annual: item?.stdLoanPlan1Annual,
      stdLoanPlan2Weekly: item?.stdLoanPlan2Weekly,
      stdLoanPlan2Monthly: item?.stdLoanPlan2Monthly,
      stdLoanPlan2Annual: item?.stdLoanPlan2Annual,
      stdLoanPlan4Weekly: item?.stdLoanPlan4Weekly,
      stdLoanPlan4Monthly: item?.stdLoanPlan4Monthly,
      stdLoanPlan4Annual: item?.stdLoanPlan4Annual,
      postgraduateLoanWeekly: item?.postgraduateLoanWeekly,
      postgraduateLoanMonthly: item?.postgraduateLoanMonthly,
      postgraduateLoanAnnual: item?.postgraduateLoanAnnual,
      stdLoanPer: item?.stdLoanPer,
      postgraduateLoanPer: item?.postgraduateLoanPer,
      pensionLowerLimit: item?.pensionLowerLimit,
      pensionUpperLimit: item?.pensionUpperLimit,
      loanIntRate: item?.loanIntRate,
      eeDirAnnualLimit: item?.eeDirAnnualLimit,
      erDirAnnualLimit: item?.erDirAnnualLimit,
      taTaxableFrom: item?.taTaxableFrom,
      p60BottomLine: item?.p60BottomLine,
      fpsns: item?.fpsns,
      fpsVer: item?.fpsVer,
      epsns: item?.epsns,
      epsVer: item?.epsVer,
      nvrns: item?.nvrns,
      nvrVer: item?.nvrVer,
      eyuns: item?.eyuns,
      eyuVer: item?.eyuVer,
      niThresholds: item?.niThresholds,
      niRates: item?.niRates,
      taxData: item?.taxData?.map((itm: ITaxData) => ({
        id: itm.id,
        taxCodeType: itm.taxCodeType,
        payFrequency: itm.payFrequency,
        minAmount: itm.minAmount,
        maxAmount: itm.maxAmount,
        taxPer: itm.taxPer,
      })),
      fixedCodeTax: item?.fixedCodeTax,
      carCo2Emmission: item?.carCo2Emmission,
      carEleRanage: item?.carEleRanage,
      carEngineSize: item?.carEngineSize,
      statePensionAge: item?.statePensionAge,
      deaStandard: item?.deaStandard,
      deaHigher: item?.deaHigher,
      deaeas: item?.deaeas,
      deamc: item?.deamc,
      deactw: item?.deactw,
      deacte: item?.deacte,
    });
  };

  React.useEffect(() => {
    const Id = id;
    const getPayrollYearlySettingsData = async () => {
      setLoading(true);
      // setListParams({...listParams, refresh: false});
      let res: any =
        await PayrollYearlySettingsService.getPayrollYearlySettings(
          Id,
          selectedTab,
          listParams.carAllowancesListType,
          listParams.aeoRates
        );
      if (res.status) {
        setPayrollYearlySettingsData(res.result);
        setFieldsValue(res.result);
      } else {
        openNotification("error", res.message);
      }
      setLoading(false);
    };
    getPayrollYearlySettingsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, selectedTab, listParams]);
  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
      >
        <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
          <Breadcrumb style={{ color: "#172B4D" }}>
            <Breadcrumb.Item onClick={() => navigate("/payrollyearlysettings")}>
              Payroll Yearly Settings
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {payrollYearlySettingsData?.yearId !== undefined
                ? `${1999 + payrollYearlySettingsData?.yearId} - ${
                    payrollYearlySettingsData?.yearId
                  }`
                : "Payroll Yearly Settings Details"}
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              {PayrollYearlySettingsTabsNameList[selectedTab - 1]}
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>

        <Row
          className="roundedCornerSmall p-1"
          style={{ backgroundColor: "white", padding: "10px 20px" }}
        >
          <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
            <Row>
              <Col
                span={24}
                style={{ display: "flex", alignItems: "center", padding: 5 }}
              >
                <Col>
                  <Col className="font-bold" style={{ fontSize: 25 }}>
                    Year:&nbsp;
                    {payrollYearlySettingsData?.yearId !== undefined
                      ? `${1999 + payrollYearlySettingsData?.yearId} - ${
                          2000 + payrollYearlySettingsData?.yearId
                        }`
                      : ""}
                  </Col>
                  <Row className="font-semibold">
                    Default Tax Code : &nbsp;
                    <span className="font-bold">
                      {payrollYearlySettingsData?.defaultTaxCode}
                    </span>
                  </Row>
                </Col>
              </Col>
              <Col span={24}>
                <Tabs
                  defaultActiveKey="1"
                  size="small"
                  items={items}
                  onChange={onChange}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {dialog.type === "AddNIThersholds" && (
          <AddNIThershold
            onDismiss={(conformation: boolean) => {
              setDialog({ type: "", params: {} });
              if (conformation) {
                setListParams({ ...listParams, refresh: !listParams.refresh });
              }
            }}
            itemId={dialog.params?.record?.id}
            record={dialog.params?.record}
          />
        )}
        {dialog.type === "AddNIRate" && (
          <AddNIRate
            onDismiss={(conformation: boolean) => {
              setDialog({ type: "", params: {} });
              if (conformation) {
                setListParams({ ...listParams, refresh: !listParams.refresh });
              }
            }}
            itemId={dialog.params?.record?.id}
            record={dialog.params?.record}
          />
        )}
        {dialog.type === "AddCarAllowance" && (
          <AddCarAllowanceItem
            carAllowanceListType={listParams.carAllowancesListType}
            onDismiss={(conformation: boolean) => {
              setDialog({ type: "", params: {} });
              if (conformation) {
                setListParams({ ...listParams, refresh: !listParams.refresh });
              }
            }}
            itemId={dialog.params?.record?.id}
            record={dialog.params?.record}
          />
        )}
        {dialog.type === "AddAEORates" && (
          <AddAEORates
          aeoRatesType={listParams.aeoRates}
            onDismiss={(conformation: boolean) => {
              setDialog({ type: "", params: {} });
              if (conformation) {
                setListParams({ ...listParams, refresh: !listParams.refresh });
              }
            }}
            itemId={dialog.params?.record?.id}
            record={dialog.params?.record}
          />
        )}
      </Spin>
    </>
  );
};

export default PayrollYearlySettingsDetails;
