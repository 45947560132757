import {
  CloseOutlined,
  EditTwoTone,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import { Form, Col, Row, Select, Button, InputNumber, Modal } from "antd";
import React from "react";
import { PayrollYearlySettingsUpdateTypes } from "../../Services/PayrollYearlySettingsService";
import { adminContext } from "../../Common/PageRoute";
import { useForm } from "antd/es/form/Form";
import { Utils } from "../../Utilities/Utils";

const TaxData = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
}: any) => {
  const [dailog, setDialog] = React.useState({ type: "", params: {} });
  const { openNotification } = React.useContext(adminContext);
  return (
    <>
      <Col
        className="roundedCornerSmall p-3"
        style={{ backgroundColor: "white" }}
      >
        <Form form={form}>
          <Form.List name="taxData">
            {(fields, { add, remove }, { errors }) => (
              <>
                <Row justify="space-between" className="border-bottom-2px pb-3">
                  <Button
                    type="primary"
                    className="add-btn"
                    onClick={() => {
                      if(isEdit.type ===  PayrollYearlySettingsUpdateTypes.taxData)
                        setDialog({ type: "AddTaxDataItem", params: {} });
                      else openNotification(
                             "warning",
                             "Please enable edit mode to add items"
                           ); 
                      }}
                    // onClick={() => {
                    //  if (isEdit) add();
                    //  else
                    //    openNotification(
                    //      "warning",
                    //      "Please enable edit mode to add items"
                    //    );
                  >
                    <PlusOutlined />
                    Add Item
                  </Button>
                </Row>
                <Col
                  span={24}
                  className="card border  pb-3 mt-2 border-rounded-1 bg-secondary"
                >
                  <Row
                    className="border-bottom  px-2 py-1 font-bold"
                    justify="space-between"
                  >
                    Tax Data
                    <span
                      className="edit-btn"
                      onClick={() =>
                        setIsEdit({
                          type: PayrollYearlySettingsUpdateTypes.taxData,
                        })
                      }
                    >
                      <EditTwoTone />
                    </span>
                  </Row>
                  <Row className="px-3 py-1 font-semibold">
                    <Col span={4}>Region</Col>
                    <Col span={5}>Pay Frequency</Col>
                    <Col span={5}>Minimum Amount</Col>
                    <Col span={5}>Maximum Amount</Col>
                    <Col span={5}>Tax Percentage</Col>
                  </Row>

                  {fields.map((field: any, index: any) => (
                    <Row className="px-3 py-1">
                      <Col span={4}>
                        <Form.Item
                          className="custom-form-item"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 18 }}
                          name={[field.name, "taxCodeType"]}
                        >
                          <Select
                            disabled={true}
                            className="line-height-28"
                            placeholder="Select Region"
                            options={[
                              { value: 1, label: "Northrn Ireland" },
                              { value: 2, label: "Scotland" },
                              { value: 3, label: "England and Wales" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          className="custom-form-item"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 18 }}
                          name={[field.name, "payFrequency"]}
                        >
                          <Select
                            disabled={true}
                            className="line-height-28"
                            placeholder="Pay Frequency"
                            options={[
                              { value: 1, label: "Monthly" },
                              { value: 2, label: "Weekly" },
                              { value: 3, label: "Two_Weekly" },
                              { value: 4, label: "Four_Weekly" },
                              { value: 5, label: "Annual" },
                              { value: 6, label: "Fortnightly" },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          className="custom-form-item"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 18 }}
                          name={[field.name, "minAmount"]}
                        >
                          <InputNumber
                            controls={false}
                            disabled={
                              isEdit.type ===
                              PayrollYearlySettingsUpdateTypes.taxData
                                ? false
                                : true
                            }
                            placeholder="Minimum Amount"
                            className="line-height-28"
                            prefix="£"
                            onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          className="custom-form-item"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 18 }}
                          name={[field.name, "maxAmount"]}
                        >
                          <InputNumber
                            controls={false}
                            disabled={
                              isEdit.type ===
                              PayrollYearlySettingsUpdateTypes.taxData
                                ? false
                                : true
                            }
                            placeholder="Maximum Amount"
                            className="line-height-28"
                            prefix="£"
                            onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          className="custom-form-item"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 18 }}
                          name={[field.name, "taxPer"]}
                        >
                          <InputNumber
                            controls={false}
                            disabled={
                              isEdit.type ===
                              PayrollYearlySettingsUpdateTypes.taxData
                                ? false
                                : true
                            }
                            placeholder="Tax Percentage"
                            className="line-height-28"
                            prefix="%"
                            onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                            style={{ width: "100%" }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                  {isEdit.type === PayrollYearlySettingsUpdateTypes.taxData && (
                    <Row className="px-3 pt-3">
                      <Button type="primary" onClick={OnSaveClick}>
                        <SaveOutlined /> Save
                      </Button>
                      <Button className="ms-2" onClick={OnCancelClick}>
                        <CloseOutlined /> Cancel
                      </Button>
                    </Row>
                  )}
                </Col>
                {dailog.type === "AddTaxDataItem" && (
                  <AddTaxData
                    onDismiss={() => setDialog({ type: "", params: {} })}
                    onOK={(values: any) => add(values)}
                    length={() => {
                      let val = form.getFieldValue("taxData");
                      return val.length;
                    }}
                  />
                )}
              </>
            )}
          </Form.List>
        </Form>
      </Col>
    </>
  );
};

export default TaxData;

const AddTaxData = ({ length, onDismiss, onOK }: any) => {
  const [form] = useForm();
  let len = length() + 1;
  React.useEffect(() => {
    form.setFieldValue("id", len);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [len]);
  return (
    <>
      <Modal
        title={"Add Tax Data item"}
        width={600}
        open={true}
        okText={
          <>
            <PlusOutlined /> Add
          </>
        }
        cancelText={
          <>
            <CloseOutlined /> Cancel
          </>
        }
        // okButtonProps={{loading: loading}}
        // cancelButtonProps={{loading: loading}}
        onCancel={() => onDismiss()}
        onOk={() => {
          const values = form.getFieldsValue();
          form.validateFields().then(() => {
            onOK(values);
            onDismiss();
          }).catch((error)=>{});
        }}
      >
        <Form form={form} requiredMark={false}>
          <Row className="px-3 py-1">
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  {
                    required: true,
                    message: "Please select a region",
                  },
                ]}
                name="taxCodeType"
                label="Region"
              >
                <Select
                  className="line-height-28"
                  placeholder="Select Region"
                  options={[
                    { value: 1, label: "Northern Ireland" },
                    { value: 2, label: "Scotland" },
                    { value: 3, label: "England and Wales" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                rules={[
                  {
                    required: true,
                    message: "Please select a pay frequency",
                  },
                ]}
                name="payFrequency"
                label="Pay Frequency"
              >
                <Select
                  className="line-height-28"
                  placeholder="Pay Frequency"
                  options={[
                    { value: 1, label: "Monthly" },
                    { value: 2, label: "Weekly" },
                    { value: 3, label: "Two_Weekly" },
                    { value: 4, label: "Four_Weekly" },
                    { value: 5, label: "Annual" },
                    { value: 6, label: "Fortnightly" },
                  ]}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 py-1">
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="minAmount"
                label="Minimum amount"
              >
                <InputNumber
                  controls={false}
                  placeholder="Minimum Amount"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="maxAmount"
                label="Maximum amount"
              >
                <InputNumber
                  controls={false}
                  placeholder="Maximum Amount"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 py-1">
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="taxPer"
                label="Tax percentage"
              >
                <InputNumber
                  controls={false}
                  placeholder="Tax Percentage"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                style={{ display: "none" }}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="id"
              >
                <InputNumber
                  controls={false}
                  placeholder="Tax Percentage"
                  className="line-height-28"
                  prefix="S.No"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
