import {
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  Switch,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React from "react";
import PayrollYearlySettingsService from "../../Services/PayrollYearlySettingsService";
import { useParams } from "react-router-dom";
import { adminContext } from "../../Common/PageRoute";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Utils } from "../../Utilities/Utils";

const AddNIThershold = ({ itemId, onDismiss, record }: any) => {
  const [form] = useForm();
  const { id } = useParams();
  const { openNotification } = React.useContext(adminContext);
  const [ loading, setLoading ] = React.useState(false);
  React.useEffect(() => {
    if(record?.id !== undefined && record?.id !== null && record?.id !== ""){
      form.setFieldsValue({
        id: record?.id,
        payFrequency: record?.payFrequency,
        payDateFrom: record?.payDateFrom ? dayjs(record?.payDateFrom) : undefined,
        payDateTo: record?.payDateTo ? dayjs(record?.payDateTo) : undefined,
        lel: record?.lel,
        pt: record?.pt,
        st: record?.st,
        ust: record?.ust,
        aust: record?.aust,
        fust: record?.fust,
      vust: record?.vust,
      uel: record?.uel,
      auel: record?.auel,
      director: record?.director
    });
  }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record, itemId]);
  return (
    <Modal
      title={` ${itemId ? 'Edit':'Add'} NI Thershold item`}
      width={900}
      open={true}
      okText={<><SaveOutlined /> Save</>}
      cancelText={<><CloseOutlined /> Cancel</>}
      okButtonProps={{loading: loading}}
      cancelButtonProps={{loading: loading}}
      onCancel={() => onDismiss(false)}
      onOk={async ()=>{
        setLoading(true);
        const yearlySettingsRecordId = id ?? "";
        const ItemId = itemId ?? "";
        form.validateFields().then(async (values: any) => {
          const val = {...values, id: record?.id}
          let res = await PayrollYearlySettingsService.updateNIThersholdItem(yearlySettingsRecordId, val, ItemId);
          if(res.status){
            openNotification("success", "Details updated successfully")
            onDismiss(true);
            setLoading(false);
          } else {
            openNotification("error", res.message);
            setLoading(false);
          }
      }).catch((error)=>{
        setLoading(false);
      });
      }}
    >
      <Form form={form} requiredMark={false}>
        <Row className="px-3 py-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Pay Frequency"
              name="payFrequency"
              rules={[{ required: true, message: "Select a Pay Frequency" }]}
            >
              <Select
                placeholder="Select PayFrequency"
                className="line-height-28"
                options={[
                  { value: 1, label: "Monthly" },
                  { value: 2, label: "Weekly" },
                  { value: 3, label: "Two_Weekly" },
                  { value: 4, label: "Four_Weekly" },
                  { value: 5, label: "Annual" },
                  { value: 6, label: "Fortnightly" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="PayDate From"
              name="payDateFrom"
            >
              <DatePicker
                placeholder="PayDate From"
                format='DD/MM/YYYY'
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="PayDate To"
              name="payDateTo"
            >
              <DatePicker
                format='DD/MM/YYYY' 
                placeholder="PayDate To" 
                style={{ width: "100%" }} 
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Lower earning limits"
              name="lel"
            >
              <InputNumber
                controls={false}
                placeholder="Lower earning limits"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Primary threshold"
              name="pt"
            >
              <InputNumber
                controls={false}
                placeholder="Primary threshold"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Secondary threshold"
              name="st"
            >
              <InputNumber
                controls={false}
                placeholder="Secondary threshold"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Upper secondary threshold"
              name="ust"
            >
              <InputNumber
                controls={false}
                placeholder="Upper secondary threshold"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Apprentice upper secondary threshold"
              name="aust"
            >
              <InputNumber
                controls={false}
                placeholder="Apprentice upper secondary threshold"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Freeport upper secondary threshold"
              name="fust"
            >
              <InputNumber
                controls={false}
                placeholder="Freeport upper secondary threshold"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Veterans upper secondary threshold"
              name="vust"
            >
              <InputNumber
                controls={false}
                placeholder="Veterans upper secondary threshold"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Upper earnings limit"
              name="uel"
            >
              <InputNumber
                controls={false}
                placeholder="Upper earnings limit"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Apprentice upper earnings limit"
              name="auel"
            >
              <InputNumber
                controls={false}
                placeholder="Apprentice upper earnings limit"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              style={{ display: "flex", alignItems: "start", textAlign: "end" }}
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              label="Director"
              name="director"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddNIThershold;
