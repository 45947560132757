import { Col, DatePicker, Form, InputNumber, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React from "react";
import CTConfigurationService from "../../Services/CTConfigurationService";
import { adminContext } from "../../Common/PageRoute";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Utils } from "../../Utilities/Utils";

const AddRnDRates = ({ id, record, onDismiss }: any) => {
  const [form] = useForm();
  const { openNotification } = React.useContext(adminContext);

  React.useEffect(() => {
    if (id !== undefined && id !== null && id !== "") {
      form.setFieldsValue({
        startDate: record?.startDate ? dayjs(record?.startDate) : undefined,
        minAmount: record?.minAmount,
        minRate: record?.minRate,
        smeRate: record?.smeRate,
        smeVaccRate: record?.smeVaccRate,
        taxRate: record?.taxRate,
        lcsRate: record?.lcsRate,
        lcsVaccRate: record?.lcsVaccRate,
        lTaxRate: record?.lTaxRate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Modal
      width={800}
      open={true}
      title={`${id ? 'Edit' : 'Add'} R & D Rates`}
      okText={<><SaveOutlined /> Save</>}
      cancelText={<><CloseOutlined /> Cancel</>}
      onCancel={() => onDismiss(false)}
      onOk={async () => {
        let Id = id === undefined || id === null ? "" : id;
        let values = undefined; 
        values = await form.validateFields().then(()=> {
          values = form.getFieldsValue();
          return form.getFieldsValue();
        }).catch((err)=>{});

        if(values !== undefined){
          let res = await CTConfigurationService.addRnDRateItem(values, Id);
          if (res.status) {
            onDismiss(true);
          } else {
            openNotification(res.message);
          }
        } else{
          openNotification("error", "Fill Mandatory Fields");
        }
      }}
    >
      <Form form={form} requiredMark={false}>
        <Row className="px-3 py-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              rules={[
                  {
                    required: true,
                    message: "Mandatory field",
                  },
                ]}
              label="Start Date"
              name="startDate"
            >
              <DatePicker 
                placeholder="Start Date" 
                format='DD/MM/YYYY' 
                style={{ width: "100%" }} 
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Minimum Amount"
              name="minAmount"
            >
              <InputNumber
                controls={false}
                placeholder="Minimum Amount"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Minimum Rate"
              name="minRate"
            >
              <InputNumber
                controls={false}
                placeholder="Minimum Rate"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"  
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="SME RnD Relief Rate"
              name="lcsRate"
            >
              <InputNumber
                controls={false}
                placeholder="SME RnD Relief Rate"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="SME Vacc RnD Relief Rate"
              name="lcsVaccRate"
            >
              <InputNumber
                controls={false}
                placeholder="SME Vacc RnD Relief Rate"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="SME Tax Rate"
              name="taxRate"
            >
              <InputNumber
                controls={false}
                placeholder="SME Tax Rate"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="LCS RnD Relief Rate"
              name="smeRate"
            >
              <InputNumber
                controls={false}
                placeholder="LCS RnD Relief Rate"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="LCS Vacc RnD Relief Rate"
              name="smeVaccRate"
            >
              <InputNumber
                controls={false}
                placeholder="LCS Vacc RnD Relief Rate"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="LCS Tax Rate"
              name="lTaxRate"
            >
              <InputNumber
                controls={false}
                placeholder="LCS Tax Rate"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddRnDRates;
