import React from 'react'
import { EditTwoTone,  PlusOutlined } from "@ant-design/icons";
import {
  SelectProps,
  Row,
  Col,
  Button,
  Select,
  Table,
  Form,
} from "antd";
import { ColumnsType } from "antd/es/table";
import {  EmployeesNICategory, INIRates } from "../../Services/PayrollYearlySettingsService";
import dayjs from "dayjs";
import Link from "antd/es/typography/Link";


const NIRates = ({
  OnCancelClick,
  setDialog,
  NIRatesList,
}: any) => {
  const [niCategoryOptions, setNICategoryOptions] = React.useState<
    SelectProps["options"]
  >([]);
  const [ niRatesListData, setNIRatesListData ] = React.useState(NIRatesList || []);
  const [params, setParams] = React.useState({
    niCategory: 0,
    isAM: 0,
  })
  const columns: ColumnsType<INIRates> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "4%",
      render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
    },
    {
      title: "NICategory",
      dataIndex: "niCategory",
      key: "NICategory",
      width: "5%",
      render: (_:any, record: any, index: any) => <p>{EmployeesNICategory[record?.niCategory - 1]}</p>
    },
    {
      title: "Annual Method",
      dataIndex: "isAM",
      key: "IsAM",
      width: "8%",
      render: (_:any, record: any, index: any) => <>{record?.isAM ? "Yes" : "No"}</>
    },
    {
      title: "PayDate From",
      dataIndex: "payDateFrom",
      key: "PayDateFrom",
      width: "8%",
      render: (_:any, record: any, index: any) => <>{dayjs(record?.payDateFrom).format("DD/MM/YYYY")}</>
    },
    {
      title: "PayDate To",
      dataIndex: "payDateTo",
      key: "PayDateTo",
      width: "8%",
      
      render: (_:any, record: any, index: any) => <>{dayjs(record?.payDateTo).format("DD/MM/YYYY")}</>
    },
    {
      title: "EE Rate LEL",
      dataIndex: "eeRateLEL",
      key: "EERateLEL",
      width: "6%",
    },
    {
      title: "EE Rate UEL",
      dataIndex: "eeRateUEL",
      key: "EERateUEL",
      width: "6%",
    },
    {
      title: "EE Rate AUEL",
      dataIndex: "eeRateAUEL",
      key: "AUEL",
      width: "7%",
    },
    {
      title: "EE Rate PT",
      dataIndex: "eeRatePT",
      key: "EERatePT",
      width: "6%",
    },
    {
      title: "EE Rate UST",
      dataIndex: "eeRateUST",
      key: "EERateUST",
      width: "6%",
    },
    // {
    //   title: "EE Rate AUST",
    //   dataIndex: "eeRateAUST",
    //   key: "EERateAUST",
    //   width: "5%",
    // },
    // {
    //   title: "EE Rate FUST",
    //   dataIndex: "eeRateFUST",
    //   key: "EERateFUST",
    //   width: "5%",
    // },
    // {
    //   title: "EE Rate VUST",
    //   dataIndex: "eeRateVUST",
    //   key: "EERateVUST",
    //   width: "5%",
    // },
    {
      title: "ER Rate ST",
      dataIndex: "erRateST",
      key: "ERRateST",
      width: "6%",
    },
    {
      title: "ER Rate UST",
      dataIndex: "erRateUST",
      key: "ERRateUST",
      width: "6%",
    },
    // {
    //   title: "ER Rate FUST",
    //   dataIndex: "erRateFUST",
    //   key: "ERRateFUST",
    //   width: "5%",
    // },
    // {
    //   title: "ER Rate AUST",
    //   dataIndex: "erRateAUST",
    //   key: "ERRateAUST",
    //   width: "5%",
    // },
    // {
    //   title: "ER Rate VUST",
    //   dataIndex: "erRateVUST",
    //   key: "ERRateVUST",
    //   width: "5%",
    // },
    {
      title: "ER Rate UEL",
      dataIndex: "erRateUEL",
      key: "ERRateUEL",
      width: "6%",
    },
    {
      title: "ER Rate AUEL",
      dataIndex: "erRateAUEL",
      key: "ERRateAUEL",
      width: "7%",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "Id",
      width: "3%",
      render: (_: any, record: any, index: any) => (
        <Link
          className="edit-btn"
          onClick={() => {
            setDialog({ type: "AddNIRate", params: { record: record } });
          }}
        >
          <EditTwoTone />
        </Link>
      ),
    },
  ];

  React.useEffect(() =>{
      let lst: any = [];
      if(params.isAM === 0 && params.niCategory === 0){
        lst = NIRatesList;
      } else if(params.isAM === 0 && params.niCategory > 0){
        lst = NIRatesList?.filter((ni:any) => ni.niCategory === params.niCategory);
      } else {
        lst = NIRatesList?.filter((ni: any) => {
          if (params.isAM === 1) {
            return ni.isAM === true && ( params.niCategory > 0 ? ni.niCategory === params.niCategory : ni.niCategory !== 0);
          } else if (params.isAM === 2) {
            return ni.isAM === false && (params.niCategory > 0 ? ni.niCategory === params.niCategory : ni.niCategory !== 0);
          }
          return false;
      });
    }
      setNIRatesListData(lst);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[params, NIRatesList]);
  React.useEffect(() => {
    let opt: any = [];
    [ "All" ,"A", "B", "C", "H", "J", "M", "X", "Z", "F", "I", "L", "S", "V",].map((item: any, idx: any) => {
      opt.push({
        label: item,
        value: idx,
      });
      return item;
    });
    setNICategoryOptions(opt);
  }, []);
  return (
    <>
      <Row
        className="roundedCornerSmall p-3"
        style={{ backgroundColor: "white" }}
      >
        <Col span={24}>
          <Row justify="space-between" className="pb-3">
            <Button
              type="primary"
              className="add-btn"
              onClick={() => setDialog({ type: "AddNIRate", params: {} })}
            >
              <PlusOutlined />
              Add NI Rate
            </Button>
            <Row className='pe-3' style={{height:28, width:'fit-content'}}>
              {/* <Col>NI Category</Col> */}
              <Form.Item
              label="NI Category"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              >
              <Select
                className="me-3 line-height-28"
                style={{
                  height: "22px !important",
                  minWidth: 170,
                  lineHeight: "22px !important",
                }}
                placeholder="Ni Category"
                value={params?.niCategory}
                options={niCategoryOptions}
                onChange={(val) => setParams({ ...params, niCategory: val })}
                />
                </Form.Item>
              {/* <Col>Annual Method</Col> */}
              <Form.Item
              label="Annual Method"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              >

              <Select
                className="me-3 line-height-28"
                style={{
                  height: "22px !important",
                  minWidth: 170,
                  lineHeight: "22px !important",
                }}
                placeholder="Annual Method Exist"
                options={[
                  { value: 0, label: "All" },
                  { value: 1, label: "Yes" },
                  { value: 2, label: "No" },
                ]}
                value={params?.isAM}
                onChange={(val) => setParams({ ...params, isAM: val })}
                />
                </Form.Item>
            </Row>
          </Row>
        </Col>
        <Col span={24} style={{ maxHeight: window.innerHeight - 340, overflowY:"scroll" }}>
          <Table
            dataSource={niRatesListData}
            columns={columns}
            size="small"
            className="aoa-custom-table"
            // loading={{
            //   spinning: loading,
            //   indicator: (
            //     <Spin
            //       indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
            //     />
            //   ),
            // }}
            pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: 1,
                pageSize: niRatesListData?.length,
                showSizeChanger: false,
                total: niRatesListData?.length,
                position: ['bottomRight'],
                style:{ position:"sticky", right: 0, bottom: 0, backgroundColor:"white"}
                // pageSizeOptions: ["15", "25", "50", "100"],
              }}
            scroll={{ x: 700 }}
          />
        </Col>
      </Row>
    </>
  );
};


export default NIRates