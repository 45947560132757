import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  InputNumber,
  Modal,
  Row,
  Select,
  SelectProps,
  Switch,
} from "antd";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import React from "react";
import { useParams } from "react-router-dom";
import PayrollYearlySettingsService from "../../Services/PayrollYearlySettingsService";
import { adminContext } from "../../Common/PageRoute";
import { Utils } from "../../Utilities/Utils";

const AddNIRate = ({ onDismiss, itemId, record }: any) => {
  const [form] = useForm();
  const { id } = useParams();
  const { openNotification } = React.useContext(adminContext);
  
  const [loading, setLoading ] = React.useState(false);
  const [niCategoryOptions, setNICategoryOptions] = React.useState<
    SelectProps["options"]
  >([]);

  React.useEffect(()=>{
    form.setFieldsValue({
        id: record?.id,
        niCategory: record?.niCategory,
        isAM: record?.isAM,
        payDateFrom: record?.payDateFrom ? dayjs(record?.payDateFrom) : undefined,
        payDateTo: record?.payDateTo ? dayjs(record?.payDateTo) : undefined,
        eeRateLEL: record?.eeRateLEL,
        eeRatePT: record?.eeRatePT,
        eeRateUST: record?.eeRateUST,
        eeRateAUST: record?.eeRateAUST,
        eeRateVUST: record?.eeRateVUST,
        eeRateFUST: record?.eeRateFUST,
        eeRateUEL: record?.eeRateUEL,
        eeRateAUEL: record?.eeRateAUEL,
        erRateST: record?.erRateST,
        erRateUST: record?.erRateUST,
        erRateAUST: record?.erRateAUST,
        erRateVUST: record?.erRateVUST,
        erRateFUST: record?.erRateFUST,
        erRateUEL: record?.erRateUEL,
        erRateAUEL: record?.erRateAUEL,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[itemId, record])

  React.useEffect(() => {
    let opt: any = [];
    ["A", "B", "C", "H", "J", "M", "X", "Z", "F", "I", "L", "S", "V"].map(
      (item: any, idx: any) => {
        opt.push({
          label: item,
          value: idx + 1,
        });
        return item;
      }
    );
    setNICategoryOptions(opt);
  }, []);
  return (
    <Modal
      title={itemId ? "Edit NI Rate item" : "Add NI Rate item"}
      width={900}
      open={true}
      okText={<><SaveOutlined /> Save</>}
      cancelText={<><CloseOutlined /> Cancel</>}
      okButtonProps={{loading: loading}}
      cancelButtonProps={{loading: loading}}
      onCancel={() => onDismiss(false)}
      onOk={async ()=>{
        setLoading(true);
        const yearlySettingsRecordId = id ?? "";
        const ItemId = itemId ?? "";
        const values = form.getFieldsValue();
        const val = {...values, id: record?.id}
        let res = await PayrollYearlySettingsService.updateNIRatesItem(yearlySettingsRecordId, val, ItemId);
        if(res.status){
          openNotification("success", "Details updated successfully")
          onDismiss(true);
          setLoading(false);
        } else {
          openNotification("error", res.message);
          setLoading(false);
        }
      }}
    >
      <Form form={form} requiredMark={false}>
        <Row className="px-3 py-1">
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="NI Category"
              name="niCategory"
              rules={[{ required: true, message: "Select a NI Category" }]}
            >
              <Select
                placeholder="Select NI Category"
                className="line-height-28"
                options={niCategoryOptions}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="PayDate From"
              name="payDateFrom"
            >
              <DatePicker
                placeholder="PayDate From"
                format='DD/MM/YYYY'
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="PayDate To"
              name="payDateTo"
            >
              <DatePicker 
                placeholder="PayDate To" 
                format='DD/MM/YYYY' 
                style={{ width: "100%" }} 
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 19 }}
              label="Annual Method"
              name="isAM"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Col className="font-semibold d-flex px-3 py-1" span={24}>
          Employee's contribution limits
          <span
            style={{
              width: "70%",
              height: 0,
              marginTop: 10,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 py-1">
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="EE Rate LEL"
              name="eeRateLEL"
            >
              <InputNumber
                controls={false}
                placeholder="EE Rate LEL"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="EE Rate PT"
              name="eeRatePT"
            >
              <InputNumber
                controls={false}
                placeholder="EE Rate PT"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="EE Rate UST"
              name="eeRateUST"
            >
              <InputNumber
                controls={false}
                placeholder="EE Rate UST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="EE Rate FUST"
              name="eeRateFUST"
            >
              <InputNumber
                controls={false}
                placeholder="EE Rate FUST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="EE Rate AUST"
              name="eeRateAUST"
            >
              <InputNumber
                controls={false}
                placeholder="EE Rate AUST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="EE Rate VUST"
              name="eeRateVUST"
            >
              <InputNumber
                controls={false}
                placeholder="EE Rate VUST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="EE Rate UEL"
              name="eeRateUEL"
            >
              <InputNumber
                controls={false}
                placeholder="EE Rate UEL"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="EE Rate AUEL"
              name="eeRateAUEL"
            >
              <InputNumber
                controls={false}
                placeholder="EE Rate AUEL"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Col className="font-semibold d-flex px-3 py-1" span={24}>
          Employer's contribution limits
          <span
            style={{
              width: "70%",
              height: 0,
              marginTop: 10,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 py-1">
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="ER Rate ST"
              name="erRateST"
            >
              <InputNumber
                controls={false}
                placeholder="ER Rate ST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="ER Rate UST"
              name="erRateUST"
            >
              <InputNumber
                controls={false}
                placeholder="ER Rate UST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="ER Rate FUST"
              name="erRateFUST"
            >
              <InputNumber
                controls={false}
                placeholder="ER Rate FUST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="ER Rate AUST"
              name="erRateAUST"
            >
              <InputNumber
                controls={false}
                placeholder="ER Rate AUST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="ER Rate VUST"
              name="erRateVUST"
            >
              <InputNumber
                controls={false}
                placeholder="ER Rate VUST"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="ER Rate UEL"
              name="erRateUEL"
            >
              <InputNumber
                controls={false}
                placeholder="ER Rate UEL"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="ER Rate AUEL"
              name="erRateAUEL"
            >
              <InputNumber
                controls={false}
                placeholder="ER Rate AUEL"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddNIRate;
