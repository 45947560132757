import { CloseOutlined,  SaveOutlined } from "@ant-design/icons";
import { Col, Form, InputNumber, Modal, Row, Select, Switch } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { useParams } from "react-router-dom";
import PayrollYearlySettingsService, { CarAllowanceListType } from "../../Services/PayrollYearlySettingsService";
import { getEnumName } from "../../Common/CommonComponent";
import { adminContext } from "../../Common/PageRoute";
import { Utils } from "../../Utilities/Utils";

const AddCarAllowanceItem = ({
  onDismiss,
  itemId,
  record,
  carAllowanceListType,
}: any) => {
  const [form] = useForm();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const { openNotification } = React.useContext(adminContext);
  
  var ty = getEnumName(carAllowanceListType, CarAllowanceListType);

  React.useEffect(() => {
    if (itemId !== undefined && itemId !== null && itemId !== "") {
      form.setFieldsValue({
        id: record?.id,
        fuelType: record?.fuelType,
        calcPer: record?.calcPer,
        isOld: record?.isOld,
      });
      if (carAllowanceListType === CarAllowanceListType.CarCo2Emmission) {
        form.setFieldsValue({
          co2EmmisionFrom: record?.co2EmmisionFrom,
          co2EmmisionTo: record?.co2EmmisionTo,
        });
      } else if (carAllowanceListType === CarAllowanceListType.CarEngineSize) {
        form.setFieldsValue({
          engineSizeFrom: record?.engineSizeFrom,
          engineSizeTo: record?.engineSizeTo,
        });
      } else if (carAllowanceListType === CarAllowanceListType.CarEleRange) {
        form.setFieldsValue({
          eleRangeFrom: record?.eleRangeFrom,
          eleRangeTo: record?.eleRangeTo,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, record, carAllowanceListType]);
  return (
    <Modal
      title={itemId ? `Edit ${ty} Allowance item` : `Add ${ty} Allowance item`}
      width={600}
      open={true}
      okText={
        <>
          <SaveOutlined /> Save
        </>
      }
      cancelText={
        <>
          <CloseOutlined /> Cancel
        </>
      }
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ loading: loading }}
      onCancel={() => onDismiss(false)}
      onOk={async () => {
        setLoading(true);
        const yearlySettingsRecordId = id ?? "";
        const ItemId = itemId ?? "";
        form.validateFields().then( async (values) =>{
          const val = {...values, id: record?.id}
          let res = await PayrollYearlySettingsService.updateCarAllowanceItem(
          yearlySettingsRecordId,
          carAllowanceListType,
          val,
          ItemId
          );
          if (res.status) {
            openNotification("success", "Details updated successfully");
            onDismiss(true);
            setLoading(false);
          } else {
            openNotification("error", res.message);
            setLoading(false);
          }
      }).catch((error)=>{
        setLoading(false);
      });
      }}
    >
      <Form form={form} requiredMark={false}>
        <Row className="px-3 py-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              rules={[
                {
                  required: true,
                  message: "Mandatory field",
                },
              ]}
              label="Fuel type"
              name="fuelType"
            >
              <Select
                placeholder="Select Fuel type"
                className="line-height-28"
                options={[
                  { value: 1, label: "Diesel Euro 6d" },
                  { value: 2, label: "Diesel" },
                  { value: 3, label: "Other" },
                  { value: 4, label: "Hybrid" },
                  { value: 5, label: "Electric" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Calculation percentage"
              name="calcPer"
            >
              <InputNumber
                controls={false}
                placeholder="Calculation percentage"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              style={{ display: "flex", alignItems: "start", textAlign: "end" }}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 12 }}
              label="Is old"
              name="isOld"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        {carAllowanceListType === CarAllowanceListType.CarCo2Emmission && (
          <Row className="px-3 py-1">
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Co2 emmission from"
                name="co2EmmisionFrom"
              >
                <InputNumber
                  controls={false}
                  placeholder="Co2 emmission from"
                  prefix="g/km"
                  onKeyPress={(e) =>  Utils.ValidateNumberOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Co2 emmission to"
                name="co2EmmisionTo"
              >
                <InputNumber
                  controls={false}
                  placeholder="Co2 emmission to"
                  prefix="g/km"
                  onKeyPress={(e) =>  Utils.ValidateNumberOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {carAllowanceListType === CarAllowanceListType.CarEngineSize && (
          <Row className="px-3 py-1">
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Engine size from"
                name="engineSizeFrom"
              >
                <InputNumber
                  controls={false}
                  placeholder="Engine size from"
                  prefix="cc"
                  onKeyPress={(e) =>  Utils.ValidateNumberOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Engine size to"
                name="engineSizeTo"
              >
                <InputNumber
                  controls={false}
                  placeholder="Engine size to"
                  prefix="cc"
                  onKeyPress={(e) =>  Utils.ValidateNumberOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
        {carAllowanceListType === CarAllowanceListType.CarEleRange && (
          <Row className="px-3 py-1">
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Ele Range from"
                name="eleRangeFrom"
              >
                <InputNumber
                  controls={false}
                  placeholder="Ele Range from"
                  prefix="cc"
                  onKeyPress={(e) =>  Utils.ValidateNumberOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Ele Range to"
                name="eleRangeTo"
              >
                <InputNumber
                  controls={false}
                  placeholder="Ele Range to"
                  prefix="cc"
                  onKeyPress={(e) =>  Utils.ValidateNumberOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default AddCarAllowanceItem;
