import { notification, SelectProps } from "antd";
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import Practices from "../Components/Practices/Practices";
import Dashboard from "../Components/Dashboard/Dashboard";
import PracticeDetails from "../Components/Practices/PracticeDetails";
import Updates from "../Components/Updates/Updates";
import CTConfiguration from "../Components/CTConfiguration/CTConfiguration";
import Profile from "../Components/Profile/Profile";
import PayrollYearlySettings from "../Components/PayrollYearlySettings/PayrollYearlySettings";
import PayrollYearlySettingsDetails from "../Components/PayrollYearlySettings/PayrollYearlySettingsDetails";
import SA100Configuration from "../Components/SA100Configuration/SA100Configuration";
import ApplicationConfigKey from "../Components/AppConfigKey/ApplicationConfigKey";
import AdminUsers from "../Components/Users/AdminUsers";
import UserProfile from "../Components/Users/UserProfile";
import { getCountries } from "./CommonComponent";
import { useUser } from "../Context/UserContext";

export const adminContext = React.createContext<any>(null);

const PageRoute: React.FC = () => {
  type NotificationType = "success" | "info" | "warning" | "error";
  const { isAuthenticated, isSuperAdmin, isSubAdmin } = useUser();
  const [practiceData, setPracticeData] = React.useState<any>(undefined);
  const [payrollYearlySettingsData, setPayrollYearlySettingsData] =
    React.useState<any>(undefined);
  const [api, contextHolder] = notification.useNotification();
  const [countriesOptions, setCountriesOptions] = React.useState<
    SelectProps["options"]
  >([]);
  const openNotification = (
    type: NotificationType,
    message: string | undefined
  ) => {
    api[type]({
      message: "",
      description: message,
      duration: 4,
      style: {
        width: 400,
        top: 18,
        borderRadius: 0,
        padding: "20px 12px",
      },
    });
  };

  const location = useLocation();

  React.useEffect(() => {
    getCountriesOptions();
  }, []);

  const getCountriesOptions = async () => {
    const COptions: SelectProps["options"] = [];
    var countries: any = await getCountries();
    if (countries) {
      let Count = countries?.map((item: any) => {
        COptions.push({
          label: item?.name,
          value: item?.id,
        });
        return item;
      });
      if (Count.length > 0) setCountriesOptions(COptions);
      // return countriesOptions1;
    }
  };

  React.useEffect(() => {
    const path = location.pathname;
    let title = "Acting Office"; // Set a default title
    if (path.startsWith("/profile")) {
      title = `Profile-${title}`;
    } else if (path.startsWith("/practices")) {
      title = `Practices-${title}`;
    } else if (path.startsWith("/dashboard")) {
      title = `Dashboard-${title}`;
    } else if (path.startsWith("/updates")) {
      title = `Updates-${title}`;
    } else if (path.startsWith("/ctconfiguration")) {
      title = `CTConfiguration-${title}`;
    } else if (path.startsWith("/payrollyearlysettings")) {
      title = `Payroll Yearly Settings-${title}`;
    }

    document.title = title;
  }, [location]);

  return (
    <adminContext.Provider
      value={{
        openNotification,
        countriesOptions,
        practiceData,
        setPracticeData,
        payrollYearlySettingsData,
        setPayrollYearlySettingsData,
      }}
    >
      <Routes>
        {isAuthenticated() && isSuperAdmin() && (
          <>
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/practices" element={<Practices />} />
            <Route
              path="/practices/:id"
              element={<PracticeDetails data={practiceData} />}
            />
            <Route path="/adminusers" element={<AdminUsers />} />
            <Route path="/adminusers/:id" element={<UserProfile />} />
            <Route path="/ctconfiguration" element={<CTConfiguration />} />
            <Route
              path="/payrollyearlysettings"
              element={<PayrollYearlySettings />}
            />
            <Route
              path="/ApplicationConfigKey"
              element={<ApplicationConfigKey />}
            />
            <Route
              path="/payrollyearlysettings/:id"
              element={
                <PayrollYearlySettingsDetails
                data={payrollYearlySettingsData}
                />
              }
            />
            <Route
              path="/sa100configuration"
              element={<SA100Configuration />}
            />
          </>
        )}
        {isAuthenticated() && (isSuperAdmin() || isSubAdmin()) && (
          <>
            <Route path="/profile" element={<Profile />} />
            <Route path="/updates" element={<Updates />} />
          </>
        )}
      </Routes>
      {contextHolder}
    </adminContext.Provider>
  );
};
export default PageRoute;
