import { SelectProps } from 'antd';
import CommonService from '../Services/CommonService';


export const getTimeZones = () => {
    const moment = require('moment-timezone');
    return  moment.tz.names();

} 
export const getTimeZonesOptions = () => {
    const timeZoneOptions: SelectProps['options'] = [];
    var timeZones = getTimeZones();
    const moment = require('moment-timezone');
    timeZones.map((timezone:any) => {
        const currentTimezone = moment.tz(timezone);
        const offsetString = currentTimezone.format('Z');
        timeZoneOptions.push({
          label: `(${offsetString}) ${timezone}`,
          value: timezone,
        });
        return `(${offsetString}) ${timezone}`;
    })
    return timeZoneOptions;
}
export const getCountries = async () => {
    let res =  await CommonService.getCountriesList();
    return res;
}
export const getEnumName = (enumValue: number, enumType: any) => {
    const keys = Object.keys(enumType).filter((key) => isNaN(Number(key)));
    for (const key of keys) {
      if (enumType[key] === enumValue) {
        return key;
      }
    }
  }