import { ApiUtility } from '../Utilities/ApiUtility.axios';

export interface IPracticeAddress {
    building: string;
    city: string;
    country: string;
    county: string;
    postcode: string;
    street: string;
  }
  
  export interface IPracticeParams {
    dueInvoicesTaskDays: number;
    pageTrackTimeout: number;
    taskDueDateInDays: number;
  }
  
  export interface IPracticeAppFeature {
    accounts: boolean;
    aml: boolean;
    bankStatement: boolean;
    bookkeeping: boolean;
    calls: boolean;
    chats: boolean;
    clientDataRequest: boolean;
    corporationTax: boolean;
    dayEnd: boolean;
    leadSources: boolean;
    linkedinLeads: boolean;
    payroll: boolean;
    teams: boolean;
    webForms: boolean;
    timesheet: boolean;
    careOf: boolean;
    selfAssessment: boolean;
    dms: boolean;
    other4: boolean;
    other5: boolean;
  }
  
  export interface IPracticeAppSubFeature {
    actionTab: boolean;
    addCustomerWithoutLead: boolean;
    digitalSignatureWithoutAML: boolean;
    manualTimesheet: boolean;
    progressiveLeads: boolean;
    soaIsEnable: boolean;
    soaIsNotificationEnable: boolean;
    websiteQueriesWithOTP: boolean;
    witoutDiscount: boolean;
    clientAutomation: boolean;
    proofOfEarningFPS: boolean;
    receiptAttachments: boolean;
    skipTeamUpdate: boolean;
    secureLink: boolean;
    bankDetails: boolean;
    amLforAllOfficers: boolean;
    firstResponse: boolean;
    accountManagar: boolean;
    accountant: boolean;
    mode: boolean;
    agentCredentialsRemark: boolean;
    clientBrand: boolean;
    clientProposition: boolean;
    S455TaxLiability: boolean;
    mtsAutomationSettings: {
      automate: boolean;
      days: number;
    },
    soaNotificationSettings: {
      day: number;
      frequency: number;
      amount: number;
      notifyOnLastDay: boolean;
    },
    noReply: {
      enabled: boolean;
      replyEmail: string;
    },
    reviewRequestConfig: {
      APCT: boolean;
      SA100: boolean;
      SA800: boolean;
      VAT: boolean;
    },
  }
  
  export interface IPracticeBankAccount {
    accountName: string;
    accountNumber: string;
    bankName: string;
    bik: string;
    iban: string;
    shortCode: string;
  }
  
  export interface IPracticeWebsite {
    iconUrl: string;
    logoUrl: string;
    url: string;
    urlPrivacy: string;
    urlTnC: string;
  }
  
  export enum PracticeSequenceTypes {
    None = 1,
    Yearly = 2,
    Monthly = 3,
  }
  
  export interface IPracticeSequence {
    type: PracticeSequenceTypes;
    prefix: string;
    postfix: string;
    seperator: string;
    leadingZero: number;
  }
  
  export interface IPractice {
    address: IPracticeAddress;
    params: IPracticeParams;
    appFeature: IPracticeAppFeature;
    appSubFeature: IPracticeAppSubFeature;
    bankAccount: IPracticeBankAccount;
    website: IPracticeWebsite;
    name: string;
    email: string;
    phoneNumber: number;
    timeZone: string;
    vat: {
      enabled: boolean;
      rate: number;
    };
    emailProvider: number;
    defaultBCCs: any[];
    defaultCCs: any[];
    sequences: {
      invoice: IPracticeSequence;
      quote: IPracticeSequence;
    };
  }

  export const enum PracticeUpdateTypes {
    none = 0,
    basicinformation = 1,
    address = 2,
    bankaccountdetails = 3,
    timezonevat = 4,
    emails = 5,
    praticeproperites = 6,
    praticesequences = 7,
    features = 8,
    reviewRequest = 9
  }
  
class PracticesService{
    route = `/Api/Practices`;
    // Get Practices List 
    // start=${start}&length=${length}&search=${search}&sortDir=${sortDir}&sortCol=${sortCol}
    getPracticesList = ( start:number, length:any, search:string, sortCol:any, sortDir:any) =>   ApiUtility.get(`${this.route}`, {start, length, search, sortDir, sortCol});

    getPracticeDetails = ( id:any) => ApiUtility.get(`${this.route}/${id}`);
    
    getFeaturePracticeDetails = ( id:any) => ApiUtility.get(`${this.route}/${id}/features`);

    updatePracticeDetails = (id:any, type: PracticeUpdateTypes, params:any) => ApiUtility.post(`${this.route}/update/${id}/${type}`, params);

    addPractices = (params:any, id?:string) => ApiUtility.post(`${this.route}/addpractices/${id}`, params)

}
export default new PracticesService();