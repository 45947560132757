import React from 'react';
import MainLayout from './Common/MainLayout';

const App = () => { 
    return (   
        <React.Fragment>
            <MainLayout /> 
            </React.Fragment>
    );    
}

export default App;
