import { ApiUtility } from "../Utilities/ApiUtility.axios";

export interface IYearlyPayrollSetting {
  id: string;
  yearId: number;
  defaultTaxCode: string;
  awe: number;
  eaLimit: number;
  sspWkPay: number;
  sapawePay: number;
  smpawePay: number;
  ppBirthAWEPay: number;
  ppAdoptionAWEPay: number;
  stdLoanPlan1Weekly: number;
  stdLoanPlan1Monthly: number;
  stdLoanPlan1Annual: number;
  stdLoanPlan2Weekly: number;
  stdLoanPlan2Monthly: number;
  stdLoanPlan2Annual: number;
  stdLoanPlan4Weekly: number;
  stdLoanPlan4Monthly: number;
  stdLoanPlan4Annual: number;
  postgraduateLoanWeekly: number;
  postgraduateLoanMonthly: number;
  postgraduateLoanAnnual: number;
  stdLoanPer: number;
  postgraduateLoanPer: number;
  pensionLowerLimit: number;
  pensionUpperLimit: number;
  loanIntRate: number;
  eeDirAnnualLimit: number;
  erDirAnnualLimit: number;
  taTaxableFrom: number;
  p60BottomLine: string;
  fpsns: string;
  fpsVer: string;
  epsns: string;
  epsVer: string;
  nvrns: string;
  nvrVer: string;
  eyuns: string;
  eyuVer: string;
  niThresholds: INIThresholds[];
  niRates: INIRates[];
  taxData: ITaxData[];
  fixedCodeTax: IFixedCodeTax;
  carCo2Emmission: ICarCo2Emmission[];
  carEleRanage: ICarEleRanage[];
  carEngineSize: ICarEngineSize[];
  statePensionAge: number;
  deaStandard: IAEOThresholds[];
  deaHigher: IAEOThresholds[];
  deaeas: IAEOThresholds[];
  deamc: IAEOThresholds[];
  deactw: IAEOThresholds[];
  deacte: IAEOThresholds[];
}

interface IAEOThresholds {
  payFrequency: EmployeePayFrequency;
  lowerLimit: number;
  higherLimit: number;
  Rate: number;
}
export interface INIThresholds {
  payFrequency: EmployeePayFrequency;
  director: boolean;
  payDateFrom: Date;
  payDateTo: Date;
  lel: number;
  pt: number;
  st: number;
  ust: number;
  aust: number;
  vust: number;
  fust: number;
  uel: number;
  auel: number;
}

export interface INIRates {
  niCategory: number;
  isAM: boolean;
  payDateFrom: Date;
  payDateTo: Date;
  eeRateLEL: number;
  eeRatePT: number;
  eeRateUST: number;
  eeRateAUST: number;
  eeRateVUST: number;
  eeRateFUST: number;
  eeRateUEL: number;
  eeRateAUEL: number;
  erRateST: number;
  erRateUST: number;
  erRateAUST: number;
  erRateVUST: number;
  erRateFUST: number;
  erRateUEL: number;
  erRateAUEL: number;
}

export interface ITaxData {
  id:number;
  taxCodeType: TaxCodeType;
  payFrequency: number;
  period: number;
  minAmount: number;
  maxAmount: number;
  taxPer: number;
}

interface ICarCo2Emmission {
  isOld: boolean;
  fuelType: number;
  co2EmmisionFrom: number;
  co2EmmisionTo: number;
  calcPer: number;
}

interface ICarEleRanage {
  isOld: boolean;
  fuelType: number;
  eleRangeFrom: number;
  eleRangeTo: number;
  calcPer: number;
}

interface ICarEngineSize {
  isOld: boolean;
  fuelType: number;
  engineSizeFrom: number;
  engineSizeTo: number;
  calcPer: number;
}

interface IFixedCodeTax {
  br: number;
  d0: number;
  d1: number;
  sbr: number;
  sD0: number;
  sD1: number;
  sD2: number;
  cbr: number;
  cD0: number;
  cD1: number;
}

export enum TaxCodeType {
  EnglishNorthernIrish = 1,
  Scottish = 2,
  Welsh = 3,
}
export enum FuelTypes {
  DieselEuro6d = 1,
  Diesel,
  Other,
  Hybrid,
  Electric,
}
export enum EmployeePayFrequency {
  Monthly = 1,
  Weekly = 2,
  Two_Weekly = 3,
  Four_Weekly = 4,
  Annual = 5,
  Fortnightly = 6,
}
export enum EmployeesNICategory {
  A,
  B,
  C,
  H,
  J,
  M,
  X,
  Z,
  F,
  I,
  L,
  S,
  V,
}
export const enum PayrollYearlySettingsTabs {
  general = 1,
  allowanceLimitsNdloansThersholds = 2,
  niThresholds = 3,
  niRates = 4,
  taxData = 5,
  carAllowances = 6,
  aeoRates = 7,
}

export const PayrollYearlySettingsTabsNameList = ["Generals", "Allowances Limits & Loans Thersholds", "NI Thersholds", "NI Rates", "Tax Data", "Car Allowances"]
export const enum PayrollYearlySettingsUpdateTypes {
  none = 0,
  generalInformation = 1,
  fixedCodeTax = 2,
  allowanceLimits = 3,
  loansThersholds = 4,
  niThresholds = 5,
  niRates = 6,
  taxData = 7,
  carAllowances = 8,
}
 
export enum CarAllowanceListType
{
    CarCo2Emmission = 1,
    CarEngineSize = 2,
    CarEleRange = 3,
}
export const enum AEORatesType
{
    DEAStandard = 1,
    DEAHigher = 2,
    DEAEAS = 3,
    DEAMC = 4,
    DEACTW = 5,
    DEACTE = 6,
}
class PayrollYearlySettingsService {
    route = `/Api/PayrollYearlySettings`;

  getPayrollYearlySettingsList = (
    start: number,
    length: any,
    search: string,
    sortCol: any,
    sortDir: any
  ) =>
    ApiUtility.get(`${this.route}`, {
      start,
      length,
      search,
      sortDir,
      sortCol,
    });

  getPayrollYearlySettings = (
    id: any,
    type: number,
    carAllowanceListType: CarAllowanceListType,
    aeoRates: AEORatesType
  ) =>
    ApiUtility.get(`${this.route}/${id}`, {
      type,
      carAllowanceListType,
      aeoRates
    });

    postPayrollYearlySettingsDetails = (id:string, type: number, params:any) => ApiUtility.post(`${this.route}/${id}/${type}`, params);

    AddPayrollYearlySettings = (params:any, id?:string) => ApiUtility.post(`${this.route}/addpayeyearlysettings?id=${id}`, params);

    updateNIThersholdItem = (id:string, params:any,  itemId?:any,) => ApiUtility.post(`${this.route}/savenithersholds/${id}/${itemId}`, params);
    
    updateNIRatesItem = (id:string, params:any,  itemId?:any,) => ApiUtility.post(`${this.route}/savenirates/${id}/${itemId}`, params);

    updateAEORatesItem = (id:string, listType:AEORatesType, params:any, itemId?:any,) => ApiUtility.post(`${this.route}/saveaeorate/${id}/${listType}/${itemId}`, params);
    
    updateCarAllowanceItem = (id:string, listType:CarAllowanceListType, params:any, itemId?:any,) => ApiUtility.post(`${this.route}/savecarallowance/${id}/${listType}/${itemId}`, params);
};

export default new PayrollYearlySettingsService();




