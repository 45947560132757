import Table, { ColumnsType } from 'antd/es/table';
import React from 'react'
import { EmployeePayFrequency } from '../../Services/PayrollYearlySettingsService';
import Link from 'antd/es/typography/Link';
import { EditTwoTone, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Form, Select, Spin } from 'antd';

const AEORates = ( {
    OnCancelClick,
    setDialog,
    listParams,
    setListParams,
    aeoRatesListType,
  }: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [loading, setLoading] = React.useState(false);

    const columns: ColumnsType<any> = [
        {
          title: "S.No",
          dataIndex: "sno",
          key: "sno",
          width: "4%",
          render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
        },
        {
            title: "Pay Frequency",
            dataIndex: "payFrequency",
            key: "PayFrequency",
            width: "9%",
            render: (_: any, record: any, index: any) => (
              <p>{EmployeePayFrequency[record?.payFrequency]}</p>
            ),
        },
        {
          title: "Lower Limit",
          dataIndex: "lowerLimit",
          key: "LowerLimit",
          width: "20%",
        },
        {
          title: "Higher Limit",
          dataIndex: "higherLimit",
          key: "HigherLimit",
          width: "20%",
        },
        {
          title: "Rate",
          dataIndex: "rate",
          key: "Rate",
          width: "20%",
        },
        {
            title: "Action",
            dataIndex: "id",
            key: "Id",
            width: "3%",
            render: (_: any, record: any, index: any) => (
              <Link
                className="edit-btn"
                onClick={() => {
                  setDialog({ type: "AddAEORates", params: { record: record } });
                }}
              >
                <EditTwoTone />
              </Link>
            ),
          },
      ];

  return (
    <>
    <Row
      className="roundedCornerSmall p-3"
      style={{ backgroundColor: "white" }}
    >
      <Col span={24}>
        <Row justify="space-between" className="pb-3">
          <Button
            type="primary"
            className="add-btn"
            onClick={() => setDialog({ type: "AddAEORates", params: {} })}
          >
            <PlusOutlined />
            Add Item
          </Button>
          <Row className='pe-3' style={{height:28, width:'fit-content'}}>
            <Form.Item
            label="AEO Rates Type"
            labelCol={{ span: 7 }}
            wrapperCol={{ span: 17 }}
            >
            <Select
              className="line-height-28"
              style={{
                height: "22px !important",
                width: 300,
                lineHeight: "22px !important",
              }}
              value={listParams?.aeoRates}
              placeholder="Select AEO Rates Type"
              options={[
                { value: 1, label: "AEO Standard rate" },
                { value: 2, label: "AEO Higher rate" },
                { value: 3, label: "AEO Earnings arrangement scotland rate" },
                { value: 4, label: "AEO Magistrates court rate" },
                { value: 5, label: "AEO Council tax wales rate" },
                { value: 6, label: "AEO Council tax england rate" },
              ]}
              onChange={(val) => setListParams({ ...listParams, aeoRates: val })}
            />
            </Form.Item>
        </Row>
        </Row>
      </Col>
      <Col span={24} style={{ maxHeight: window.innerHeight - 350, overflowY:"scroll" }}>
        <Table
          dataSource={aeoRatesListType}
          columns={columns}
          size="small"
          className="aoa-custom-table"
          loading={{
            spinning: loading,
            indicator: (
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
              />
            ),
          }}
          pagination={{
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} items`,
            current: 1,
            pageSize: aeoRatesListType?.length,
            showSizeChanger: false,
            total: aeoRatesListType?.length,
            position: ['bottomRight'],
            style:{ position:"sticky", right: 0, bottom: 0, backgroundColor:"white"}
            // pageSizeOptions: ["15", "25", "50", "100"],
          }}
          scroll={{ x: 700 }}
        />
      </Col>
    </Row>
  </>
  )
}

export default AEORates