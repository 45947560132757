import { EditTwoTone, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import React from "react";
import { PayrollYearlySettingsUpdateTypes } from "../../Services/PayrollYearlySettingsService";
import { Utils } from "../../Utilities/Utils";

const AllowanceLimitsAndLoans = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
}: any) => {
  return (
    <React.Fragment>
      <Form form={form}>
        <Col className="card border pb-3 border-rounded-1 bg-secondary">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Allowance Limits
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({
                  type: PayrollYearlySettingsUpdateTypes.allowanceLimits,
                })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 py-1">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Average Weekly Earning (AWE)"
                name="awe"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="Average Weekly Earning"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Employment Allowance Limit"
                name="eaLimit"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="Employment Allowance Limit"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Employee's NI start limit as director"
                name="eeDirAnnualLimit"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="Employee's NI start limit as director"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Employeer's NI start limit for director"
                name="erDirAnnualLimit"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="Employeer's NI start limit for director"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col className=" px-3 font-semibold d-flex" span={24}>
            Leave Allowance Limits
            <span
              style={{
                width: "85%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 py-1">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="SSP Weekly Pay"
                name="sspWkPay"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="SSP Weekly Pay"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="SAP AWE Pay"
                name="sapawePay"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="SAP AWE Pay"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="SMP AWE Pay"
                name="smpawePay"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="SMP AWE Pay"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 py-1">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="PP Birth AWE Pay"
                name="ppBirthAWEPay"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="PP Birth AWE Pay"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="PP Adoption AWE Pay"
                name="ppAdoptionAWEPay"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.allowanceLimits
                      ? false
                      : true
                  }
                  placeholder="PP Adoption AWE Pay"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PayrollYearlySettingsUpdateTypes.allowanceLimits && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>

        <Col className="card mt-3 border pb-3 border-rounded-1 bg-secondary">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Loans Thresholds
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({
                  type: PayrollYearlySettingsUpdateTypes.loansThersholds,
                })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 py-1">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="loanIntRate"
                label="Loan Int Rate"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Loan Int Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPer"
                label="Student Loan Percentage"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Student Loan Percentage"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="postgraduateLoanPer"
                label="Postgraduate Loan Percentage"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Postgraduate Loan Percentage"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col className=" px-3 font-semibold d-flex" span={24}>
            Students Loan
            <span
              style={{
                width: "85%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 py-1 font-semibold">
            <Col span={6}>Plan Type</Col>
            <Col span={6}>Weekly</Col>
            <Col span={6}>Monthly</Col>
            <Col span={6}>Annually</Col>
          </Row>
          <Row className="px-3 py-1">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  disabled={true}
                  className="line-height-28"
                  value={1}
                  options={[
                    { value: 1, label: "Plan 1" },
                    { value: 2, label: "Plan 2" },
                    { value: 4, label: "Plan 4" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan1Weekly"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Weekly"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan1Monthly"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Monthly"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan1Annual"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Annually"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 py-1">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  disabled={true}
                  className="line-height-28"
                  value={2}
                  options={[
                    { value: 1, label: "Plan 1" },
                    { value: 2, label: "Plan 2" },
                    { value: 4, label: "Plan 4" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan2Weekly"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Weekly"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan2Monthly"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Monthly"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan2Annual"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Annually"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 py-1">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
              >
                <Select
                  disabled={true}
                  className="line-height-28"
                  value={4}
                  options={[
                    { value: 1, label: "Plan 1" },
                    { value: 2, label: "Plan 2" },
                    { value: 4, label: "Plan 4" },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan4Weekly"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Weekly"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan4Monthly"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Monthly"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="stdLoanPlan4Annual"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Annually"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Col className=" px-3 font-semibold d-flex" span={24}>
            Post graduation Loan
            <span
              style={{
                width: "85%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 py-1">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Loan Type"
              >
                <Select
                  disabled={true}
                  className="line-height-28"
                  value={0}
                  options={[{ value: 0, label: "Post Graduation Loan" }]}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="postgraduateLoanWeekly"
                label="Weekly"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Weekly"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="postgraduateLoanMonthly"
                label="Monthly"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Monthly"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                name="postgraduateLoanAnnual"
                label="Annually"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.loansThersholds
                      ? false
                      : true
                  }
                  placeholder="Annually"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PayrollYearlySettingsUpdateTypes.loansThersholds && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </React.Fragment>
  );
};
export default AllowanceLimitsAndLoans;
