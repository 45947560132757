import {
  SaveOutlined,
  CloseOutlined,
  DeleteTwoTone,
  PlusCircleTwoTone,
  DeleteOutlined,
} from "@ant-design/icons";
import { Col, Form, Input, Modal, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import ApplicationCongfigKeys from "../../Services/ApplicationCongfigKeys";
import { adminContext } from "../../Common/PageRoute";

const AddApplicationConfigKey = ({ record, onDismiss }: any) => {
  const [form] = useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = React.useState(false);

  const SaveAppConfigKey = () =>{
    form.validateFields().then(async (values: any) =>{
      let id = record?.id ?? "";
      values = {id : id, ...values}; 
      setLoading(true);
      await ApplicationCongfigKeys.updateApplicationConfigKeys(id, values).then((res)=>{
        if(res.status) onDismiss(true);
        else onDismiss(false);
      }).finally(()=>{
        setLoading(true);
      });
    })
  }
  if(record?.id){
    form.setFieldsValue(record);
  }
  return (
    <Modal
      title={`${record?.id ? 'Edit': 'Add'} Applicationn Config Keys`}
      open={true}
      confirmLoading={loading}
      width={800}
      okText={
        <>
          <SaveOutlined /> Save
        </>
      }
      cancelText={
        <>
          <CloseOutlined /> Cancel
        </>
      }
      onCancel={() => onDismiss(false)}
      onOk={() => SaveAppConfigKey()}
    >
      <Form form={form}>
        <Row>
          <Col span={18}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Name"
              name="name"
            >
              <Input
                className="line-height-28"
                placeholder="Name"
                // onKeyPress={(e) =>  Utils.ValidateCharSpaceAndNumberOnly(e)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={18}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Application Config Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: "Please select a app config type",
                },
              ]}
            >
              <Select
                placeholder="Select config type"
                options={[
                  { value: 0, label: "Unknown" },
                  { value: 1, label: "User" },
                  { value: 2, label: "Practice" },
                  { value: 3, label: "Company" },
                ]}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.List name="items">
          {(fields, { add, remove }, { errors }) => {
            return (
              <>
                <Row>Key Items</Row>
                <Row className="px-3 py-1 font-semibold">
                  <Col span={7}>key</Col>
                  <Col span={8}>Name</Col>
                  <Col span={8}>Descripition</Col>
                  <Col span={1}>{fields.length === 0 &&  <PlusCircleTwoTone onClick={() => add()} />}</Col>
                </Row>
                {fields?.map((field: any, idx: any) => {
                  let disabled = record?.items?.some((itm:any) => itm.id === form.getFieldValue(["items", field.name, "id"])); 
                  return (
                    <Row className="py-1">
                      <Col span={7}>
                        <Form.Item
                          className="custom-form-item"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 22 }}
                          // label="Key"
                          name={[field.name, "id"]}
                        >
                          <Input
                            className="line-height-28"
                            placeholder="Key"
                            disabled={record?.id && disabled}
                            // onKeyPress={(e) =>  Utils.ValidateCharSpaceAndNumberOnly(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          className="custom-form-item"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 22 }}
                          // label="Text"
                            name={[field.name, "name"]}
                        >
                          <Input
                            className="line-height-28"
                            placeholder="Text"
                            // onKeyPress={(e) =>  Utils.ValidateCharSpaceAndNumberOnly(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        <Form.Item
                          className="custom-form-item"
                          labelCol={{ span: 24 }}
                          wrapperCol={{ span: 22 }}
                          // label="Descripition"
                            name={[field.name, "desc"]}
                        >
                          <Input
                            className="line-height-28"
                            placeholder="Descripition"
                            // onKeyPress={(e) =>  Utils.ValidateCharSpaceAndNumberOnly(e)}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={1} style={{ display: "flex", alignItems: "center"}}>
                        {idx === fields.length - 1 ? (
                          <PlusCircleTwoTone onClick={() => add()} />
                        ) : (
                          record?.id && disabled ? 
                          <DeleteOutlined  style={{ color: 'grey'  }} onClick={() => openNotification("error","Can not delete the existing used keys")} />
                          :
                          <DeleteOutlined  style={{ color: "#d11a2a" }} onClick={() => remove(idx)} />
                        )}
                      </Col>
                    </Row>
                  );
                })}
              </>
            );
          }}
        </Form.List>
      </Form>
    </Modal>
  );
};

export default AddApplicationConfigKey;
