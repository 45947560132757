import { SaveOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Col,
  DatePicker,
  Form,
  Input,
  Modal,
  Row,
  Select,
  SelectProps,
} from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { adminContext } from "../../Common/PageRoute";
import { Utils } from "../../Utilities/Utils";
import dayjs from "dayjs";
import UserService from "../../Services/UserService";

const AddUser = ({ onDismiss, practiceId, userId }: any) => {
  const [form] = useForm();
  const { openNotification, countriesOptions } = React.useContext(adminContext);
  const [loading, setLoading] = React.useState(false);
  const [roleOption, setRoleOptions] = React.useState<SelectProps["options"]>(
    practiceId
      ? [
          { value: "Admin", label: "Admin" },
          { value: "Manager", label: "Manager" },
          { value: "Staff", label: "Staff" },
        ]
      : [
          { value: "SuperAdmin", label: "Super Admin" },
          { value: "SubAdmin", label: "Sub Admin" },
        ]
  );

  return (
    <Modal
      open={true}
      width={700}
      loading={loading}
      title={userId !== undefined ? `Edit user details` : "Add new user"}
      okText={
        <>
          <SaveOutlined /> Save
        </>
      }
      cancelText={
        <>
          <CloseOutlined /> Cancel
        </>
      }
      onCancel={() => onDismiss(false)}
      onOk={(values: any) => {
        setLoading(true);
        let Values = {
          ...form.getFieldsValue(),
          birthDate: dayjs(form.getFieldValue("birthDate")).format(
            "DD/MM/YYYY"
          ),
        };
        UserService.createNewUser(Values)
          .then((res: any) => {
            if (res.status) {
              onDismiss(true);
              openNotification(
                "success",
                `New user ${
                  res?.result?.name?.first + res?.result?.name?.last
                } successfully created.`
              );
            } else {
              openNotification("error",  `${res.errors}`);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }}
    >
      <Form requiredMark={false} form={form}>
        <Row className="ps-1 pt-3 pb-1 font-semibold" justify="space-between">
          Profile Infiormation
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 21 }}
              label="Title"
              name="title"
              rules={[
                { required: true, message: "title is requried" },
                { validator: Utils.validateWhiteSpace },
              ]}
            >
              <Select
                disabled={false}
                className="line-height-28"
                placeholder="Title"
                options={[
                  { value: "Mr", label: "Mr" },
                  { value: "Mrs", label: "Mrs" },
                  { value: "Miss", label: "Miss" },
                  { value: "Sir", label: "Sir" },
                  { value: "Ms", label: "Ms" },
                  { value: "Dr", label: "Dr" },
                  { value: "Prof", label: "Prof" },
                  { value: "Madam", label: "Madam" },
                  { value: "Ma'am", label: "Ma'am" },
                  { value: "Lord", label: "Lord" },
                  { value: "Lady", label: "Lady" },
                  { value: "Reverend", label: "Reverend" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 21 }}
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: "First name is requried" },
                { validator: Utils.validateWhiteSpace },
              ]}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="First Name"
                onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 21 }}
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: "Last name is requried" },
                { validator: Utils.validateWhiteSpace },
              ]}
              // requiredMark={false}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="Last Name"
                onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                onInput={(e: any) =>
                  (e.target.value =
                    e.target.value.length > 1
                      ? e.target.value
                      : e.target.value.toUpperCase())
                }
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Gender"
              name="gender"
              rules={[{ required: true, message: "This field is required" }]}
            >
              <Select
                disabled={false}
                className="line-height-28"
                placeholder="gender"
                options={[
                  { value: 0, label: "Select" },
                  { value: 1, label: "Male" },
                  { value: 2, label: "Female" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Birthdate"
              name="birthDate"
              rules={[
                { required: true, message: "This field is required" },
                { validator: Utils.validateBirthdate },
              ]}
              // getValueProps={((i:any)=>({ value: dayjs(i)}))}
              // getValueFromEvent={(onChange) => dayjs(onChange).format("DD/MM/YYYY")}
            >
              <DatePicker
                disabled={false}
                style={{ width: "100%" }}
                className="line-height-28"
                placeholder="Birthdate"
                format="DD/MM/YYYY"
                //defaultValue={dayjs(userProfile?.birthDate, "DD/MM/YYYY")}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Email"
              name="email"
              rules={[
                { required: true, message: "Email is required" },
                { validator: Utils.ValidateEmailFormat },
              ]}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="Email"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Phone Number"
              name="phoneNumber"
              rules={[
                { required: true, message: "Phone Number is required" },
                {
                  pattern: /^[0-9]{10}$/,
                  message: "Mobile No. must be 10 digits",
                },
              ]}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="Phone Number"
                onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                maxLength={10}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Role"
              name="role"
              rules={[{ required: true, message: "Please select a role" }]}
            >
              <Select
                disabled={false}
                className="line-height-28"
                placeholder="Role"
                options={roleOption}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Roles"
              name="roles"
              rules={[
                { required: true, message: "Phone Number is required" }
              ]}
            >
              <Select
                  mode="multiple"
                  className="line-height-28"
                  disabled={false}
                  placeholder="Roles"
                  options={practiceId ?
                    [
                      {value:"MANAGER", label:"Manager"},
                      {value:"ADMIN", label:"Admin"},
                      {value:"STAFF", label:"Staff"},
                    ] :
                    [
                      {value:"SUPERADMIN", label:"Super Admin"}
                      {value:"SUBADMIN", label:"Sub Admin"}
                    ]
                  }
                />
            </Form.Item> */}
          </Col>
        </Row>
        <Row className="ps-1 pt-3 pb-1  font-semibold" justify="space-between">
          Address Infiormation
        </Row>
        <Row className="px-3 pt-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Building"
              name={["address", "building"]}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="Building"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Street"
              name={["address", "street"]}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="Street"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="City"
              name={["address", "city"]}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="City"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="County"
              name={["address", "county"]}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="County"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Country"
              name={["address", "country"]}
            >
              <Select
                disabled={false}
                className="line-height-28"
                placeholder="Country"
                options={countriesOptions}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 22 }}
              label="Post Code"
              name={["address", "postcode"]}
            >
              <Input
                disabled={false}
                className="line-height-28"
                placeholder="Post Code"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddUser;
