import React from 'react';
import {Route, Routes } from 'react-router-dom';
import Login from './Login/Login';
import ForgotPassword from './Login/ForgotPassword';
import ResetPassword from './Login/ResetPassword';


const UnAuthRoute: React.FC = () => {

    return (
        <Routes>           
            <Route path='/login' element={<Login />} />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='/reset-password/:userId/:code' element={<ResetPassword />} />          
        </Routes>
    )
}
export default UnAuthRoute;