import Axios from 'axios';
import { ApiUtility, IApiResponse } from '../Utilities/ApiUtility.axios';
import { Utils } from '../Utilities/Utils';
import { InMemoryJWT, LocalStorageKeyToken } from '../Utilities/InMemoryJWT';
import { message } from 'antd';

export interface IUserLoginResponse {
  name?: string;
  token: string;
  tokenExpiry: number;
}

export const AuthService = {
    login: async (username: string, password: string): Promise<IUserLoginResponse | null> => {        
    const response = await Axios.post<IApiResponse<IUserLoginResponse>>(
        `/Api/Auth/Login`,
      { username, password } as any,
      ApiUtility._axiosOptions({ withCredentials: true })
    );
        if (response.status === 200) {
            if (response.data && response.data.status) {    
            const { result } = response.data;
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            InMemoryJWT.setToken(result.token, result.tokenExpiry);                           
                Utils.setLocal('ActingOffice.Admin.Token', result);
               // Utils.setSession('tp-user', result);     

             
            return response.data.result;
        }
        //else if (response.data && response.data.message) throw message.error(response.data.message);
    }
        return null;
    },

  loginAs: async (pid: string, id: string) => {
    const response = await Axios.post<IApiResponse<IUserLoginResponse>>(
      '/auth/' + pid + '/loginas/' + id,
      {} as any,
      ApiUtility._axiosOptions({
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        withCredentials: true,
      })
    );
    if (response.status === 200) {
      if (response.data && response.data.status) {
        const { result } = response.data;
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        InMemoryJWT.setToken(result.token, result.tokenExpiry);

        return response.data.result;
      } else if (response.data && response.data.message) throw message.error(response.data.message);

      return null;
    }
  },


  forgotPassword: async (email: string) => {
    const response = await Axios.post<IApiResponse>(
      `/Api/Auth/ForgotPassword`,
      { email },
      ApiUtility._axiosOptions()
      );
    if (response.status === 200) {
        if (response.data && response.data.status) {
            return response.data;
        } else if (response.data && response.data.message) {
            return response.data;
        } 
    }
  },

  resetPassword: async (
    userId: string | undefined,
    password: string,
    confirmpassword: string,
    code: string | undefined
  ) => {
    const response = await Axios.post<IApiResponse>(
        `/Api/Auth/ResetPassword`,
        { userId, password, confirmpassword, code },
      ApiUtility._axiosOptions()
    );
    if (response.status === 200) {
        if (response.data && response.data.status) {
            return response.data;
        } else if (response.data && response.data.message) {
            return response.data;
        }
    }
  }, 
  changePassword: async (
    userId: string | undefined, 
    currentPassword: string, 
    newPassword: string, 
    confirmPassword: string) =>{
      const response = await Axios.post<IApiResponse>(
        `/Api/Auth/ChangePassword/${userId}`,
        { currentPassword, newPassword, confirmPassword },
        ApiUtility._axiosOptions()
      );
      if (response.status === 200) {
        if (response.data && response.data.status) {
            return response.data;
        } else if (response.data && response.data.message) {
            return response.data;
        }
    }
  },
  logout: async () => {  
    // remove user from local storage to log user out
      InMemoryJWT.ereaseToken(true);   
      localStorage.clear();
    return Promise.resolve();
  },  

    getAuthToken: (): string | null =>
        InMemoryJWT.getToken() || Utils.getLocal(LocalStorageKeyToken),

    isAuthenticated: (): boolean | false => AuthService.getAuthToken() !== null,

    userRole(){        
        if (AuthService.getAuthToken() != null)
        {
            let user = Utils.getLocal('ActingOffice.Admin.Token');
            return user.role; 
        }        
    },
 
};

export const Roles = {  

    SuperAdmin: "SuperAdmin",
    SuperAdminC: "SUPERADMIN",
    SubAdmin: "SubAdmin",
    SubAdminC: "SUBADMIN",
    Admin: "Admin",
    AdminC: "ADMIN",
    Manager: "Manager",
    ManagerC: "MANAGER",
    Staff: "Staff",
    StaffC: "STAFF",
    Client: "Client",
    ClientC: "CLIENT",
    CallAgent: "CallAgent",
    CallAgentC: "CALLAGENT",
    CallMonitor: "CallMonitor",
    CallMonitorC: "CALLMONITOR",
    ChatAgent: "ChatAgent",
    ChatAgentC: "CHATAGENT",
    ChatMonitor: "ChatMonitor",
    ChatMonitorC: "CHATMONITOR",
    Editor: "Editor",
    EditorC: "EDITOR",
    AllClients: "AllClients",
    AllClientsC: "ALLCLIENTS",
    SalesAgent: "SalesAgent",
    SalesAgentC: "SALESAGENT",
    FrontEnd: "FrontEnd",
    FrontEndC: "FRONTEND",
    TeamLeader: "TeamLeader",
    TeamLeaderC: "TEAMLEADER",
    FirstResponse: "FirstResponse",
    FirstResponseC: "FIRSTRESPONSE",
    FrontendAccountant: "FrontendAccountant",
    FrontendAccountantC: "FRONTENDACCOUNTANT",
    BackendAccountant: "BackendAccountant",
    BackendAccountantC: "BACKENDACCOUNTANT",
    Reviewer: "Reviewer",
    ReviewerC: "REVIEWER",
    Auditor: "Auditor",
    AuditorC: "AUDITOR"
};
