import {
  CloseOutlined,
  DeleteOutlined,
  EditTwoTone,
  LoadingOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Row,
  Select,
  Spin,
} from "antd";
import React from "react";
import CTConfiguration, {
  CapitalAllowanceAssetCategories,
} from "../../Services/CTConfigurationService";
import { useForm } from "antd/es/form/Form";
import dayjs from "dayjs";
import { adminContext } from "../../Common/PageRoute";
import FImage from "../../images/FImage(2).svg";
import { Utils } from "../../Utilities/Utils";
// import deleteIcon from "../../images/deleteIcon.svg";

const CapitalAllowance = () => {
  const { openNotification } = React.useContext(adminContext);
  //const navigate = useNavigate();
  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [CapitalAllowanceList, setCapitalAllowanceList] = React.useState<any>(
    []
  );
  const [CAACategory, setCAACategory] = React.useState<{
    type: CapitalAllowanceAssetCategories;
    name: string;
  }>({
    type: CapitalAllowanceAssetCategories.MachineryAndPlantSpecial,
    name: "Machinery and plant (Special Rate Pool)",
  });
  const OnCancelClick = () => {
    setIsEdit(false);
    setFormValues(CapitalAllowanceList);
  };
  const OnSaveClick = async () => {
    let values = form.getFieldValue("CategoryTypeValues");
    let res = await CTConfiguration.postCapitalAllowances(
      CAACategory.type,
      values
    );
    if (res.status) {
      setIsEdit(false);
      setCapitalAllowanceList(res.result);
      setFormValues(res.result);
      openNotification("success", "Details updated successfully");
    } else {
      openNotification("error", res.message);
      setLoading(false);
    }
  };
  const setFormValues = (list: any) => {
    form.setFieldsValue({
      CategoryTypeValues: list.map((item: any) => ({
        name: item.name,
        fromDate: item.fromDate ? dayjs(item.fromDate) : undefined,
        toDate: item.toDate ? dayjs(item.toDate) : undefined,
        rate: item.rate,
        isFYA100: item.isFYA100,
        isEnhancedCA: item.isEnhancedCA,
      })),
    });
  };
  React.useEffect(() => {
    setIsEdit(false);
    setLoading(true);
    CTConfiguration.getCapitalAllowances(CAACategory.type).then((res) => {
      if (res.status) {
        setCapitalAllowanceList(res.result);
        setFormValues(res.result);
        setLoading(false);
      } else {
        openNotification("error", res.message);
        setLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [CAACategory.type]);

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Form form={form}>
        <Form.List name="CategoryTypeValues">
          {(fields, { add, remove }, { errors }) => (
            <Row>
              <Col span={24} className="card pb-3 border-rounded-1 ">
                <Row
                  className="border-bottom-2px  p-2 font-semibold"
                  justify="space-between"
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Form.Item className="custom-form-item">
                    <Button
                      type="primary"
                      onClick={() => {
                        if (isEdit) add();
                        else
                          openNotification(
                            "warning",
                            "Please enable edit mode to add items"
                          );
                      }}
                    >
                      <PlusOutlined />
                      Add Item
                    </Button>
                  </Form.Item>
                  <div style={{ minWidth: 320 }}>
                    <span style={{ paddingRight: 10 }}>
                      Capital Allowance Asset Categories :
                    </span>
                    <Select
                      style={{ minWidth: 300, marginRight: 10 }}
                      // className="line-height-28"
                      value={CAACategory.type}
                      options={[
                        {
                          value: 1,
                          label: "Machinery and plant (Special Rate Pool)",
                        },
                        { value: 2, label: "Machinery and plant (Main Pool)" },
                        { value: 3, label: "Other allowances and charges" },
                        { value: 4, label: "Structures and buildings" },
                        {
                          value: 5,
                          label: "Business premises renovation allowance",
                        },
                        { value: 6, label: "Electric charge-points" },
                        { value: 7, label: "Enterprise zones" },
                        { value: 8, label: "Zero emissions goods vehicles" },
                        { value: 9, label: "Zero emissions cars" },
                        { value: 10, label: "Patents and know how" },
                      ]}
                      onChange={(val: any, item: any) => {
                        setLoading(true);
                        setCAACategory({ type: val, name: item?.label });
                      }}
                    />
                    <span className="edit-btn" onClick={() => setIsEdit(true)}>
                      <EditTwoTone />
                    </span>
                  </div>
                </Row>
                {fields.length === 0 && (
                  <Row
                    style={{ height: "500px" }}
                    align="middle"
                    justify="center"
                  >
                    <Image preview={false} src={FImage} alt="No Items" />
                  </Row>
                )}
                <>
                  {fields.map((field: any, index: any) => (
                    <Col
                      span={24}
                      className="border border-rounded-1 pb-2 mt-3"
                    >
                      <Row
                        className="px-3 py-1 font-bold border-bottom"
                        justify="space-between"
                      >
                        <div>
                          {CAACategory?.name} : Item {index + 1}
                        </div>
                        <div>
                          {CapitalAllowanceList?.length <= index && (
                            <span
                              className="delete-btn"
                              onClick={() => remove(field.name)}
                            >
                              <DeleteOutlined
                                style={{ color: "red", fontSize: 18 }}
                              />
                            </span>
                          )}
                        </div>
                      </Row>
                      <Row className="px-3 pt-1">
                        <Col span={8}>
                          <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 14 }}
                            label="Name"
                            name={[field.name, "name"]}
                          >
                            <Input
                              placeholder="Name"
                              onKeyPress={(e) =>  Utils.ValidateCharSpaceAndNumberOnly(e)}
                              disabled={isEdit ? false : true}
                              className="line-height-28"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 12 }}
                            label="From Date"
                            name={[field.name, "fromDate"]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="From Date"
                              format='DD/MM/YYYY'
                              disabled={isEdit ? false : true}
                              className="line-height-28"
                            />
                          </Form.Item>
                        </Col>
                        <Col span={8}>
                          <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 12 }}
                            label="To Date"
                            name={[field.name, "toDate"]}
                          >
                            <DatePicker
                              style={{ width: "100%" }}
                              placeholder="To Date"
                              format='DD/MM/YYYY'
                              disabled={isEdit ? false : true}
                              className="line-height-28"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row className="px-3 pt-1">
                        <Col span={8}>
                          <Form.Item
                            className="custom-form-item"
                            labelCol={{ span: 24 }}
                            wrapperCol={{ span: 14 }}
                            label="Rate"
                            name={[field.name, "rate"]}
                          >
                            <InputNumber
                              controls={false}
                              placeholder="Rate"
                              className="line-height-28"
                              disabled={isEdit ? false : true}
                              onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                              prefix="%"
                              style={{ width: "100%" }}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          span={8}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Form.Item
                            className="custom-form-item"
                            valuePropName="checked"
                            name={[field.name, "isFYA100"]}
                          >
                            <Checkbox
                              disabled={isEdit ? false : true}
                              className="line-height-28"
                            >
                              First year allowance at 100%
                            </Checkbox>
                          </Form.Item>
                        </Col>
                        <Col
                          span={8}
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Form.Item
                            className="custom-form-item"
                            valuePropName="checked"
                            name={[field.name, "isEnhancedCA"]}
                          >
                            <Checkbox
                              disabled={isEdit ? false : true}
                              className="line-height-28"
                            >
                              100% Allowance (Enhanced CAs)
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                  ))}
                </>
                {isEdit && (
                  <Row className="px-3 pt-3">
                    <Button
                      loading={loading}
                      type="primary"
                      onClick={OnSaveClick}
                    >
                      <SaveOutlined /> Save
                    </Button>
                    <Button
                      loading={loading}
                      className="ms-2"
                      onClick={OnCancelClick}
                    >
                      <CloseOutlined /> Cancel
                    </Button>
                  </Row>
                )}
              </Col>
            </Row>
          )}
        </Form.List>
      </Form>
    </Spin>
  );
};

export default CapitalAllowance;
