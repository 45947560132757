import { EditTwoTone, PlusOutlined, LoadingOutlined } from "@ant-design/icons";
import {
  Table,
  Row,
  Col,
  Button,
  Spin,
  Select,
  SelectProps,
} from "antd";
import React from "react";
import CTConfiguration from "../../Services/CTConfigurationService";
import Link from "antd/es/typography/Link";
import AddTaxRates from "./AddTaxRates";
import CommonService from "../../Services/CommonService";

const TaxRates = () => {
  //const currYear = dayjs().year();
  const [loading, setLoading] = React.useState(false);
  const [dailog, setDialog] = React.useState<any>({ type: "", params: {} });
  const [selYear, setSelYear] = React.useState<any>(0);

  const [taxRatesList, setTaxRatesList] = React.useState([]);
  const [selYearOptions, setSelYearOptions] =React.useState<SelectProps["options"]>([]);
  const [addedYears, setAddedYears] =React.useState([]);
  const [refresh, setRefresh] = React.useState(false);
  // const OnSaveClick = () => {};

  const onChangeDatePicker = (year:any) => {
    setSelYear(year);
  };

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "3%",
      render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
    },
    {
      title: "Year",
      dataIndex: "year",
      key: "Year",
      width: "10%",
    },
    {
      title: "Main Rate",
      dataIndex: "mainRate",
      key: "Main Rate",
      width: "10%",
    },
    {
      title: "Special Rate",
      dataIndex: "specialRate",
      key: "Specail Rate",
      width: "10%",
    },
    {
      title: "Small Profit Rate",
      dataIndex: "smallProfitRate",
      key: "Small Profit Rate",
      width: "10%",
    },
    {
      title: "MR Lower Limit",
      dataIndex: "mrLowerLimit",
      key: "",
      width: "10%",
    },
    {
      title: "MR Upper Limit",
      dataIndex: "mrUpperLimit",
      key: "",
      width: "10%",
    },
    {
      title: "Ring Fance Rate",
      dataIndex: "ringFanceRate",
      key: "",
      width: "10%",
    },
    {
      title: "Standard Fraction Low",
      dataIndex: "standardFractionLow",
      key: "",
      width: "12%",
    },
    {
      title: "Standard Fraction Up",
      dataIndex: "standardFractionUp",
      key: "",
      width: "12%",
    },
    {
      title: "Action",
      dataIndex: "mRLowerLimit",
      key: "",
      width: "5%",
      render: (_: any, record: any, index: any) => (
        <Link
          className="edit-btn"
          onClick={() => {
            setDialog({ type: "addtaxrates", params: { ...record } });
          }}
        >
          <EditTwoTone />
        </Link>
      ),
    },
  ];

  React.useEffect(() => {
    setLoading(true);
    const TaxRates = async () => {
      let rates = await CTConfiguration.getTaxRates(selYear);
      if (rates.status) {
        setTaxRatesList(rates.result);
        setLoading(false);
      } else{
        setLoading(false);
      }
    };
    TaxRates();
  }, [selYear, refresh]);

  React.useEffect(() => {
    setLoading(true);
    const getTaxRatesAddedYears = async () => {
      let res = await CommonService.getTaxRatesAddedYear();
      const optn: SelectProps["options"] = [{value:0, label:"All"}];
      if (res.result) {
        setAddedYears(res.result);
        res.result?.map((item: any) => {
          optn.push({
            value: item,
            label: item
          });
          return item;
        });
        setSelYearOptions(optn);
        setLoading(false);
      }
    };
    getTaxRatesAddedYears();
  }, []);
  return (
    <>
      <Row>
        <Col
          span={24}
          className="border-bottom p-2 font-bold"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            type="primary"
            onClick={() => {
              setDialog({ type: "addtaxrates", params: {} });
            }}
          >
            <PlusOutlined />
            Add TaxRates
          </Button>
          <span>
              <Select style={{width:120}} options={selYearOptions} className="line-height-28" placeholder="Select Year" onChange={onChangeDatePicker} />
          </span>
        </Col>
        <Col span={24}>
          <Table
            dataSource={taxRatesList?.length > 0 ? taxRatesList: []}
            columns={columns}
            size="small"
            className="aoa-custom-table"
            pagination={{
              defaultPageSize:15,
            }}
            loading={{
              spinning: loading,
              indicator: (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
              ),
            }}
          />
        </Col>
        {dailog?.type === "addtaxrates" && (
          <AddTaxRates
            addedYears={addedYears}
            id={dailog.params?.year}
            record={dailog.params}
            onDismiss={(conformation: any) => {
              setDialog({ type: "", params: {} });
              if(conformation) setRefresh((x)=> x= !x);
            }}
          />
        )}
      </Row>
    </>
  );
};

export default TaxRates;
