import {
  CloseOutlined,
  EditTwoTone,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import UserService from "../../Services/UserService";
import {
  Breadcrumb,
  Col,
  Row,
  Spin,
  Image,
  Typography,
  Form,
  Input,
  Select,
  SelectProps,
  Button,
  DatePicker,
} from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./Profile.css";
import { useUser } from "../../Context/UserContext";
import { adminContext } from "../../Common/PageRoute";
import { useForm } from "antd/es/form/Form";
import { Utils } from "../../Utilities/Utils";
import dayjs from "dayjs";

interface ILocalUser {
  email: string;
  id: string;
  name: string;
  role: string;
  roles: string[];
  token: string;
  tokenExpiry: number;
  userName: string;
}

const Profile = () => {
  const { getUser, setCurrentUser } = useUser();
  const navigate = useNavigate();
  const { openNotification, countriesOptions } = React.useContext(adminContext);
  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(true);
  const [imagePath, setImagePath] = React.useState("");
  const [userProfile, setUserProfile] = React.useState<any>();
  let Id: any = getUser()?.id;

  const SetFieldValues = (user: any) => {
    form.setFieldsValue({
      id: user?.id,
      address: user?.address,
      birthDate: user?.birthDate
        ? dayjs(user?.birthDate, "DD/MM/YYYY")
        : undefined,
      email: user?.email,
      fullName: user?.fullName,
      gender: user?.gender,
      name: user?.name,
      phoneNumber: user?.phoneNumber,
      // picture: {
      //   imagePath: user?.picture?.imagePath,
      //   thumbPath: user?.picture?.thumbPath,
      // },
      userName: user?.userName,
    });
  };
  const OnSave = (values: any) => {
    setLoading(true);
    let Values = {
      ...values,
      birthDateStr: dayjs(values?.birthDate).format("DD/MM/YYYY"),
    };
    UserService.updateUserDetails(Id, Values).then((res) => {
      if (res?.status) {
        setUserProfile(res.result);
        SetFieldValues(res.result);
        let user = Utils.getLocal("ActingOffice.Admin.Token");
        if (user !== null) {
          let newUser: ILocalUser = {
            email: res.result?.email,
            id: user?.id,
            name: res.result?.fullName,
            role: user?.role,
            roles: [...user?.roles],
            token: user?.token,
            tokenExpiry: user?.tokenExpiry,
            userName: user?.userName,
          };
          Utils.setLocal("ActingOffice.Admin.Token", newUser);
          setCurrentUser({
            ...res.result,
            name: `${
              res.result?.name?.alias !== null ? res.result?.name?.alias : ""
            } 
             ${res.result?.name?.first !== null ? res.result?.name?.first : ""} 
             ${res.result?.name?.last !== null ? res.result?.name?.last : ""} 
             ${
               res.result?.name?.title !== null ? res.result?.name?.title : ""
             }`,
          });
        }
        setIsEdit(true);
        setLoading(false);
        openNotification("success", "Profile updated successfully.");
      } else {
        openNotification("error", res.message);
        setLoading(false);
      }
    });
  }; 
  React.useEffect(() => {
    if (Id !== undefined || Id !== null || Id !== "") {
      setLoading(true);
      UserService.getUserDetails(Id).then((user: any) => {
        if (user?.status) {
          setUserProfile(user?.result);
          setImagePath(user?.result?.picture?.imagePath);
          SetFieldValues(user?.result);
          setLoading(false);
        } else {
          openNotification("error", user?.message);
          setLoading(false);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [Id]);
  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
    >
      <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
        <Breadcrumb style={{ color: "#172B4D" }}>
          <Breadcrumb.Item onClick={() => navigate("/profile")}>
            Profile
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>
      <Form form={form} requiredMark={false} onFinish={OnSave}>
        <Row
          className="roundedCornerSmall p-2"
          // style={{ backgroundColor: "white" }}
        >
          <Row style={{ width: "100%", minHeight: 750 }} justify="space-around">
            <Col span={6} className="bg-white profile-img-col">
              {imagePath !== "" ? (
                <Image
                  wrapperStyle={{ height: "200px", width: "200px" }}
                  className="rounded profile-img"
                  src={imagePath}
                  alt="Profile image"
                />
              ) : (
                <Image
                  wrapperStyle={{ height: "200px", width: "200px" }}
                  className="rounded profile-img"
                  src=""
                  alt="Profile image"
                />
              )}
              <Row justify="center">
                <Typography.Title
                  level={5}
                  className="mb-2 pt-2"
                  style={{ margin: 0 }}
                >
                  {userProfile?.fullName}
                </Typography.Title>
              </Row>
              <Row justify="center" className="mb-2">
                {userProfile?.userName}
              </Row>
            </Col>
            <Col
              span={17}
              className="bg-white detail-col"
              style={{ position: "relative" }}
            >
              <Row
                className="border-bottom py-2 ps-4 pe-2 font-bold"
                justify="space-between"
              >
                Profile Infiormation
                <span
                  className="edit-btn"
                  onClick={() => {
                    setLoading(false);
                    setIsEdit(false);
                  }}
                >
                  <EditTwoTone />
                </span>
              </Row>
              <Row className="px-4 pt-3">
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="First Name"
                    name={["name", "first"]}
                    rules={[{ required: true, message: "First name is requried" }, { validator: Utils.validateWhiteSpace }]}
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="First Name"
                      onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                      onInput={(e: any) => e.target.value = e.target.value.length > 1 ? e.target.value : e.target.value.toUpperCase()}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Last Name"
                    name={["name", "last"]}
                    rules={[{ required: true, message: "Last name is requried"}, { validator: Utils.validateWhiteSpace }]}
                    // requiredMark={false} 
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Last Name"
                      onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                      onInput={(e: any) => e.target.value = e.target.value.length > 1 ? e.target.value : e.target.value.toUpperCase()}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="px-4 pt-1">
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Gender"
                    name="gender"
                    rules={[{ required: true, message: "This field is required" }]}
                  >
                    <Select
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="gender"
                      options={[
                        { value: 0, label: "Select" },
                        { value: 1, label: "Male" },
                        { value: 2, label: "Female" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Birthdate"
                    name="birthDate"
                    rules={[{ required: true, message: "This field is required" }, { validator: Utils.validateBirthdate }]}
                    // getValueProps={((i:any)=>({ value: dayjs(i)}))}
                    // getValueFromEvent={(onChange) => dayjs(onChange).format("DD/MM/YYYY")}
                  >
                    <DatePicker
                      disabled={isEdit}
                      style={{ width: "100%" }}
                      className="line-height-28"
                      placeholder="Birthdate"
                      format="DD/MM/YYYY"
                      //defaultValue={dayjs(userProfile?.birthDate, "DD/MM/YYYY")}
                    />
                    {/* <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Birthdate"
                    /> */}
                  </Form.Item>
                </Col>
              </Row>
              <Row className="px-4 pt-1">
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "This field is required" }, { validator : Utils.ValidateEmailFormat }]}
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Email"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Phone Number"
                    name="phoneNumber"
                    rules={[{ required: true, message: "This field is required" }, { pattern: /^[0-9]{10}$/, message: "Mobile No. must be 10 digits" }]}
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Phone Number"
                      onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                      maxLength={10}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="font-bold ps-4 py-3">Address Information</Row>
              <Row className="px-4 ">
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Building"
                    name={["address", "building"]}
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Building"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Street"
                    name={["address", "street"]}
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Street"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="px-4 pt-1">
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="City"
                    name={["address", "city"]}
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="City"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="County"
                    name={["address", "county"]}
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="County"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className="px-4 pt-1">
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Country"
                    name={["address", "country"]}
                  >
                    <Select
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Country"
                      options={countriesOptions}
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 12 }}
                    label="Post Code"
                    name={["address", "postcode"]}
                  >
                    <Input
                      disabled={isEdit}
                      className="line-height-28"
                      placeholder="Post Code"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {!isEdit && (
                <Row
                  className="pe-5 pb-4 mb-0"
                  style={{ position: "absolute", bottom: 0, right: 0 }}
                  justify={"end"}
                >
                  <Button
                    className=""
                    onClick={() => {
                      setIsEdit(true);
                      SetFieldValues(userProfile);
                    }}
                  >
                    <CloseOutlined /> Cancel
                  </Button>
                  <Button type="primary" htmlType="submit" className="ms-2">
                    <SaveOutlined /> Save
                  </Button>
                </Row>
              )}
            </Col>
          </Row>
        </Row>
      </Form>
    </Spin>
  );
};

export default Profile;
