import { LoadingOutlined } from "@ant-design/icons";
import { Row, Spin } from "antd";

interface ILoadingStackProps {
  label?: string;
}

export const FullPageSpinner = ({
  label = "Loading...",
}: ILoadingStackProps) => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      ></Spin>
    </Row>
  );
};

export const LoadingStack = ({ label = "Loading..." }: ILoadingStackProps) => {
  return (
    <Row justify="center" align="middle" style={{ minHeight: "100vh" }}>
      <Spin
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      ></Spin>
    </Row>
  );
};
