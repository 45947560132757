import React from "react";
import PracticesService, {
  IPractice,
  PracticeUpdateTypes,
} from "../../Services/PracticesService";
import { useNavigate, useParams } from "react-router-dom";
import {
  Breadcrumb,
  Col,
  Row,
  Spin,
  Image,
  Tabs,
  TabsProps,
  Input,
  Select,
  Button,
  Radio,
  Form,
  SelectProps,
  InputNumber,
} from "antd";
import {
  CloseOutlined,
  EditTwoTone,
  LoadingOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import "./Practices.css";
import Checkbox from "antd/es/checkbox/Checkbox";
import { useForm } from "antd/es/form/Form";
import { adminContext } from "../../Common/PageRoute";
import { getTimeZonesOptions } from "../../Common/CommonComponent";
import NoImageToShow from "../../images/no_Image_to_show.png";
import { Utils } from "../../Utilities/Utils";

const defaultData = {
  address: {
    building: "",
    city: "",
    country: "",
    county: "",
    postcode: "",
    street: "",
  },
  params: {
    dueInvoicesTaskDays: 0,
    pageTrackTimeout: 0,
    taskDueDateInDays: 0,
  },
  appFeature: {
    accounts: false,
    aml: false,
    bankStatement: false,
    bookkeeping: false,
    calls: false,
    chats: false,
    clientDataRequest: false,
    corporationTax: false,
    dayEnd: false,
    leadSources: false,
    linkedinLeads: false,
    payroll: false,
    teams: false,
    webForms: false,
    timesheet: false,
    careOf: false,
    selfAssessment: false,
    dms: false,
    other4: false,
    other5: false,
  },
  appSubFeature: {
    actionTab: false,
    addCustomerWithoutLead: false,
    digitalSignatureWithoutAML: false,
    manualTimesheet: false,
    progressiveLeads: false,
    soaIsEnable: false,
    soaIsNotificationEnable: false,
    websiteQueriesWithOTP: false,
    witoutDiscount: false,
    clientAutomation: false,
    proofOfEarningFPS: false,
    receiptAttachments: false,
    skipTeamUpdate: false,
    secureLink: false,
    bankDetails: false,
    amLforAllOfficers: false,
    firstResponse: false,
    accountManagar: false,
    accountant: false,
    mode: false,
    agentCredentialsRemark: false,
    clientBrand: false,
    clientProposition: false,
    S455TaxLiability: false,
    mtsAutomationSettings: {
      automate: false,
      days: 0,
    },
    soaNotificationSettings: {
      day: 1,
      frequency: 3,
      amount: 100,
      notifyOnLastDay: false,
    },
    noReply: {
      enabled: false,
      replyEmail: "",
    },
    reviewRequestConfig: {
      APCT: false,
      SA100: false,
      SA800: false,
      VAT: false,
    },
  },
  bankAccount: {
    accountName: "",
    accountNumber: "",
    bankName: "",
    bik: "",
    iban: "",
    shortCode: "",
  },
  website: {
    iconUrl: "",
    logoUrl: "",
    url: "",
    urlPrivacy: "",
    urlTnC: "",
  },
  name: "",
  email: "",
  phoneNumber: 0,
  timeZone: "",
  vat: {
    enabled: false,
    rate: 0,
  },
  emailProvider: 0,
  defaultBCCs: [],
  defaultCCs: [],
  sequences: {
    invoice: {
      leadingZero: 0,
      postfix: "",
      prefix: "",
      seperator: "",
      type: 0,
    },
    quote: {
      leadingZero: 0,
      postfix: "",
      prefix: "",
      seperator: "",
      type: 0,
    },
  },
};

const PracticeDetails = ({ data }: any) => {
  const { openNotification, setPracticeData } = React.useContext(adminContext);
  const navigate = useNavigate();
  const [form] = useForm();
  const { id } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [practicesData, setPracticesData] =
    React.useState<IPractice>(defaultData);
  const [isEdit, setIsEdit] = React.useState<{ type: PracticeUpdateTypes }>({
    type: PracticeUpdateTypes.none,
  });

  const onChange = (key: string) => {};
  const OnCancelClick = () => {
    setIsEdit({ type: PracticeUpdateTypes.none });
    SetFormValues(practicesData);
  };
  const OnSaveClick = async () => {
    setLoading(true);
    var type = Number(isEdit.type);
    var Values = await form
      .validateFields()
      .then((values) => {
        return values;
      })
      .catch((error) => {
        openNotification("error", "Valdate Fields");
      });
    let res = await PracticesService.updatePracticeDetails(id, type, Values);
    if (res.status) {
      setIsEdit({ type: PracticeUpdateTypes.none });
      setPracticesData(res?.result);
      setLoading(false);
    } else if (res.errors) {
      setLoading(false);
      openNotification("error", res?.errors);
    }
  };
  const handleValuesChange = async (changedValues: any, allValues: any) => {
    var vals = await form.validateFields().then((values) => {
      return values;
    });
    setPracticesData(vals);
  };
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Basic Information",
      children: (
        <>
          <BasicInformation
            form={form}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            OnSaveClick={OnSaveClick}
            OnCancelClick={OnCancelClick}
            handleValuesChange={handleValuesChange}
          />
          <BankAccount
            form={form}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            OnSaveClick={OnSaveClick}
            OnCancelClick={OnCancelClick}
            handleValuesChange={handleValuesChange}
          />
        </>
      ),
    },
    {
      key: "2",
      label: "TimeZone, VAT & Email",
      children: (
        <TimeZoneVATAndEmail
          form={form}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          OnSaveClick={OnSaveClick}
          OnCancelClick={OnCancelClick}
          handleValuesChange={handleValuesChange}
        />
      ),
    },
    {
      key: "3",
      label: "Sequence & Review",
      children: (
        <>
          <Pratices
            form={form}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            OnSaveClick={OnSaveClick}
            OnCancelClick={OnCancelClick}
            handleValuesChange={handleValuesChange}
          />
          <ReviewRequest
            form={form}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            OnSaveClick={OnSaveClick}
            OnCancelClick={OnCancelClick}
            handleValuesChange={handleValuesChange}
          />
        </>
      ),
    },
    {
      key: "4",
      label: "Features",
      children: (
        <Features
          form={form}
          isEdit={isEdit}
          setIsEdit={setIsEdit}
          OnSaveClick={OnSaveClick}
          OnCancelClick={OnCancelClick}
          handleValuesChange={handleValuesChange}
        />
      ),
    },
  ];
  const SetFormValues = (currValues: any) => {
    form.setFieldsValue({
      address: currValues?.address,
      params: currValues?.params,
      appFeature: currValues?.appFeature,
      appSubFeature: {
        ...currValues?.appSubFeature,
        mtsAutomationSettings: currValues?.appSubFeature?.mtsAutomationSettings,
        soaNotificationSettings:
          currValues?.appSubFeature?.soaNotificationSettings,
        noReply: currValues?.appSubFeature?.noReply,
        reviewRequestConfig: currValues?.appSubFeature?.reviewRequestConfig,
      },
      bankAccount: currValues?.bankAccount,
      website: currValues?.website,
      name: currValues?.name,
      email: currValues?.email,
      phoneNumber: currValues?.phoneNumber,
      timeZone: currValues?.timeZone,
      vat: currValues?.vat,
      emailProvider: currValues?.emailProvider,
      defaultBCCs: currValues?.defaultBCCs,
      defaultCCs: currValues?.defaultCCs,
      sequences: {
        invoice: currValues?.sequences?.invoice,
        quote: currValues?.sequences?.quote,
      },
    });
  };
  React.useEffect(() => {
    const Id = id;
    setLoading(true);
    const getpracticesData = async () => {
      let res: any;
      if (data !== undefined && Id === data?.result?.id?.toString()) {
        res = data;
      } else {
        setPracticeData(undefined);
        res = await PracticesService.getPracticeDetails(Id);
      }
      if (res.status) {
        setPracticesData(res.result);
        SetFormValues(res.result);
      }
      setLoading(false);
    };
    getpracticesData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
    >
      <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
        <Breadcrumb style={{ color: "#172B4D" }}>
          <Breadcrumb.Item onClick={() => navigate("/practices")}>
            Practices
          </Breadcrumb.Item>
          <Breadcrumb.Item> {practicesData?.name}</Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row
        className="roundedCornerSmall p-1"
        style={{ backgroundColor: "white", padding: "10px 20px " }}
      >
        <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
          <Row>
            <Col
              span={23}
              style={{ display: "flex", alignItems: "center", padding: 10 }}
            >
              <Image
                className="rounded"
                style={{ height: "44px", width: "auto" }}
                src={
                  practicesData?.website?.logoUrl !== undefined &&
                  practicesData?.website?.logoUrl !== "" &&
                  practicesData?.website?.logoUrl !== null
                    ? `${practicesData?.website?.logoUrl}`
                    : NoImageToShow
                }
                alt="Company logo"
              />
              <Col style={{ paddingLeft: "20px" }}>
                <Col id="practicetname" style={{ fontSize: 25 }}>
                  {practicesData?.name}
                </Col>
                <a href={`mailto: ${practicesData?.email}`}>
                  {practicesData?.email}
                </a>
              </Col>
            </Col>
            <Col span={23}>
              <Tabs
                defaultActiveKey="1"
                size="small"
                items={items}
                onChange={onChange}
              />
            </Col>
          </Row>
          {/* <Row className="h100" justify="center" align="middle">
            <Image src={notFound} preview={false} />
          </Row> */}
        </Col>
      </Row>
    </Spin>
  );
};

export default PracticeDetails;

export const BasicInformation = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
}: any) => {
  const { countriesOptions } = React.useContext(adminContext);

  return (
    <>
      <Form
        requiredMark={false}
        form={form}
        onValuesChange={handleValuesChange}
      >
        <Col className="card border pb-3 border-rounded-1 bg-secondary">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Basic Information
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({ type: PracticeUpdateTypes.basicinformation })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Name"
                name="name"
                rules={[
                  { required: true, message: "Name is requried" },
                  { validator: Utils.validateWhiteSpace },
                ]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.basicinformation
                      ? false
                      : true
                  }
                  onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                  onInput={(e: any) =>
                    (e.target.value =
                      e.target.value.length > 1
                        ? e.target.value
                        : e.target.value.toUpperCase())
                  }
                  className="line-height-28"
                  placeholder="Name"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Email"
                name="email"
                rules={[
                  { required: true, message: "This field is required" },
                  { validator: Utils.ValidateEmailFormat },
                ]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.basicinformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Email"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 6 }}
                label="Phone Number"
                name="phoneNumber"
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.basicinformation
                      ? false
                      : true
                  }
                  onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                  maxLength={11}
                  className="line-height-28"
                  placeholder="Phone Number"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Url"
                name={["website", "url"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.basicinformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Url"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Url T&C"
                name={["website", "urlTnC"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.basicinformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Url T&C"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Url Privacy"
                name={["website", "urlPrivacy"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.basicinformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Url Privacy"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Icon Url"
                name={["website", "iconUrl"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.basicinformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Icon Url"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Logo Url"
                name={["website", "logoUrl"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.basicinformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Logo Url"
                />
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PracticeUpdateTypes.basicinformation && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
        <Col className="card border pb-3 border-rounded-1 bg-secondary my-4">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Address Details
            <span
              className="edit-btn"
              onClick={() => setIsEdit({ type: PracticeUpdateTypes.address })}
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Building"
                name={["address", "building"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.address ? false : true
                  }
                  className="line-height-28"
                  placeholder="Building"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Street"
                name={["address", "street"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.address ? false : true
                  }
                  className="line-height-28"
                  placeholder="Street"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="City / Town"
                name={["address", "city"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.address ? false : true
                  }
                  className="line-height-28"
                  placeholder="City / Town"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 ">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="County"
                name={["address", "county"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.address ? false : true
                  }
                  onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                  className="line-height-28"
                  placeholder="County"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Post Code"
                name={["address", "postcode"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.address ? false : true
                  }
                  onKeyPress={(e: any) => Utils.ValidateCharAndNumberOnly(e)}
                  className="line-height-28"
                  placeholder="Post Code"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
                label="Country"
                name={["address", "country"]}
              >
                <Select
                  disabled={
                    isEdit.type === PracticeUpdateTypes.address ? false : true
                  }
                  className="line-height-28"
                  placeholder="Country"
                  options={countriesOptions}
                />
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PracticeUpdateTypes.address && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </>
  );
};
export const BankAccount = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
}: any) => {
  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Col className="card border pb-3 border-rounded-1 bg-secondary">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Bank Account
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({ type: PracticeUpdateTypes.bankaccountdetails })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Account Name"
                name={["bankAccount", "accountName"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.bankaccountdetails
                      ? false
                      : true
                  }
                  onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                  className="line-height-28"
                  placeholder="Account Name"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Bank Name"
                name={["bankAccount", "bankName"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.bankaccountdetails
                      ? false
                      : true
                  }
                  onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}
                  className="line-height-28"
                  placeholder="Bank Name"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
                label="Sort Code"
                name={["bankAccount", "shortCode"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.bankaccountdetails
                      ? false
                      : true
                  }
                  onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                  className="line-height-28"
                  placeholder="Sort Code"
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="Account Number"
                name={["bankAccount", "accountNumber"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.bankaccountdetails
                      ? false
                      : true
                  }
                  onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                  maxLength={8}
                  className="line-height-28"
                  placeholder="Accouint Number"
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="IBAN"
                name={["bankAccount", "iban"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.bankaccountdetails
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="IBAN"
                />
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PracticeUpdateTypes.bankaccountdetails && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </>
  );
};

const TimeZoneVATAndEmail = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
}: any) => {
  const BCCoptions: SelectProps["options"] = [];
  const CCoptions: SelectProps["options"] = [];
  // const timeZoneOptions: SelectProps['options'] = [];
  var bCCs = form.getFieldValue("defaultBCCs");
  var CCs = form.getFieldValue("defaultCCs");

  bCCs?.forEach((item: any) => {
    BCCoptions.push({
      label: item,
      value: item,
    });
  });
  CCs?.forEach((item: any) => {
    CCoptions.push({
      label: item,
      value: item,
    });
  });

  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Col className="card border pb-3 border-rounded-1 bg-secondary">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            TimeZone, VAT
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({ type: PracticeUpdateTypes.timezonevat })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 10 }}
                label="TimeZone"
                name="timeZone"
              >
                <Select
                  disabled={
                    isEdit.type === PracticeUpdateTypes.timezonevat
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="TimeZone"
                  options={getTimeZonesOptions()}
                />
              </Form.Item>
            </Col>
            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                className="custom-form-item"
                valuePropName="checked"
                name={["vat", "enabled"]}
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.timezonevat
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  VAT Paye
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 8 }}
                label="VAT Rate"
                name={["vat", "rate"]}
              >
                <Input
                  disabled={
                    isEdit.type === PracticeUpdateTypes.timezonevat
                      ? false
                      : true
                  }
                  onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                  className="line-height-28"
                  placeholder="VAT Rate"
                />
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PracticeUpdateTypes.timezonevat && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
        <Col className="card border pb-3 border-rounded-1 bg-secondary my-4">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Emails
            <span
              className="edit-btn"
              onClick={() => setIsEdit({ type: PracticeUpdateTypes.emails })}
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} style={{ display: "flex", alignItems: "center" }}>
              <Form.Item
                style={{ width: "100%" }}
                className="custom-form-item"
                name="emailProvider"
              >
                <Radio.Group
                  style={{ display: "flex", justifyContent: "space-evenly" }}
                  disabled={
                    isEdit.type === PracticeUpdateTypes.emails ? false : true
                  }
                >
                  <Radio
                    value={0}
                    className="border  border-rounded-1 mail-radio-btn"
                  >
                    <span className="mail-radio-btn-span">
                      <img
                        style={{ height: 20, width: 20 }}
                        alt="email"
                        src="https://cdn-icons-png.flaticon.com/512/5968/5968534.png"
                      />
                      <div>Gmail</div>
                    </span>
                  </Radio>
                  <Radio
                    value={1}
                    className="border  border-rounded-1 mail-radio-btn"
                  >
                    <span className="mail-radio-btn-span">
                      <img
                        style={{ height: 20, width: 20 }}
                        alt="email"
                        src="https://upload.wikimedia.org/wikipedia/commons/thumb/d/df/Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg/800px-Microsoft_Office_Outlook_%282018%E2%80%93present%29.svg.png"
                      />
                      <div>Outlook</div>
                    </span>
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="BCCs"
                name="defaultBCCs"
              >
                <Select
                  mode="multiple"
                  className="line-height-28"
                  disabled={
                    isEdit.type === PracticeUpdateTypes.emails ? false : true
                  }
                  placeholder="BCCs"
                  options={BCCoptions}
                />
              </Form.Item>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 12 }}
                label="CCs"
                name="defaultCCs"
              >
                <Select
                  mode="multiple"
                  defaultValue={["a0000", "c111"]}
                  className="line-height-28"
                  disabled={
                    isEdit.type === PracticeUpdateTypes.emails ? false : true
                  }
                  placeholder="CCs"
                ></Select>
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PracticeUpdateTypes.emails && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </>
  );
};
const Pratices = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
}: any) => {
  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Col className="card border pb-3 border-rounded-1 bg-secondary mb-4">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Practice Sequence
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({ type: PracticeUpdateTypes.praticesequences })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row>
            <Col xxl={10} xl={12}>
              <Row className="font-semibold ps-3 pt-1">Invoice</Row>
              <Col className="px-3 pt-1">
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 12 }}
                  label="Sequence Type"
                  name={["sequences", "invoice", "type"]}
                >
                  <Select
                    className="line-height-28"
                    placeholder="Sequence Type"
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                    options={[
                      { value: 0, label: "None" },
                      { value: 1, label: "Yearly" },
                      { value: 2, label: "Monthly" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Row className="px-3">
                <Col span={4}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Seperator"
                    name={["sequences", "invoice", "seperator"]}
                  >
                    <Input
                      className="line-height-28"
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticesequences
                          ? false
                          : true
                      }
                      onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                    />
                  </Form.Item>
                </Col>
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    fontWeight: 900,
                    padding: "0 7px 10px",
                  }}
                >
                  -
                </span>
                <Col span={5}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Leading Zero"
                    name={["sequences", "invoice", "leadingZero"]}
                  >
                    <Input
                      className="line-height-28"
                      onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticesequences
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    fontWeight: 900,
                    padding: "0 7px 10px",
                  }}
                >
                  -
                </span>
                <Col span={3}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Prefix"
                    name={["sequences", "invoice", "prefix"]}
                  >
                    <Input
                      className="line-height-28"
                      onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticesequences
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    fontWeight: 900,
                    padding: "0 7px 10px",
                  }}
                >
                  -
                </span>
                <Col span={3}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Postfix"
                    name={["sequences", "invoice", "postfix"]}
                  >
                    <Input
                      className="line-height-28"
                      onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticesequences
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
            <Col xxl={10} xl={12}>
              <Row className="font-semibold ps-3 pt-1">Quotes</Row>
              <Col className="px-3 pt-1">
                <Form.Item
                  className="custom-form-item"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 12 }}
                  label="Sequence Type"
                  name={["sequences", "quote", "type"]}
                >
                  <Select
                    className="line-height-28"
                    placeholder="Sequence Type"
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticesequences
                        ? false
                        : true
                    }
                    options={[
                      { value: 0, label: "None" },
                      { value: 1, label: "Yearly" },
                      { value: 2, label: "Monthly" },
                    ]}
                  />
                </Form.Item>
              </Col>
              <Row className="px-3 ">
                <Col span={4}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Seperator"
                    name={["sequences", "quote", "seperator"]}
                  >
                    <Input
                      className="line-height-28"
                      onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticesequences
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    fontWeight: 900,
                    padding: "0 7px 10px",
                  }}
                >
                  -
                </span>
                <Col span={5}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Leading Zero"
                    name={["sequences", "quote", "leadingZero"]}
                  >
                    <Input
                      className="line-height-28"
                      onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticesequences
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    fontWeight: 900,
                    padding: "0 7px 10px",
                  }}
                >
                  -
                </span>
                <Col span={3}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Prefix"
                    name={["sequences", "quote", "prefix"]}
                  >
                    <Input
                      className="line-height-28"
                      onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticesequences
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
                <span
                  style={{
                    display: "flex",
                    alignItems: "flex-end",
                    fontWeight: 900,
                    padding: "0 7px 10px",
                  }}
                >
                  -
                </span>
                <Col span={3}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Postfix"
                    name={["sequences", "quote", "postfix"]}
                  >
                    <Input
                      className="line-height-28"
                      onKeyPress={(e) => Utils.ValidateCharAndNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticesequences
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
          {isEdit.type === PracticeUpdateTypes.praticesequences && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </>
  );
};
const Features = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
}: any) => {
  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Col className="card border pb-3 border-rounded-1 bg-secondary ">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Features
            <span
              className="edit-btn"
              onClick={() => setIsEdit({ type: PracticeUpdateTypes.features })}
            >
              <EditTwoTone />
            </span>
          </Row>
          {/* <Row className="font-semibold ps-3 pt-2">Features</Row> */}
          <Row className="px-3 pt-3">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "calls"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Calls
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "leadSources"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Lead Sources
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "linkedinLeads"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  LinkedIn Leads
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "webForms"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Web Forms
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "dayEnd"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Days End
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "accounts"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Accounts
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "corporationTax"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Corporation tax
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "aml"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  AML
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "chats"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Chats
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "teams"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Teams
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "bankStatement"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Bank Statements
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "payroll"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Payroll
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "clientDataRequest"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Client Data Qequest
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "bookkeeping"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Bookkeeping
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "timeSheet"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  TimeSheet
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "careOf"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  CareOf
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "selfAssessment"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Self Assessement
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "dms"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  DMS
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "other4"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Other4
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appFeature", "other5"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.features ? false : true
                  }
                  className="line-height-28"
                >
                  Other5
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PracticeUpdateTypes.features && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
        <Col className="card border pb-3 mt-3 border-rounded-1 bg-secondary ">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Practice Properties (Sub Feature)
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({ type: PracticeUpdateTypes.praticeproperites })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
            Parameters
            <span
              style={{
                width: "96%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 pt-1 ">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                label="Page track timeout"
                name={["params", "pageTrackTimeout"]}
              >
                <Input
                  className="line-height-28"
                  placeholder="Page track timeout"
                  onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                label="Due invoice days"
                name={["params", "dueInvoicesTaskDays"]}
              >
                <Input
                  className="line-height-28"
                  placeholder="Due invoice days"
                  onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                />
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                label="Task due date days"
                name={["params", "taskDueDateInDays"]}
              >
                <Input
                  className="line-height-28"
                  placeholder="Task due date days"
                  onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                />
              </Form.Item>
            </Col>
          </Row>
          <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
            Statement of accounts
            <span
              style={{
                width: "88%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "soaIsEnable"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Enable
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "soaIsNotificationEnable"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Notification
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          {form?.getFieldValue([
            "appSubFeature",
            "soaIsNotificationEnable",
          ]) && (
            <>
              <Row className="px-3 pt-1 ">
                <Col span={8} className="d-flex">
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Notify on(day)"
                    name={["appSubFeature", "soaNotificationSettings", "day"]}
                  >
                    <Select
                      className="line-height-28"
                      placeholder="Notify on(day)"
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticeproperites
                          ? false
                          : true
                      }
                      options={[
                        { value: 1, label: "Monday" },
                        { value: 2, label: "Tuesday" },
                        { value: 3, label: "Wednesday" },
                        { value: 4, label: "Thursday" },
                        { value: 5, label: "Friday" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="d-flex">
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Notification frequency"
                    name={[
                      "appSubFeature",
                      "soaNotificationSettings",
                      "frequency",
                    ]}
                  >
                    <Select
                      className="line-height-28"
                      placeholder="Notification frequency"
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticeproperites
                          ? false
                          : true
                      }
                      options={[
                        { value: 0, label: "Weekly" },
                        { value: 1, label: "Two-Weekly" },
                        { value: 2, label: "Monthly" },
                        { value: 3, label: "Quarterly" },
                      ]}
                    />
                  </Form.Item>
                </Col>
                <Col span={8} className="d-flex">
                  <Form.Item
                    className="custom-form-item"
                    name={[
                      "appSubFeature",
                      "soaNotificationSettings",
                      "notifyOnLastDay",
                    ]}
                    valuePropName="checked"
                  >
                    {/* <Input
                      className="line-height-28"
                      placeholder="Notify at quarter end"
                      onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                      }
                    /> */}
                    <Checkbox
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticeproperites
                          ? false
                          : true
                      }
                      className="line-height-28"
                    >
                      Notify at quarter end
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <Row className="px-3 pt-1 ">
                <Col span={8} className="d-flex">
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    label="Due amount greater than"
                    name={[
                      "appSubFeature",
                      "soaNotificationSettings",
                      "amount",
                    ]}
                  >
                    <InputNumber
                      controls={false}
                      placeholder="Due amount greater than"
                      prefix="£"
                      onKeyPress={(e) => Utils.ValidateAmountOnly(e)}
                      style={{ width: "200px" }}
                      disabled={
                        isEdit.type === PracticeUpdateTypes.praticeproperites
                          ? false
                          : true
                      }
                    />
                  </Form.Item>
                </Col>
              </Row>
            </>
          )}
          <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
            Manual timesheets
            <span
              style={{
                width: "88%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "manualTimesheet"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Manual timesheet
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "mtsAutomationSettings", "automate"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Automate WIP
                </Checkbox>
              </Form.Item>
            </Col>
            {form?.getFieldValue([
              "appSubFeature",
              "mtsAutomationSettings",
              "automate",
            ]) && (
              <Col span={8} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  label="Days"
                  name={["appSubFeature", "mtsAutomationSettings", "days"]}
                >
                  <Input
                    className="line-height-28"
                    placeholder="Days"
                    onKeyPress={(e) => Utils.ValidateNumberOnly(e)}
                    disabled={
                      isEdit.type === PracticeUpdateTypes.praticeproperites
                        ? false
                        : true
                    }
                  />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
            Additional features
            <span
              style={{
                width: "88%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "digitalSignatureWithoutAML"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Digital Signature Without AML
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "websiteQueriesWithOTP"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Website Quries With OTP
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "addCustomerWithoutLead"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Add Customer Without Lead
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "progressiveLeads"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Progressive Leads
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "witoutDiscount"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Without Discount
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "actionTab"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Action Tab
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "scheduleEmail"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Schedule Email
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "resourceSection"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Resource section (Reports)
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "clientAutomation"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Client automation
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "proofOfEarningFPS"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Proof of earning (FPS)
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "receiptAttachments"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Receipt Attachments
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "skipTeamUpdate"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Skip team update
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "secureLink"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Secure link
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "bankDetails"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Bank Details
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "amLforAllOfficers"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  AML for all officers
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
            Client fields
            <span
              style={{
                width: "90%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "firstResponse"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  First response
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "accountManagar"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Account managar
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "accountant"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Accountant
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "mode"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Mode
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "agentCredentialsRemark"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Agent Credentials Remark
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "clientBrand"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Client brand
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "clientProposition"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  Client Proposition
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
            Tax and Accounts
            <span
              style={{
                width: "88%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "s455TaxLiability"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={
                    isEdit.type === PracticeUpdateTypes.praticeproperites
                      ? false
                      : true
                  }
                  className="line-height-28"
                >
                  S455 tax liability
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1"></Row>
          {isEdit.type === PracticeUpdateTypes.praticeproperites && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </>
  );
};

const ReviewRequest = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
  handleValuesChange,
}: any) => {
  return (
    <>
      <Form form={form} onValuesChange={handleValuesChange}>
        <Col className="card border pb-3 border-rounded-1 bg-secondary ">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Review Request
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({ type: PracticeUpdateTypes.reviewRequest })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          {/* <Row className="font-semibold ps-3 pt-2">Features</Row> */}
          <Col xxl={20} xl={24}>
            <Row className="px-3 pt-3">
              <Col span={12} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  name={["appSubFeature", "reviewRequestConfig", "apct"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      isEdit.type === PracticeUpdateTypes.reviewRequest
                        ? false
                        : true
                    }
                    className="line-height-28"
                  >
                    Accounts Reports & Corporation Tax
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={12} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  name={["appSubFeature", "reviewRequestConfig", "sA100"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      isEdit.type === PracticeUpdateTypes.reviewRequest
                        ? false
                        : true
                    }
                    className="line-height-28"
                  >
                    SA100
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          <Col xxl={20} xl={24}>
            <Row className="px-3 pt-1">
              <Col span={12} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  name={["appSubFeature", "reviewRequestConfig", "sA800"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      isEdit.type === PracticeUpdateTypes.reviewRequest
                        ? false
                        : true
                    }
                    className="line-height-28"
                  >
                    SA800
                  </Checkbox>
                </Form.Item>
              </Col>
              <Col span={12} className="d-flex">
                <Form.Item
                  className="custom-form-item"
                  name={["appSubFeature", "reviewRequestConfig", "vat"]}
                  valuePropName="checked"
                >
                  <Checkbox
                    disabled={
                      isEdit.type === PracticeUpdateTypes.reviewRequest
                        ? false
                        : true
                    }
                    className="line-height-28"
                  >
                    VAT
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
          {isEdit.type === PracticeUpdateTypes.reviewRequest && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </>
  );
};
