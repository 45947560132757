import { EditTwoTone, SaveOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, InputNumber, Row } from "antd";
import React from "react";
import { PayrollYearlySettingsUpdateTypes } from "../../Services/PayrollYearlySettingsService";
import { Utils } from "../../Utilities/Utils";

const General = ({
  form,
  isEdit,
  setIsEdit,
  OnSaveClick,
  OnCancelClick,
}: any) => {
  return (
    <React.Fragment>
      <Form form={form}>
        <Col className="card border pb-3 border-rounded-1 bg-secondary">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            General Information
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({
                  type: PayrollYearlySettingsUpdateTypes.generalInformation,
                })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 ">
            <Col sm={12} lg={12} xl={8} xxl={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Default Tax Code"
                name="defaultTaxCode"
              >
                <Input
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  onKeyPress={(e) =>  Utils.ValidateCharAndNumberOnly(e)}
                  className="line-height-28"
                  placeholder="Default Tax Code"
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="TA Taxable Form"
                name="taTaxableFrom"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  placeholder="TA Taxable Form"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="P60 Bottom Line"
                name="p60BottomLine"
              >
                <Input
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="P60 Bottom Line"
                />
              </Form.Item>
            </Col>
          </Row>
          <Col className=" px-3 font-bold d-flex" span={24}>
            Pension Details
            <span
              style={{
                width: "90%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 ">
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Pension Upper Limit"
                name="pensionUpperLimit"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  placeholder="Pension Upper Limit"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Pension Lower Limit"
                name="pensionLowerLimit"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  placeholder="Pension Lower Limit"
                  className="line-height-28"
                  prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="State Pension Age"
                name="statePensionAge"
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  placeholder="State Pension Age"
                  className="line-height-28"
                  // prefix="£"
                  onKeyPress={(e) =>  Utils.ValidateNumberOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            {/* <Col xs={24} sm={24} lg={12} xl={12} xxl={12} style={{display:"flex"}}>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 18 }}
                    label="Employee's NI start limit as director"
                    name="eeDirAnnualLimit"
                  >
                    <Input
                      disabled={isEdit.type === PayrollYearlySettingsUpdateTypes.generalInformation ? false : true}
                      className="line-height-28"
                      placeholder="Pension Upper Limit"
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    className="custom-form-item"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 18 }}
                    label="Employer's NI start limit as director"
                    name="erDirAnnualLimit"
                  >
                    <Input
                      disabled={isEdit.type === PayrollYearlySettingsUpdateTypes.generalInformation ? false : true}
                      className="line-height-28"
                      placeholder="Pension Lower Limit"
                    />
                  </Form.Item>
                </Col>
              </Col> */}
          </Row>
          <Col className=" px-3 font-bold d-flex" span={24}>
            FPS Details
            <span
              style={{
                width: "90%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 ">
            <Col xs={24} sm={18} lg={18} xl={16} xxl={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 21 }}
                label="Full Payment Submission Name Space"
                name="fpsns"
              >
                <Input
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Full Payment Submission Name Space"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24} lg={6} xl={8} xxl={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Full Payment Submission Version"
                name="fpsVer"
              >
                <Input
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Full Payment Submission Version"
                />
              </Form.Item>
            </Col>
          </Row>
          <Col className=" px-3 font-bold d-flex" span={24}>
            EPS Details
            <span
              style={{
                width: "90%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3">
            <Col xs={24} sm={18} lg={18} xl={16} xxl={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 21 }}
                label="Employer Payment Summary Name Space"
                name="epsns"
              >
                <Input
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Employer Payment Summary Name Space"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24} lg={6} xl={8} xxl={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Employer Payment Summary Version"
                name="epsVer"
              >
                <Input
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="Employer Payment Summary Version"
                />
              </Form.Item>
            </Col>
          </Row>
          <Col className=" px-3 font-bold d-flex" span={24}>
            NVR Details
            <span
              style={{
                width: "90%",
                height: 0,
                marginTop: 10,
                marginLeft: 7,
                paddingLeft: 5,
                borderBottom: "1px solid #f3f3f3",
              }}
            ></span>
          </Col>
          <Row className="px-3 ">
            <Col xs={24} sm={18} lg={18} xl={16} xxl={12}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 21 }}
                label="NINO Verification Request Name Space"
                name="nvrns"
              >
                <Input
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="NINO Verification Request Name Space"
                />
              </Form.Item>
            </Col>
            <Col sm={12} xs={24} lg={6} xl={8} xxl={6}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="NINO Verification Request Version"
                name="nvrVer"
              >
                <Input
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.generalInformation
                      ? false
                      : true
                  }
                  className="line-height-28"
                  placeholder="NINO Verification Request Version"
                />
              </Form.Item>
            </Col>
          </Row>

          {isEdit.type ===
            PayrollYearlySettingsUpdateTypes.generalInformation && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
        <Col className="card border pb-3 border-rounded-1 mt-3 bg-secondary">
          <Row
            className="border-bottom  px-2 py-1 font-bold"
            justify="space-between"
          >
            Fixed Code Tax
            <span
              className="edit-btn"
              onClick={() =>
                setIsEdit({
                  type: PayrollYearlySettingsUpdateTypes.fixedCodeTax,
                })
              }
            >
              <EditTwoTone />
            </span>
          </Row>
          <Row className="px-3 py-1">
            <Col sm={12} lg={12} xl={8} xxl={4}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Basic Rate"
                name={["fixedCodeTax", "br"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Basic Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={5}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Higher Rate (Day Rate)"
                name={["fixedCodeTax", "d0"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Higher Rate (Day Rate)"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={5}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Additional  Rate (Day Rate)"
                name={["fixedCodeTax", "d1"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Additional  Rate (Day Rate)"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={5}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label=" Scottish Basic Rate"
                name={["fixedCodeTax", "sbr"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Scottish Basic Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={5}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label=" Scottish Higher Rate (Day Rate)"
                name={["fixedCodeTax", "sD0"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Scottish Higher Rate (Day Rate)"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 py-1">
            <Col sm={12} lg={12} xl={8} xxl={4}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Company Basic Rate"
                name={["fixedCodeTax", "cbr"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Company Basic Rate"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={5}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Company Higher Rate (Day Rate)"
                name={["fixedCodeTax", "cD0"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Company Higher Rate (Day Rate)"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={5}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Company Additional  Rate (Day Rate)"
                name={["fixedCodeTax", "cD1"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Company Additional  Rate (Day Rate)"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={5}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label="Scottish Additional Rate (Day Rate)"
                name={["fixedCodeTax", "sD1"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Scottish Additional Rate (Day Rate)"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col sm={12} lg={12} xl={8} xxl={5}>
              <Form.Item
                className="custom-form-item"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 18 }}
                label=" Scottish Intermediate Rate (Day Rate)"
                name={["fixedCodeTax", "sD2"]}
              >
                <InputNumber
                  controls={false}
                  disabled={
                    isEdit.type ===
                    PayrollYearlySettingsUpdateTypes.fixedCodeTax
                      ? false
                      : true
                  }
                  placeholder="Scottish Intermediate Rate (Day Rate)"
                  className="line-height-28"
                  prefix="%"
                  onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
          {isEdit.type === PayrollYearlySettingsUpdateTypes.fixedCodeTax && (
            <Row className="px-3 pt-3">
              <Button type="primary" onClick={OnSaveClick}>
                <SaveOutlined /> Save
              </Button>
              <Button className="ms-2" onClick={OnCancelClick}>
                <CloseOutlined /> Cancel
              </Button>
            </Row>
          )}
        </Col>
      </Form>
    </React.Fragment>
  );
};
export default General;
