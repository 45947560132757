import { ApiUtility } from "../Utilities/ApiUtility.axios";

export enum ReleaseUpdateTypes
{
    Unknown = 0,
    Enhancement = 1,
    New = 2,
    Fix = 3
}
class UpdatesService{
    route = `/Api/Updates`;

    getUpdatesList = ( start:number, length:any, search:string, sortCol:any, sortDir:any) =>   ApiUtility.get(`${this.route}`, {start, length, search, sortDir, sortCol});

    addUpdate = (id?:string, params?: any) => ApiUtility.post(`${this.route}/${id}`, params);
}
export default new UpdatesService();