import { Col, Form, Input, Modal, Row, Select, SelectProps } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import CommonService from "../../Services/CommonService";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import PayrollYearlySettingsService from "../../Services/PayrollYearlySettingsService";
import { adminContext } from "../../Common/PageRoute";
import { Utils } from "../../Utilities/Utils";

const AddPayrollYearlySettings = ({ id, record, onDismiss }: any) => {
  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);
  const { openNotification } = React.useContext(adminContext);
  //   const [payeAddedYears, setPayeAddedYears] = React.useState<any>();
  const [selYearOptions, setSelYearOptions] = React.useState<
    SelectProps["options"]
  >([]);
  React.useEffect(() => {
    setLoading(true);
    const getTaxRatesAddedYears = async () => {
      let res = await CommonService.getPayeollYearlyAddedYear();
      const optn: SelectProps["options"] = [];
      if (res.result) {
        // setPayeAddedYears(res.result);
        let addedYears = res.result;
        let startYear = Math.min(...addedYears);
        let endYear = Math.max(...addedYears) + 10;
        for (let i = startYear; i <= endYear; i++) {
          console.log("years", addedYears); 
          optn.push({
            value: i,
            label:  `${1999 + i} - ${2000 + i}`,
            disabled: addedYears?.includes(i) ?  true  : false,
          });
        }
        // res.result?.map((item: any, idx: any) => {
        //   optn.push({
        //     value: item,
        //     label: `${1999 + item} - ${2000 + item}`,
        //     disabled: true,
        //   });
        //   if (res.result?.length - 1 === idx) {
        //     for (let index = 1; index < 11; index++) {
        //       let val = item + index;
        //       optn.push({
        //         value: val,
        //         label: `${1999 + val} - ${2000 + val}`,
        //         disabled: false,
        //       });
        //     }
        //   }
        //   return item;
        // });
        setSelYearOptions(optn);
        setLoading(false);
      }
    };
    getTaxRatesAddedYears();
  }, []);

  return (
    <Modal
      title={"Add Payroll Yearly Settings"}
      open={true}
      confirmLoading={loading}
      width={600}
      okText={
        <>
          <SaveOutlined /> Save
        </>
      }
      cancelText={
        <>
          <CloseOutlined /> Cancel
        </>
      }
      onCancel={() => onDismiss(false)}
      onOk={() => {
        form.validateFields().then((values) => {
          const Id = id ?? "";
          PayrollYearlySettingsService.AddPayrollYearlySettings(
            values,
            Id
          ).then((res: any) => {
            console.log(res);
            if (res.status) {
              onDismiss(true);
            } else {
              openNotification("error", res.message);
            }
          });
        }).catch((err) => {});
      }}
    >
      <Form form={form} requiredMark={false}>
        <Row className="px-3 py-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              rules={[
                {
                  required: true,
                  message: "Please select a year",
                },
              ]}
              label="Year"
              name="yearId"
            >
              <Select placeholder="Select year" options={selYearOptions} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              rules={[
                {
                  required: true,
                  message: "Please enter default tax code",
                },
              ]}
              label="Default tax code"
              name="defaultTaxCode"
            >
              <Input
                onKeyPress={(e) =>  Utils.ValidateCharAndNumberOnly(e)}
                className="line-height-28"
                placeholder="Default tax code"
              />
            </Form.Item>
          </Col>
        </Row>
        {id !== undefined && (
          <Row className="px-3 py-1">
            <Col span={24} className="are-you-sure">
              <Col span={24} style={{ fontWeight: 600, fontSize: 16 }}>
                Are you sure ?
              </Col>
              <Col span={24} style={{ fontSize: 16 }}>
                You want clone details from{" "}
                <span style={{ fontWeight: 700 }}>
                  {1999 + record?.yearId} - {2000 + record?.yearId}
                </span>{" "}
                and add new yearly settings
              </Col>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
};

export default AddPayrollYearlySettings;
