import { Breadcrumb, Col, Row,  Tabs, TabsProps } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import TaxRates from "./TaxRates";
import { CTConfigurationPageType } from "../../Services/CTConfigurationService";
import CapitalAllowance from "./CapitalAllowance";
import RnDRates from "./RnDRates";
import AnnualInvestmentAllowance from "./AnnualInvestmentAllowance";
import AssetCategories from "./AssetCategories";

const CTConfiguration = () => {
  const navigate = useNavigate();
  // const [loading, setLoading] = React.useState(false);
  const [pageType, setPageType] = React.useState({ type: CTConfigurationPageType.TaxRates });

  const onChange = (key: any) => {
    var num = parseInt(key, 10);
    setPageType({ type: num });
  };

  const items: TabsProps["items"] = [
    {
      key: "1",
      label: "Tax Rates",
    },
    {
      key: "2",
      label: "Capital Allowance",
    },
    {
      key: "3",
      label: "R & D Rates",
    },
    {
      key: "4",
      label: "Annual Investment Allowance",
    },
    {
      key: "5",
      label: "Asset Categories",
    },
    // {
    //   key: "6",
    //   label: "Company Names",
    // },
  ];
  return (
    <>
      <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
        <Breadcrumb style={{ color: "#172B4D" }}>
          <Breadcrumb.Item separator=">" onClick={() => navigate("/ctconfiguration")}>
            CTConfiguration
          </Breadcrumb.Item>
          <Breadcrumb.Item>
            {pageType.type === CTConfigurationPageType.TaxRates
              ? "TaxRates"
              : pageType.type === CTConfigurationPageType.CapitalAllowance
              ? "Captial Allowance"
              : pageType.type === CTConfigurationPageType.RnDRates 
              ? "RnDRates" 
              : pageType.type === CTConfigurationPageType.AnnualInvestmentAllowance
              ? "Annual Investment Allowance" 
              : pageType.type === CTConfigurationPageType.AssetCategories 
              ? "Asset Categories" 
              : pageType.type === CTConfigurationPageType.CompanyNames
              ? "Company Names" 
              : ""
              }
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row
        className="roundedCornerSmall px-3 py-1"
        style={{ backgroundColor: "white" }}
      >
        <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
          <Row>
            <Col span={23}>
              <Tabs
                defaultActiveKey="1"
                size="small"
                items={items}
                onChange={onChange}
              />
            </Col>
          </Row>
          {pageType.type === CTConfigurationPageType.TaxRates && <TaxRates />}
          {pageType.type === CTConfigurationPageType.CapitalAllowance && <CapitalAllowance />}
          {pageType.type === CTConfigurationPageType.RnDRates && <RnDRates />}
          {pageType.type === CTConfigurationPageType.AnnualInvestmentAllowance && <AnnualInvestmentAllowance />}
          {pageType.type === CTConfigurationPageType.AssetCategories && <AssetCategories />}
        </Col>
      </Row>
    </>
  );
};

export default CTConfiguration;
