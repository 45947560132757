import { PlusOutlined, EditTwoTone } from "@ant-design/icons";
import { Button, Col, Form, Row, Select, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { FuelTypes } from "../../Services/PayrollYearlySettingsService";
import Link from "antd/es/typography/Link";


const CarAllowances = ({
  OnCancelClick,
  setDialog,
  listParams,
  setListParams,
  CarAllowancesTypeList,
}: any) => {


  const columns: ColumnsType<any> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "4%",
      render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
    },
    {
      title: "Old ",
      dataIndex: "isOld",
      key: "IsOld",
      width: "16%",
      render: (_:any, record: any, index: any) => <>{record?.isOld ? "Yes" : "No"}</>
    },
    {
        title: "Fuel Type",
        dataIndex: "fuelType",
        key: "FuelType",
        width: "20%",
        render: (_:any, record: any, index: any) => <>{FuelTypes[record?.fuelType]}</>
      },
    {
      title: listParams?.carAllowancesListType === 1 ?  "Co2 Emmision From"  : listParams?.carAllowancesListType === 2 ?"Engine Size From" : "Electric Range From",
      dataIndex: listParams?.carAllowancesListType === 1 ?  "co2EmmisionFrom"  : listParams?.carAllowancesListType === 2 ? "engineSizeFrom" : "eleRangeFrom",
      key: listParams?.carAllowancesListType === 1 ?  "Co2EmmisionFrom"  : listParams?.carAllowancesListType === 2 ?"EngineSizeFrom": "EleRangeFrom",
      width: "20%",
    },
    {
      title: listParams?.carAllowancesListType === 1 ?  "Co2 Emmision To"  : listParams?.carAllowancesListType === 2 ? "Engine Size To" :"Electric Range To",
      dataIndex: listParams?.carAllowancesListType === 1 ?  "co2EmmisionTo"  : listParams?.carAllowancesListType === 2 ? "engineSizeTo" : "eleRangeTo",
      key: listParams?.carAllowancesListType === 1 ?  "Co2EmmisionTo"  : listParams?.carAllowancesListType === 2 ? "EngineSizeTo" :"EleRangeTo",
      width: "20%",
    },
    {
      title: "Calculation Percentage",
      dataIndex: "calcPer",
      key: "CalcPer",
      width: "20%",
    },
    {
        title: "Action",
        dataIndex: "id",
        key: "Id",
        width: "3%",
        render: (_: any, record: any, index: any) => (
          <Link
            className="edit-btn"
            onClick={() => {
              setDialog({ type: "AddCarAllowance", params: { record: record } });
            }}
          >
            <EditTwoTone />
          </Link>
        ),
      },
  ];
  return (
    <>
      <Row
        className="roundedCornerSmall p-3"
        style={{ backgroundColor: "white" }}
      >
        <Col span={24}>
          <Row justify="space-between" className="pb-3">
            <Button
              type="primary"
              className="add-btn"
                onClick={() => setDialog({ type: "AddCarAllowance", params: {} })}
            >
              <PlusOutlined />
              Add Item
            </Button>
            <Col xl={12} xxl={8} xs={12} sm={12}  className="pe-3">
            <Form.Item
             className="custom-form-item"
             labelCol={{ span: 12 }}
             wrapperCol={{ span: 12 }}
            label="Car Allowance Type">
              <Select
                className="me-3"
                style={{
                  height: "22px !important",
                  minWidth: 235,
                  lineHeight: "22px !important",
                }}
                placeholder="Select Car Allowance List Type"
                value={listParams?.carAllowancesListType}
                options={[
                  { value: 1, label: "Car Co2 Emmision List" },
                  { value: 2, label: "Car Engine Size List" },
                  { value: 3, label: "Car Electric Range List" },
                ]}
                onChange={(val: any) => {
                  setListParams({...listParams, carAllowancesListType: val});
                }}
              />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col span={24} style={{ height: window.innerHeight - 350, overflowY:"scroll" }}>
          <Table
            dataSource={CarAllowancesTypeList}
            columns={columns}
            size="small"
            className="aoa-custom-table"
            // loading={{
            //   spinning: loading,
            //   indicator: (
            //     <Spin
            //       indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
            //     />
            //   ),
            // }}
            pagination={{
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: 1,
                pageSize: CarAllowancesTypeList?.length,
                showSizeChanger: false,
                total: CarAllowancesTypeList?.length,
                position: ['bottomRight'],
                style:{ position:"sticky", right: 0, bottom: 0, backgroundColor:"white"}
                // pageSizeOptions: ["15", "25", "50", "100"],
              }}
            scroll={{ x: 700 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default CarAllowances;
