import { EditTwoTone, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import UpdatesService, { ReleaseUpdateTypes } from "../../Services/UpdatesService";
import { Breadcrumb, Col, Row, Spin, Table, Input, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import { adminContext } from "../../Common/PageRoute";
// import { Link } from "react-router-dom";
import dayjs from "dayjs";
import UpdateDetails from "./UpdateDetails";
import Link from "antd/es/typography/Link";
const { Search } = Input;

interface ListDataType {
  id: number;
  name: string;
  phoneNumber: string;
  email: string;
  createdBy: any;
  updatedBy: any;
}
interface ListData {
  items: ListDataType[];
  totalItems: number;
}

const Updates = () => {
  const { openNotification } = React.useContext(adminContext);
  const [updatesList, setUpdatesList] = React.useState<ListData>();
  const [loading, setLoading] = React.useState(false);
  
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refresh, setRefresh] = React.useState(false);
  const [dialog, setDialog] = React.useState<{ type: string; params: any }>({
    type: "",
    params: {},
  });
  // const [pagingItems, setPagingItems] = React.useState({
   
  // });
  const [listParams, setListParams] = React.useState({
    search: "",
    sortDir: "descend",
    sortCol: "CreatedBy.Date",
    totalRecords: 0,
    currentPage: 1,
    pageSize: 15,
  });

  const getItems = async (start: any, length: any) => {
    let res = await UpdatesService.getUpdatesList(
      start,
      length,
      listParams.search,
      listParams.sortCol,
      listParams.sortDir
    );
    if (res?.status) {
      setLoading(false);

      setUpdatesList({
        totalItems: res?.result?.totalRecords,
        items: res?.result?.items,
      });
      //   setPagingItems({...pagingItems, totalRecords: res?.result?.totalRecords})
    } else {
      openNotification("error", res.message);
      //setNotification(notific);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    setLoading(true);
    getItems(
      (listParams.currentPage - 1) * listParams.pageSize,
      listParams.pageSize
    );
    // if(refresh === true) setRefresh(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams, refresh]);

  const columns: ColumnsType<ListDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "3%",
      render: (_: any, record: any, index: any) => <p>{listParams.currentPage > 1 ? (listParams.pageSize * (listParams.currentPage - 1)) + index + 1 : index + 1}</p>,
    },
    {
      title: "Date",
      dataIndex: "createdBy.date",
      key: "CreatedBy.Date",
      width: "11%",
      sorter: true,
      render: (text: any, record: any) => (
        <text>{dayjs(record?.createdBy?.date).format("DD-MM-YYYY")}</text>
      ),
    },
    {
      title: "Type",
      dataIndex: "type",
      width: "11%",
      key: "type",
      render: (text: any, record: any) => (
        <text>{ReleaseUpdateTypes[record?.type]}</text>
        ),
      },
      {
        title: "Title",
        width: "20%",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Description",
        dataIndex: "description",
          key: "description",
          render: (text: any, record: any) => (
              <div>
                  <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap',height:25 }} dangerouslySetInnerHTML={{ __html: record.description } }>
                  </div>
              </div>
        )
      },
      {
        title: "Action",
        width: "5%",
      // dataIndex: "description",
      render: (_: any, record: any, index: any) => (
        <Link
          className="edit-btn"
          onClick={() => {
            setDialog({ type: "editupdate", params: { record: record } });
          }}
        >
          <EditTwoTone />
        </Link>
      ),
      // key: "description",
    },
  ];
  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
          <Breadcrumb style={{ color: "#172B4D" }}>
            <Breadcrumb.Item>Updates</Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row
          className="roundedCornerSmall p-3"
          style={{ backgroundColor: "white" }}
        >
          <Col span={24}>
            <Row justify="space-between" className=" pb-3">
              <Button
                type="primary"
                className="add-btn"
                onClick={() => setDialog({ type: "editupdate", params: {} })}
              >
                <PlusOutlined />
                Add Release
              </Button>
              <Search
                size="small"
                placeholder="Search..."
                className="att-search-input mb-1"
                onSearch={(val: string) =>
                  setListParams({ ...listParams, search: val })
                }
                onChange={(e) =>
                  e.target.value === ""
                    ? setListParams({ ...listParams, search: "" })
                    : null
                }
                style={{ width: 170 }}
              />
            </Row>
          </Col>
          <Col span={24}  style={{ minHeight: window.innerHeight - 200 }}>

              <Table
                dataSource={updatesList?.items}
                columns={columns}
                size="small"
                scroll={{x:1200, y:600}}
                className="aoa-custom-table"
                // loading={{
                //   spinning: loading,
                //   indicator: (
                //     <Spin
                //       indicator={
                //         <LoadingOutlined style={{ fontSize: 20 }} spin />
                //       }
                //     />
                //   ),
                // }}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams.currentPage,
                  pageSize: listParams.pageSize,
                  showSizeChanger: true,
                  total: updatesList?.totalItems,
                  pageSizeOptions: ["15", "25", "50", "100"],
                }}
                onChange={(paging, filter, sort: any) => {
                 
                  if (
                    paging?.current !== undefined &&
                    paging?.pageSize !== undefined
                  ) {
                    setLoading(true);
                    setListParams({
                      ...listParams,
                      sortDir:sort.order !== undefined  ? sort.order : 'acsend',
                      sortCol:sort.order !== undefined ? sort.columnKey : 'CreatedBy.Date',
                      currentPage: paging.current !== undefined ? paging.current : 1,
                      pageSize : paging.pageSize !== undefined ? paging.pageSize : 15,
                    });
                  }
                }}
              />
              {dialog.type === "editupdate" && (
                <UpdateDetails
                  {...dialog.params}
                  show={true}
                  onDismiss={(confirmed: boolean) => {
                    setDialog({ type: "", params: {} });
                    if (confirmed) {
                      setRefresh((x)=> x = !x);
                    }
                  }}
                />
              )}
          </Col>
        </Row>
      </Spin>
      {/* <Divider orientation="left">Small Size</Divider> */}
    </>
  );
};

export default Updates;
