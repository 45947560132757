import { Checkbox, Col, Form, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import PracticesService from "../../Services/PracticesService";
import { adminContext } from "../../Common/PageRoute";
 
const ViewPracticeDetails = ({ record, onDismiss }: any) => {
  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);
  const { openNotification } = React.useContext(adminContext);
  const [practiceFeatures, setPracticeData] = React.useState<any>({});
  
  React.useEffect(()=>{
    const getFeatures = async () =>{
      setLoading(true);
      let res =  await PracticesService.getFeaturePracticeDetails(record?.id);
      if(res?.status){
        setPracticeData(res?.result);
        form.setFieldsValue({
          appFeature:  res?.result?.appFeature, 
          appSubFeature: res?.result?.appSubFeature,
        })
        setLoading(false);
      } else{
        openNotification("error", "Detail not found");
        setLoading(false);
      }
    }
    if(record?.id) getFeatures();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[record?.id]);
  return (
    <Modal
      confirmLoading={loading}
      title={`View Features for ${practiceFeatures?.name}`}
      className="practice-view-details"
      width={800}
      open={true}
      onCancel={() => onDismiss(false)}
      footer={[]}
    >
      <Form form={form} style={{pointerEvents:"none"}}>
        <Col className="font-semibold d-flex ps-3 pt-1" span={24}>
          Features
          <span
            style={{
              width: "90%",
              height: 0,
              marginTop: 10,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 pt-2">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "calls"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Calls
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "leadSources"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Lead Sources
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "linkedinLeads"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                LinkedIn Leads
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "webForms"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Web Forms
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "dayEnd"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Days End
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "accounts"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Accounts
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "corporationTax"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Corporation tax
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "aml"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                AML
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "chats"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Chats
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "teams"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Teams
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "bankStatement"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Bank Statements
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "payroll"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Payroll
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "clientDataRequest"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Client Data Qequest
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "bookkeeping"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Bookkeeping
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "timeSheet"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                TimeSheet
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "careOf"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                CareOf
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "selfAssessment"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Self Assessement
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "dms"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                DMS
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 pt-1">
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "other4"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Other4
              </Checkbox>
            </Form.Item>
          </Col>
          <Col span={8} className="d-flex">
            <Form.Item
              className="custom-form-item"
              name={["appFeature", "other5"]}
              valuePropName="checked"
            >
              <Checkbox disabled={false} className="line-height-28">
                Other5
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Col className="font-semibold d-flex ps-3 pt-3" span={24}>
          Sub Features
          <span
            style={{
              width: "85%",
              height: 0,
              marginTop: 15,
              marginLeft: 7,
              paddingLeft: 5,
              borderBottom: "1px solid #f3f3f3",
            }}
          ></span>
        </Col>
        <Row className="px-3 pt-2">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "digitalSignatureWithoutAML"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={false}
                  className="line-height-28"
                >
                  Digital Signature Without AML
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "websiteQueriesWithOTP"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={false}
                  className="line-height-28"
                >
                  Website Quries With OTP
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "addCustomerWithoutLead"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={false}
                  className="line-height-28"
                >
                  Add Customer Without Lead
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "manualTimesheet"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={false}
                  className="line-height-28"
                >
                  Manual TimeSheet
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "progressiveLeads"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={false}
                  className="line-height-28"
                >
                  Progressive Leads
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "witoutDiscount"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={false}
                  className="line-height-28"
                >
                  Without Discount
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "statementOfAccounts"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={false}
                  className="line-height-28"
                >
                  Statement of Account
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "actionTab"]}
                valuePropName="checked"
              >
                <Checkbox
                  disabled={false}
                  className="line-height-28"
                >
                  Action Tab
                </Checkbox>
              </Form.Item>
            </Col>
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "scheduleEmail"]}
                valuePropName="checked"
              >
                <Checkbox
                  className="line-height-28"
                >
                  Schedule Email 
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row className="px-3 pt-1">
            <Col span={8} className="d-flex">
              <Form.Item
                className="custom-form-item"
                name={["appSubFeature", "resourceSection"]}
                valuePropName="checked"
              >
                <Checkbox
                  className="line-height-28"
                >
                Resource section (Reports)
                </Checkbox>
              </Form.Item>
            </Col>
            </Row>
      </Form>
    </Modal>
  );
};

export default ViewPracticeDetails;
