import { Col, Form, Input, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import { adminContext } from "../../Common/PageRoute";
import { useNavigate } from "react-router-dom";
import PracticesService from "../../Services/PracticesService";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Utils } from "../../Utilities/Utils";

const AddPractice = ({ onDismiss, id, name, email }: any) => {
  const [form] = useForm();
  const navigate = useNavigate();
  const { openNotification, setPracticeData } = React.useContext(adminContext);
  React.useEffect(() => {
    if (id !== undefined) {
      form.setFieldsValue({
        name: name,
        email: email,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Modal
      open={true}
      width={600}
      title={id !== undefined ? `Clone details from (${name})` : "Add Practice"}
      okText={
        <>
          <SaveOutlined /> Save
        </>
      }
      cancelText={
        <>
          <CloseOutlined /> Cancel
        </>
      }
      onCancel={() => onDismiss(false)}
      onOk={(values: any) => {
        setPracticeData(undefined);
        form.validateFields().then(async (value) => {
          let Id = id !== undefined && id !== null && id !== "" ? id : 0;
          let res = await PracticesService.addPractices(value, Id);
          if (res.status) {
            setPracticeData(res);
            navigate(`/practices/${res.result.id}`);
            if (Id > 0)
              openNotification(
                "success",
                "Practice added successfully. Details has be clone to local system you need to save deatils for each section without refreshing the page. refreshing can cause the cloned data loss"
              );
          } else {
            openNotification("error", res.message);
            onDismiss(true);
          }
        }).catch((error) => {
          openNotification("error", "Valdate Fields");
        });
      }}
    >
      <Form requiredMark={false} form={form}>
        <Row className="px-3 py-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Select Name"
              name="name"
              rules={[{ required: true, message: "Name is requried" }, { validator: Utils.validateWhiteSpace }]}
            >
              <Input className="line-height-28" placeholder="Name"  onKeyPress={(e: any) => Utils.ValidateCharOnly(e)}/>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Select Email"
              name="email"
              rules={[{ required: true, message: "This field is required" }, { validator : Utils.ValidateEmailFormat }]}
            >
              <Input className="line-height-28" placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={24}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 21 }}
              label="Company Logo Url"
              name="logoUrl"
            >
              <Input
                className="line-height-28"
                placeholder="Company Logo Url"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      {id !== undefined ? (
        <Row className="px-3 py-1">
        <Col span={24}
          className="p-3"
          style={{ backgroundColor: "#eef2ff", borderRadius: 5 }}
        >
            <Row className="font-bold"> Are you sure ? </Row>
            <Row>you want to clone details from the selected practice.</Row>
        </Col>
          </Row>
      ) : (
        <Row></Row>
      )}
    </Modal>
  );
};

export default AddPractice;
