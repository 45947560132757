import { PlusOutlined, LoadingOutlined, EditTwoTone } from "@ant-design/icons";
import { Button, Col, Row, Spin, Table } from "antd";
import React from "react";
import CTConfiguration from "../../Services/CTConfigurationService";
import dayjs from "dayjs";
import AddAIAs from "./AddAIAs";
import Link from "antd/es/typography/Link";
import { adminContext } from "../../Common/PageRoute";

const AnnualInvestmentAllowance = () => {
  const { openNotification } = React.useContext(adminContext);
  const [AIAsList, setAIAsList] = React.useState([]);
  const [dialog, setDialog] = React.useState<{ type: string; params: any }>({
    type: "",
    params: {},
  });
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(false);
  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "3%",
      render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
    },
    {
      title: "Form Date",
      dataIndex: "fromDate",
      key: "fromDate",
      width: "20%",
      render: (_: any, record: any, index: any) => (
        <p>
          {record.fromDate !== undefined && record.fromDate !== null
            ? dayjs(record.fromDate).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      title: "To Date",
      dataIndex: "toDate",
      key: "toDate",
      width: "20%",
      render: (_: any, record: any, index: any) => (
        <p>
          {record.toDate !== undefined && record.toDate !== null
            ? dayjs(record.toDate).format("DD/MM/YYYY")
            : "-"}
        </p>
      ),
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      width: "20%",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "Id",
      width: "7%",
      render: (_: any, record: any, index: any) => (
        <Link
          className="edit-btn"
          onClick={() => {
            if (record.id !== undefined || record.id !== null) {
              setDialog({
                type: "addAIAs",
                params: { id: record.id, record: record },
              });
            } else {
              openNotification("error", "Id not Found")
            }
          }}
        >
          <EditTwoTone />
        </Link>
      ),
    },
  ];

  React.useEffect(() => {
    const getRnDList = async () => {
      setLoading(true);
      let res = await CTConfiguration.getAIAs();
      if (res.status) {
        setAIAsList(res.result);
        setLoading(false);
      } else {
        setLoading(false);
      }
    };
    getRnDList();
  }, [refresh]);

  return (
    <>
      <Row>
        <Col
          span={24}
          className="border-bottom p-2 font-bold"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            type="primary"
            onClick={() => {
              setDialog({ type: "addAIAs", params: {} });
            }}
          >
            <PlusOutlined />
            Add AIAs
          </Button>
        </Col>
        <Col span={24}>
          <Table
            dataSource={AIAsList?.length > 0 ? AIAsList : []}
            columns={columns}
            size="small"
            className="aoa-custom-table"
            loading={{
              spinning: loading,
              indicator: (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
              ),
            }}
          />
        </Col>
        {dialog?.type === "addAIAs" && (
          <AddAIAs
            id={dialog.params?.id || ""}
            record={dialog.params?.record}
            onDismiss={(conformation: any) => {
              setDialog({ type: "", params: {} });
              if (conformation) {
                setRefresh((x) => x = !x);
              }
            }}
          />
        )}
      </Row>
    </>
  );
};

export default AnnualInvestmentAllowance;
