import React from "react";
import { Modal, Input, Form,  Select } from "antd";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Updates from "../../Services/UpdatesService";
import { useForm } from "antd/es/form/Form";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";

interface UpdateParams {
  onDismiss: (confirmed: boolean) => void;
  record: any;
}
const UpdateDetails = ({ onDismiss, record }: UpdateParams) => {
  const [form] = useForm();
  const [loading, setLoading] = React.useState(false);
    React.useEffect(() => {
    if (record?.id !== undefined && record?.id !== "") {
      form.setFieldsValue({
        type: record?.type !== undefined ? record?.type : "",
        title: record?.title !== undefined ? record?.title : "",
        description: record?.description !== undefined ? record?.description : "",
        content: record?.content !== undefined ? record?.content : "",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [record?.id]);
  return (
    <>
      <Modal
        title={
          record?.id !== undefined ? `Edit ${record?.title}` : "Add New Update"
        }
        width={1000}
        confirmLoading={loading}
        open={true}
        okText={<><SaveOutlined /> Save</>}
        cancelText={<><CloseOutlined /> Cancel</>}
        onOk={() => {
          let Id = record?.id !== undefined && record?.id !== null ? record?.id : "";
          setLoading(true);
          form?.validateFields().then(async (values:any) => {
            Updates.addUpdate(Id, values).then((res) => {
              if (res?.status) {
                onDismiss(true);
              } else {
                onDismiss(false);
              }
            });
          }).catch((err) => {
            setLoading(false);
          })
        }}
        onCancel={() => {
          onDismiss(false);
        }}
        okButtonProps={{ disabled: false }}
        cancelButtonProps={{ disabled: false }}
      >
        <Form form={form} requiredMark={false}>
          <Form.Item
            className="custom-form-item mt-3"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 12 }}
            label="Type"
            name={["type"]}
            rules={[
              {
                required: true,
                message: "Select a update type",
              },
            ]}
          >
            <Select
              placeholder="type"
              options={[
                { value: 0, label: "Unkonwn" },
                { value: 1, label: "Enhancement" },
                { value: 2, label: "New" },
                { value: 3, label: "Fix" },
              ]}
            />
          </Form.Item>
          <Form.Item
            className="custom-form-item mt-3"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 12 }}
            label="Title"
            name={["title"]}
            rules={[
              {
                required: true,
                message: "Mandatory field",
              },
            ]}
          >
            <Input placeholder="title" />
          </Form.Item>

          <Form.Item
            className="custom-form-item mt-3"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 12 }}
            label="Descripition"
            name={["description"]}
          >
            <CKEditor                     
                editor={ClassicEditor}
                data={
                    record?.description !== "" && record?.description !== null
                        ? record?.description
                        : form?.getFieldValue("description")
                }
                // config={  editorConfiguration}
                onReady={(editor: any) => {
                    // Insert the toolbar before the editable area.
                    // editor.ui
                    //   .getEditableElement()
                    //   .parentElement.insertBefore(
                    //     editor.ui.view.toolbar.element,
                    //     editor.ui.getEditableElement()
                    //   );
                    //editor = editor;
                }}
                onChange={(event :any,editor :any) => {
                    const data = editor.getData();
                    form.setFieldValue("description", data);
                }}
            />
          </Form.Item>
          <Form.Item
            className="custom-form-item mt-3"
            labelCol={{ span: 3 }}
            wrapperCol={{ span: 18 }}
            label="Content"
            name={["content"]}
          >
            <CKEditor
              editor={ClassicEditor}
              data={
                record?.content !== "" && record?.content !== null
                  ? record?.content
                  : form?.getFieldValue("content")
              }
              // config={  editorConfiguration}
              onReady={(editor: any) => {
                // Insert the toolbar before the editable area.
                // editor.ui
                //   .getEditableElement()
                //   .parentElement.insertBefore(
                //     editor.ui.view.toolbar.element,
                //     editor.ui.getEditableElement()
                //   );
                //editor = editor;
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
                form.setFieldValue("content", data);
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
export default UpdateDetails;
