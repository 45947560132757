import { EditTwoTone, LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { Row, Col, Button, Select, Table, Spin, Form } from "antd";
import { ColumnsType } from "antd/es/table";
import React from "react";
import {
  EmployeePayFrequency,
  INIThresholds,
} from "../../Services/PayrollYearlySettingsService";
import dayjs from "dayjs";
import Link from "antd/es/typography/Link";

const NIThersholds = ({
  OnCancelClick,
  setDialog,
  NIThresholdsList,
}: any) => {
  const [loading, setLoading] = React.useState(false);
  const [niThersholdslistData, setNIThersholdsListData] = React.useState(
    NIThresholdsList || []
  );
  const [params, setParams] = React.useState({
    payFrequency: 0,
    director: 0,
  });


  const columns: ColumnsType<INIThresholds> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "4%",
      render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
    },
    {
      title: "Pay Frequency",
      dataIndex: "payFrequency",
      key: "PayFrequency",
      width: "9%",
      render: (_: any, record: any, index: any) => (
        <p>{EmployeePayFrequency[record?.payFrequency]}</p>
      ),
    },
    {
      title: "Director",
      dataIndex: "director",
      key: "Director",
      width: "7%",
      render: (_: any, record: any, index: any) => (
        <>{record?.director ? "Yes" : "No"}</>
      ),
    },
    {
      title: "PayDate From",
      dataIndex: "payDateFrom",
      key: "PayDateFrom",
      width: "10%",
      render: (_: any, record: any, index: any) => (
        <>{record?.payDateFrom ? dayjs(record?.payDateFrom).format("DD/MM/YYYY") : "-"}</>
      ),
    },
    {
      title: "PayDate To",
      dataIndex: "payDateTo",
      key: "PayDateTo",
      width: "10%",

      render: (_: any, record: any, index: any) => (
        <>{record?.payDateTo ? dayjs(record?.payDateTo).format("DD/MM/YYYY") : "-"}</>
      ),
    },
    {
      title: "LEL",
      dataIndex: "lel",
      key: "LEL",
      width: "7%",
    },
    {
      title: "UEL",
      dataIndex: "uel",
      key: "UEL",
      width: "7%",
    },
    {
      title: "AUEL",
      dataIndex: "auel",
      key: "AUEL",
      width: "7%",
    },
    {
      title: "PT",
      dataIndex: "pt",
      key: "PT",
      width: "7%",
    },
    {
      title: "ST",
      dataIndex: "st",
      key: "ST",
      width: "7%",
    },
    {
      title: "UST",
      dataIndex: "ust",
      key: "UST",
      width: "6%",
    },
    {
      title: "AUST",
      dataIndex: "aust",
      key: "AUST",
      width: "6%",
    },
    {
      title: "FUST",
      dataIndex: "fust",
      key: "FUST",
      width: "6%",
    },
    {
      title: "VUST",
      dataIndex: "vust",
      key: "VUST",
      width: "6%",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "Id",
      width: "3%",
      render: (_: any, record: any, index: any) => (
        <Link
          className="edit-btn"
          onClick={() => {
            setDialog({
              type: "AddNIThersholds",
              params: { record: record },
            });
          }}
        >
          <EditTwoTone />
        </Link>
      ),
    },
  ];

  React.useEffect(() => {
    if (NIThresholdsList?.length > 0) {
      setLoading(true);
      let lst: any = NIThresholdsList || [];
      if (params.director === 0 && params.payFrequency === 0) {
        lst = NIThresholdsList;
      } else if (params.director === 0 && params.payFrequency > 0) {
        lst = NIThresholdsList?.filter(
          (ni: any) => ni.payFrequency === params.payFrequency
        );
      } else {
        lst = NIThresholdsList?.filter((ni: any) => {
          if (params.director === 1) {
            return (
              ni.director === true &&
              (params.payFrequency > 0
                ? ni.payFrequency === params.payFrequency
                : ni.payFrequency !== 0)
            );
          } else if (params.director === 2) {
            return (
              ni.director === false &&
              (params.payFrequency > 0
                ? ni.payFrequency === params.payFrequency
                : ni.payFrequency !== 0)
            );
          }
          return false;
        });
      }
      setNIThersholdsListData(lst);
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, NIThresholdsList]);

 

  return (
    <>
      <Row
        className="roundedCornerSmall p-3"
        style={{ backgroundColor: "white" }}
      >
        <Col span={24}>
          <Row justify="space-between"  className="me-1 pb-3">
            <Button
              type="primary"
              className="add-btn"
              onClick={() => setDialog({ type: "AddNIThersholds", params: {} })}
            >
              <PlusOutlined />
              Add NI Thersholds
            </Button>
            <Row className='pe-3' style={{height:28, width:'fit-content'}}>
              <Form.Item
                label="Pay Frequency"
                labelCol={{ span: 12 }}
                wrapperCol={{ span: 12 }}
              >
              <Select
                className="line-height-28"
                style={{
                  height: "22px !important",
                  minWidth: 170,
                  lineHeight: "22px !important",
                }}
                value={params?.payFrequency}
                placeholder="PayFrequance"
                options={[
                  { value: 0, label: "All" },
                  { value: 1, label: "Monthly" },
                  { value: 2, label: "Weekly" },
                  { value: 3, label: "Two_Weekly" },
                  { value: 4, label: "Four_Weekly" },
                  { value: 5, label: "Annual" },
                  { value: 6, label: "Fortnightly" },
                ]}
                onChange={(val) => setParams({ ...params, payFrequency: val })}
              />
              </Form.Item>
              <Form.Item
              label="Is Director"
              labelCol={{ span: 12 }}
              wrapperCol={{ span: 12 }}
              >
              <Select
                className="me-5  line-height-28"
                style={{
                  height: "22px !important",
                  minWidth: 170,
                  lineHeight: "22px !important",
                }}
                placeholder="Select Director Exist"
                options={[
                  { value: 0, label: "All" },
                  { value: 1, label: "Yes" },
                  { value: 2, label: "No" },
                ]}
                value={params?.director}
                onChange={(val) => setParams({ ...params, director: val })}
              />
              </Form.Item>
          </Row>
          </Row>
        </Col>
        <Col span={24} style={{ minHeight: window.innerHeight - 350 }}>
          <Table
            dataSource={niThersholdslistData}
            columns={columns}
            size="small"
            className="aoa-custom-table"
            loading={{
              spinning: loading,
              indicator: (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
              ),
            }}
            pagination={{
              showTotal: (total, range) =>
                `${range[0]}-${range[1]} of ${total} items`,
              current: 1,
              pageSize: niThersholdslistData?.length,
              showSizeChanger: false,
              total: niThersholdslistData?.length,
              position: ['bottomRight'],
              style:{ position:"sticky", right: 0, bottom: 0, backgroundColor:"white"}
              // pageSizeOptions: ["15", "25", "50", "100"],
            }}
            scroll={{ x: 700 }}
          />
        </Col>
      </Row>
    </>
  );
};

export default NIThersholds;
