import { Col, DatePicker, Form, Input, InputNumber, Modal, Row } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import CTConfigurationService from "../../Services/CTConfigurationService";
import { adminContext } from "../../Common/PageRoute";
import dayjs from "dayjs";
import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Utils } from "../../Utilities/Utils";

const AddAIAs = ({ id, record, onDismiss }: any) => {
  const [form] = useForm();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (id !== undefined && id !== null && id !== "") {
      form.setFieldsValue({
        name: record?.name,
        amount: record?.amount,
        fromDate: record?.fromDate ? dayjs(record?.fromDate) : undefined,
        toDate: record?.toDate ? dayjs(record?.toDate) : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);
  return (
    <Modal
      title={
        id
          ? "Edit Annual Investment Allowance"
          : "Add Annual Investment Allowance"
      }
      width={600}
      open={true}
      confirmLoading={loading}
      okText={<><SaveOutlined /> Save</>}
      cancelText={<><CloseOutlined /> Cancel</>}
      onCancel={() => onDismiss(false)}
      onOk={async () => {
        setLoading(true);
        let Id = id === undefined || id === null ? "" : id;
        let values = form.getFieldsValue();
        let res = await CTConfigurationService.addAIAsItem(values, Id);
        if (res.status) {
          onDismiss(true);
          setLoading(false);
        } else {
          openNotification(res.message);
          setLoading(false);
        }
      }}
    >
      <Form form={form}>
        <Row>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Name"
              name="name"
            >
              <Input 
                className="line-height-28" 
                placeholder="Name" 
                onKeyPress={(e) =>  Utils.ValidateCharSpaceAndNumberOnly(e)}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Amount"
              name="amount"
            >
              <InputNumber
                controls={false}
                placeholder="Amount"
                prefix="£"
                style={{ width: "100%" }}
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="From Date"
              name="fromDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                format='DD/MM/YYYY'
                className="line-height-28"
                placeholder="From Date"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="To Date"
              name="toDate"
            >
              <DatePicker
                style={{ width: "100%" }}
                format='DD/MM/YYYY'
                className="line-height-28"
                placeholder="To Date"
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddAIAs;
