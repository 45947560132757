import React from 'react'
import './Dashboard.css';
import { Breadcrumb, Col, Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {  useNavigate } from 'react-router-dom';
const Dashboard = () => {
  const navigate = useNavigate();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [loading, setLoading] = React.useState(false);

  return (
    <Spin
      spinning={loading}
      indicator={<LoadingOutlined style={{ fontSize: 40 }} spin />}
    >
      <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
        <Breadcrumb style={{ color: "#172B4D" }}>
          <Breadcrumb.Item onClick={()=> navigate('/dashboard')}  >Dashboard</Breadcrumb.Item>
          {/* <Breadcrumb.Item></Breadcrumb.Item> */}
        </Breadcrumb>
      </Row>

      <Row
        className="roundedCornerSmall"
        style={{ backgroundColor: "white" }}
      >
        <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
          <Row>
            <Col span={23}>
            <div className='Dashboard-card'><h1>Welcome</h1></div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Spin>
  )
}

export default Dashboard