import { Breadcrumb, Button, Col, Input, Row, Spin, Table } from "antd";
import React from "react";
import {
  EditTwoTone,
  FolderViewOutlined,
  LoadingOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { ColumnsType } from "antd/es/table/interface";
import { Link, useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { adminContext } from "../../Common/PageRoute";
import AddPractice from "./AddPractices";
import PracticesService from "../../Services/PracticesService";
import ViewPracticeDetails from "./VIewPracticeDetails";

const { Search } = Input;
interface ListDataType {
  key: React.Key;
  id: number;
  name: string;
  phoneNumber: string;
  email: string;
  createdBy: any;
  updatedBy: any;
}
interface ListData {
  items: ListDataType[];
  totalItems: number;
}
const Practices = () => {
  const { openNotification } = React.useContext(adminContext);
  const navigate = useNavigate();
  const [practiceList, setPracticeList] = React.useState<ListData>();
  const [loading, setLoading] = React.useState(false);
  const [dialog, setDialog] = React.useState<any>({ type: "", params: {} });
  const [selectedRowKeys, setSelectedRowKeys] = React.useState<React.Key[]>([]);
  const [selPracticeDetails, setSelPracticeDetails] = React.useState({
    name: "",
    email: "",
  });
  const [pagingItems, setPagingItems] = React.useState({
    totalRecords: 0,
    currentPage: 1,
    pageSize: 15,
  });
  const [listParams, setListParams] = React.useState({
    search: "",
    sortDir: "ascend",
    sortCol: "Name",
  });

  const columns: ColumnsType<ListDataType> = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "5%",
      render: (_: any, record: any, index: any) => <p>{pagingItems.currentPage > 1 ? (pagingItems.pageSize * (pagingItems.currentPage - 1)) + index + 1 : index + 1}</p>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "Name",
      sorter: true,
      width: "50%",
      render: (text: any, record: any) => (
        <Link title={text} to={`/practices/${record.id}`}>
          {text}
        </Link>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      key: "PhoneNumber",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "Email",
    },
    {
      title: "Created By",
      dataIndex: "createdBy.date",
      sorter: true,
      key: "CreatedBy.Date",
      render: (text: any, record: any) => (
        <text>{dayjs(record?.createdBy?.date).format("DD-MM-YYYY")}</text>
      ),
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "Id",
      render: (text: any, record: any) => (
        <>
          <Link to={`/practices/${record.id}`} className="edit-btn">
            <EditTwoTone />
          </Link>
          <FolderViewOutlined
            className="ms-2"
            style={{ color: "#1890ff" }}
            onClick={() => {
              setDialog({
                type: "ViewPracticeDetails",
                params: { record: record },
              });
            }}
          />
        </>
      ),
    },
  ];

  const getItems = async (start: any, length: any) => {
    let res = await PracticesService.getPracticesList(
      start,
      length,
      listParams.search,
      listParams.sortCol,
      listParams.sortDir
    );
    if (res?.status) {
      setLoading(false);
      var data: ListDataType[] = res?.result?.items.map((item: any) => {
        var itemWithKey = {
          key: item?.id,
          id: item?.id,
          name: item?.name,
          phoneNumber: item?.phoneNumber,
          email: item?.email,
          createdBy: item?.createdBy,
          updatedBy: item?.updatedBy,
        };
        return itemWithKey;
      });
      setPracticeList({
        totalItems: res?.result?.totalRecords,
        items: data,
      });
    } else {
      openNotification("error", res.message);
      //setNotification(notific);
      setLoading(false);
    }
  };
  React.useEffect(() => {
    //getItems();
    setLoading(true);
    getItems(
      (pagingItems.currentPage - 1) * pagingItems.pageSize,
      pagingItems.pageSize
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listParams.search]);
  return (
    <>
      <Spin
        spinning={loading}
        indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
      >
        <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
          <Breadcrumb style={{ color: "#172B4D" }}>
            <Breadcrumb.Item onClick={() => navigate("/practices")}>
              Practices
            </Breadcrumb.Item>
          </Breadcrumb>
        </Row>
        <Row
          className="roundedCornerSmall p-3"
          style={{ backgroundColor: "white" }}
        >
          <Col span={24}>
            <Row justify="space-between" className="pb-3">
              <Button
                type="primary"
                className="add-btn"
                onClick={() => setDialog({ type: "addPractice", params: {} })}
              >
                <PlusOutlined />
                {selectedRowKeys.length === 1
                  ? "Clone Practice"
                  : "Add Practice"}
              </Button>
              <Search
                size="small"
                placeholder="Search..."
                className="att-search-input mb-1 me-5"
                onSearch={(val: string) =>
                  setListParams({ ...listParams, search: val })
                }
                onChange={(e) =>
                  e.target.value === ""
                    ? setListParams({ ...listParams, search: "" })
                    : null
                }
                style={{ width: 170 }}
              />
            </Row>
          </Col>
          <Col span={24} style={{ minHeight: window.innerHeight - 200 }}>
            <>
              <Table
                rowSelection={{
                  type: "checkbox",
                  selectedRowKeys: selectedRowKeys,
                  onSelect: (record) => {
                    let selItems: React.Key[] = [];
                    let nameEmail = { name: "", email: "" };
                    if (!selectedRowKeys.includes(record?.id)) {
                      selItems.push(record?.id);
                      nameEmail = { name: record?.name, email: record?.email };
                    }
                    setSelPracticeDetails({
                      name: nameEmail.name,
                      email: nameEmail.email,
                    });
                    setSelectedRowKeys(selItems);
                  },
                }}
                // rowClassName={(record:any) => record?.id !== selectedRowKeys  ? "disabled-row" : ""}
                dataSource={practiceList?.items}
                columns={columns}
                size="small"
                className="aoa-custom-table"
                // loading={{
                //   spinning: loading,
                //   indicator: (
                //     <Spin
                //       indicator={
                //         <LoadingOutlined style={{ fontSize: 20 }} spin />
                //       }
                //     />
                //   ),
                // }}
                scroll={{ x: 700 }}
                pagination={{
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: pagingItems.currentPage,
                  pageSize: pagingItems.pageSize,
                  showSizeChanger: true,
                  total: practiceList?.totalItems,
                  pageSizeOptions: ["15", "25", "50", "100"],
                }}
                onChange={(paging, filter, sort: any) => {
                  sort.order !== undefined &&
                    setListParams({
                      ...listParams,
                      sortDir: sort.order,
                      sortCol: sort.columnKey,
                    });
                  if (
                    paging?.current !== undefined &&
                    paging?.pageSize !== undefined
                  ) {
                    setLoading(true);
                    setPagingItems((p) => {
                      p.currentPage =
                        paging.current !== undefined ? paging.current : 1;
                      p.pageSize =
                        paging.pageSize !== undefined ? paging.pageSize : 15;
                      return p;
                    });
                    getItems(
                      (paging.current - 1) * paging.pageSize,
                      paging.pageSize
                    );
                  }
                }}
              />
              <div>
                {/* <Pagination
                  defaultCurrent={1}
                  total={clientList?.totalItems}
                  size="small"
                  defaultPageSize={15}
                  />*/}
              </div>
            </>

            {/* </Row> */}
          </Col>
        </Row>
        {dialog.type === "addPractice" && (
          <AddPractice
            id={selectedRowKeys[0]}
            name={selPracticeDetails?.name}
            email={selPracticeDetails?.email}
            onDismiss={(confirmed: boolean) => {
              setDialog({ type: "", params: {} });
            }}
          />
        )}
        {dialog.type === "ViewPracticeDetails" && (
          <ViewPracticeDetails
            record={dialog.params?.record}
            onDismiss={(confirmed: boolean) => {
              setDialog({ type: "", params: {} });
            }}
          />
        )}
      </Spin>
      {/* <Divider orientation="left">Small Size</Divider> */}
    </>
  );
};

export default Practices;
