import { Breadcrumb, Col, Row } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

const SA100Configuration = () => {
  const navigate = useNavigate();
  return (
    <>
      <Row justify="start" align="middle" style={{ marginBottom: "6px" }}>
        <Breadcrumb style={{ color: "#172B4D" }}>
          <Breadcrumb.Item onClick={() => navigate("/sa100configuration")}>
            SA100 Configuration
          </Breadcrumb.Item>
        </Breadcrumb>
      </Row>

      <Row
        className="roundedCornerSmall px-3 py-1"
        style={{ backgroundColor: "white" }}
      >
        <Col className="pt-3" span={24} style={{ minHeight: window.innerHeight - 200, fontSize:18, fontWeight:600 }}>
            SA100 Configuration page is in progress...
        </Col>
      </Row>
    </>
  );
};

export default SA100Configuration;
