import { PlusOutlined, LoadingOutlined, EditTwoTone } from "@ant-design/icons";
import { Button, Col, Row,  Spin, Table } from "antd";
import React from "react";
import CTConfiguration from "../../Services/CTConfigurationService";
import dayjs from "dayjs";
import AddRnDRates from "./AddRnDRates";
import { adminContext } from "../../Common/PageRoute";
import Link from "antd/es/typography/Link";

const RnDRates = () => {
  const { openNotification } = React.useContext(adminContext);
  const [rnDRatesList, setRnDRates] = React.useState([]);
  const [dialog, setDialog] = React.useState<{type: string, params:any}>({ type: "", params: {} });
  const [loading, setLoading] = React.useState(false);
  const [refresh, setRefresh] = React.useState(true);

  const columns = [
    {
      title: "S.No",
      dataIndex: "sno",
      key: "sno",
      width: "3%",
      render: (_: any, record: any, index: any) => <p>{index + 1}</p>,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      width: "10%",
      render: (_: any, record: any, index: any) => <p>{dayjs(record.startDate).format("DD/MM/YYYY")}</p>
    },
    {
      title: "SME RnD Relief Rate",
      dataIndex: "smeRate",
      key: "smeRate",
      width: "10%",
    },
    {
      title: "SME Vacc RnD Relief Rate",
      dataIndex: "smeVaccRate",
      key: "smeVaccRate",
      width: "10%",
    },
    {
      title: "SME Tax Rate",
      dataIndex: "taxRate",
      key: "taxRate",
      width: "8%",
    },
    {
      title: "LCS RnD Relief Rate",
      dataIndex: "lcsRate",
      key: "lcsRate",
      width: "10%",
    },
    {
      title: "LCS Vacc RnD Relief Rate",
      dataIndex: "lcsVaccRate",
      key: "lcsVaccRate",
      width: "10%",
    },
    {
      title: "LCS Tax Rate",
      dataIndex: "lTaxRate",
      key: "lTaxRate",
      width: "8%",
    },
    {
      title: "Min Rate",
      dataIndex: "minRate",
      key: "minRate",
      width: "8%",
    },
    {
      title: "Min Amount",
      dataIndex: "minAmount",
      key: "minAmount",
      width: "8%",
    },
    {
      title: "Action",
      dataIndex: "id",
      key: "Id",
      width: "5%",
      render: (_: any, record: any, index: any) => (
        <Link
          className="edit-btn"
          onClick={() => {
            if (record.id !== undefined || record.id !== null) {
              setDialog({
                type: "addRnDRate",
                params: { id: record.id, record: record },
              });
            } else {
              openNotification("error", "Id not Found")
            }
          }}
        >
          <EditTwoTone />
        </Link>
      ),
    },
  ];

  React.useEffect(()=>{
    const getRnDList = async ()=>{
      setLoading(true);
        let res = await CTConfiguration.getRnDRates();
        if(res.status){
            setRnDRates(res.result);
            setLoading(false);
        } else {
            setLoading(false);
        }
    };
    getRnDList();
  },[refresh])


  return (
    <>
      <Row>
        <Col
          span={24}
          className="border-bottom p-2 font-bold"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Button
            type="primary"
            onClick={() => {
              setDialog({ type: "addRnDRate", params: {} });
            }}
          >
            <PlusOutlined />
            Add R&D Rate
          </Button>
        </Col>
        <Col span={24}>
          <Table
            dataSource={rnDRatesList?.length > 0 ? rnDRatesList : []}
            columns={columns}
            size="small"
            className="aoa-custom-table"
            pagination={{
              defaultPageSize:15,
            }}
            loading={{
              spinning: loading,
              indicator: (
                <Spin
                  indicator={<LoadingOutlined style={{ fontSize: 20 }} spin />}
                />
              ),
            }}
          />
        </Col>
      </Row>
      {dialog.type === "addRnDRate" &&(
        <AddRnDRates 
          id={dialog.params?.id}
          record={dialog.params?.record}
          onDismiss={(confirmed:any) =>{
            setDialog({type:"", params:{}});
            if(confirmed){
              setRefresh((x)=> x = !x);
            }
          }}
        />
      )}
    </>
  );
};

export default RnDRates;
