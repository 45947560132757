import { CloseOutlined, SaveOutlined } from "@ant-design/icons";
import { Col, Form, InputNumber, Modal, Row, Select } from "antd";
import { useForm } from "antd/es/form/Form";
import React from "react";
import PayrollYearlySettingsService, {
  AEORatesType,
} from "../../Services/PayrollYearlySettingsService";
import { useParams } from "react-router-dom";
import { adminContext } from "../../Common/PageRoute";
import { Utils } from "../../Utilities/Utils";

const AddAEORates = ({ onDismiss, itemId, record, aeoRatesType }: any) => {
  const [form] = useForm();
  const { id } = useParams();
  const { openNotification } = React.useContext(adminContext);
  const [loading, setLoading] = React.useState(false);
  var aeoItemType =
    aeoRatesType === AEORatesType.DEAStandard
      ? "AEO Standard rate"
      : aeoRatesType === AEORatesType.DEAHigher
      ? "AEO Higher rate"
      : aeoRatesType === AEORatesType.DEAEAS
      ? "AEO Earnings arrestment scotland rate"
      : aeoRatesType === AEORatesType.DEAMC
      ? "AEO Magistrates court rate"
      : aeoRatesType === AEORatesType.DEACTW
      ? "AEO Council tax wales rate"
      : aeoRatesType === AEORatesType.DEACTE
      ? "AEO Council tax england rate"
      : "";

  React.useEffect(() => {
    if (itemId !== undefined && itemId !== null && itemId !== "") {
      form.setFieldsValue({
        id: record?.id,
        payFrequency: record?.payFrequency,
        rate: record?.rate,
        lowerLimit: record?.lowerLimit,
        higherLimit: record?.higherLimit,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId, record, aeoRatesType]);
  return (
    <Modal
      title={itemId ? `Edit ${aeoItemType} item` : `Add ${aeoItemType} item`}
      width={600}
      open={true}
      okText={
        <>
          <SaveOutlined /> Save
        </>
      }
      cancelText={
        <>
          <CloseOutlined /> Cancel
        </>
      }
      okButtonProps={{ loading: loading }}
      cancelButtonProps={{ loading: loading }}
      onCancel={() => onDismiss(false)}
      onOk={async () => {
        setLoading(true);
        const yearlySettingsRecordId = id ?? "";
        const ItemId = itemId ?? "";
        form.validateFields().then( async (values) => {
          const val = {...values, id: record?.id}
          let res = await PayrollYearlySettingsService.updateAEORatesItem(
            yearlySettingsRecordId,
            aeoRatesType,
            val,
            ItemId
          );
          if (res.status) {
            openNotification("success", "Details updated successfully");
            onDismiss(true);
            setLoading(false);
          } else {
            openNotification("error", res.message);
            setLoading(false);
          }
        }).catch((error)=>{
          setLoading(false);
        });
        }}
    >
      <Form form={form} requiredMark={false}>
        <Row className="px-3 py-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Pay Frequency"
              name="payFrequency"
              rules={[
                {
                  required: true,
                  message: "Select a Pay Frequency",
                },
              ]}
            >
              <Select
                placeholder="Select PayFrequency"
                className="line-height-28"
                options={[
                  { value: 1, label: "Monthly" },
                  { value: 2, label: "Weekly" },
                  { value: 3, label: "Two_Weekly" },
                  { value: 4, label: "Four_Weekly" },
                  { value: 5, label: "Annual" },
                  { value: 6, label: "Fortnightly" },
                ]}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Rate"
              name="rate"
            >
              <InputNumber
                controls={false}
                placeholder="Rate"
                prefix="%"
                onKeyPress={(e) =>  Utils.ValidatePercentageOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row className="px-3 py-1">
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Lower limit"
              name="lowerLimit"
            >
              <InputNumber
                controls={false}
                placeholder="Lower limit"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              className="custom-form-item"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 18 }}
              label="Higher limit"
              name="higherLimit"
            >
              <InputNumber
                controls={false}
                placeholder="Higher limit"
                prefix="£"
                onKeyPress={(e) =>  Utils.ValidateAmountOnly(e)}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default AddAEORates;
