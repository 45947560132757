import { ApiUtility } from "../Utilities/ApiUtility.axios";

export const enum CTConfigurationPageType {
  none = 0,
  TaxRates = 1,
  CapitalAllowance = 2,
  RnDRates = 3,
  AnnualInvestmentAllowance = 4,
  AssetCategories = 5,
  CompanyNames = 6,
}

export  enum CapitalAllowanceAssetCategories {
  Unknown,
  MachineryAndPlantSpecial,
  MachineryAndPlantMain ,
  OtherChargesAndAllowances ,
  StructuresAndBuilding ,
  BusinessPremisesRenovationAllowances ,
  ElectricCharge ,
  EnterpriseZones ,
  ZeroEmissionsGoodsVehicles ,
  ZeroEmissionsCars ,
  KnowHow,
}

class CTConfigurationService {
    route = `/Api/CTConfiguration`;

  getTaxRates = (year: any) => ApiUtility.get(`${this.route}/taxrates/${year}`);

  saveTaxrates = (year: any, params: any) => ApiUtility.post(`${this.route}/taxrates/${year}`, params);

  getCapitalAllowances = (type: number) => ApiUtility.get(`${this.route}/capitalAllowances/${type}`);
  
  postCapitalAllowances = (type: number, params: any) => ApiUtility.post(`${this.route}/capitalAllowances/${type}`, params);
  
  getRnDRates = () => ApiUtility.get(`${this.route}/rndrates`);
  
  addRnDRateItem = (params:any, id:any) => ApiUtility.post(`${this.route}/addrndrates/${id}`, params);

  getAIAs = () => ApiUtility.get(`${this.route}/aias`);
 
  addAIAsItem = (params:any, id:any) => ApiUtility.post(`${this.route}/addaias/${id}`, params);

  getAssetCategories = (type: number) => ApiUtility.get(`${this.route}/assetcategory/${type}`);

  postAssetCategories = (type: number, params: any) => ApiUtility.post(`${this.route}/assetcategories/${type}`, params);


}

export default new CTConfigurationService();
